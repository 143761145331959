import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import ArticlesOfAssociationVm from './ArticlesOfAssociationVm';
import st from './ArticlesOfAssociation.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import Guide from '@commonComponents/Guide/Guide';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import ToolTip from '@standby/common-ui/components/molecules/ToolTip/ToolTip';
import clsx from 'clsx';

function ArticlesOfAssociation() {
  useEffect(() => {
    ArticlesOfAssociationVm.load();
  }, []);
  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>정관</span>
      </PageTitle>
      {ArticlesOfAssociationVm.companyRegisterStatus === 'REGISTERED' &&
      ArticlesOfAssociationVm.articlesOfAssociationTagName === '등록중' ? (
        <div className={st.imgBox}>
          <img src="/images/articles_of_association_example.png" />
          <div className={st.explain}>
            지금 정관을 등록하고 있어요.
            <br />
            등록을 마치고 카톡으로 알림을 드릴게요.
            <div className={st.subExplain}>최대 1영업일</div>
          </div>
        </div>
      ) : (
        <>
          <Guide
            title="정관 가이드"
            localStorageKey="companyInfoAoAGuide"
            corpId={ArticlesOfAssociationVm.corpId}
            items={ArticlesOfAssociationVm.guides}
            fullLink="https://standby.kr/blog/tag/%EC%A0%95%EA%B4%80/"
          />
          <ContentBox
            title={ArticlesOfAssociationVm.companyName + ' 정관'}
            className={st.contentBox}
            titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
          >
            <div className={st.content}>
              {ArticlesOfAssociationVm.corpType === '주식회사' && (
                <>
                  <div className={st.aoa}>
                    <div className={st.aoaDownloadAndChange}>
                      <div className={st.aoaDownload}>
                        <div className={st.imgDownload}>
                          <img className={st.fileImg} src="/images/aoa_sample.svg" />
                          <FileBtn
                            btnType="important"
                            className={st.downloadBtn}
                            onClick={() => ArticlesOfAssociationVm.downLoadFile()}
                          >
                            정관 파일 다운로드
                          </FileBtn>
                        </div>
                        <div className={st.updatedAt}>{ArticlesOfAssociationVm.updatedAt}</div>
                      </div>
                      <div className={st.aoaInfoBox}>
                        <div className={st.title}>정관 주요내용</div>
                        <div className={st.aoaContent} style={{ backgroundImage: 'url(/images/사업연도_icon.svg)' }}>
                          <div>
                            사업연도는{' '}
                            <span className={st.stateBold}>{ArticlesOfAssociationVm.importantContents?.결산기간}</span>
                          </div>
                          <ToolTip iconType="question" leftOrRight="right">
                            사업연도란 회사의 1년간의 수입과 비용을 계산하여 정리하는 회계기간을 말해요. 사업연도는 1년
                            단위로 정하고, 정관으로 미리 정해두어야 해요.
                          </ToolTip>
                        </div>
                        <div className={st.aoaContent} style={{ backgroundImage: 'url(/images/신주발행_icon.svg)' }}>
                          <div>
                            <span className={st.bold}>제3자 신주발행</span> 근거규정{' '}
                            <span
                              className={clsx(
                                st.stateBold,
                                ArticlesOfAssociationVm.importantContents?.제3자_신주발행규정Yn ? st.yes : st.no,
                              )}
                            >
                              {ArticlesOfAssociationVm.importantContents?.제3자_신주발행규정Yn ? '있음' : '없음'}
                            </span>
                          </div>
                          <ToolTip iconType="question" leftOrRight="right">
                            상법상 신주는 주주에게 지분율에 맞게 발행하는 것이 원칙이에요. 만약 제3자에게 신주를
                            발행하는 경우, 반드시 정관상 "제3자에게도 특별한 경우 신주발행을 할 수 있다."는 내용의
                            근거규정이 있어야 해요. (상법 제418조 제2항)
                          </ToolTip>
                        </div>
                        <div className={st.aoaContent} style={{ backgroundImage: 'url(/images/우선주_발행_icon.svg)' }}>
                          <div>
                            <span className={st.bold}>우선주 발행</span> 근거규정{' '}
                            <span
                              className={clsx(
                                st.stateBold,
                                ArticlesOfAssociationVm.importantContents?.우선주_발행_근거규정Yn ? st.yes : st.no,
                              )}
                            >
                              {ArticlesOfAssociationVm.importantContents?.우선주_발행_근거규정Yn ? '있음' : '없음'}
                            </span>
                          </div>
                          <ToolTip iconType="question" leftOrRight="right">
                            상법상 우선주를 발행하려면 정관에 어떤 내용의 우선주를 얼마나 발행할 것인지 근거규정이
                            있어야 해요. (상법 제344조 제1항)
                          </ToolTip>
                        </div>
                        <div className={st.aoaContent} style={{ backgroundImage: 'url(/images/stockoption_icon.svg)' }}>
                          <div>
                            <span className={st.bold}>스톡옵션</span> 규정{' '}
                            <span
                              className={clsx(
                                st.stateBold,
                                ArticlesOfAssociationVm.displayStockOption?.bool ? st.yes : st.no,
                              )}
                            >
                              {ArticlesOfAssociationVm.displayStockOption?.bool ? '있음' : '없음'}
                            </span>
                            {ArticlesOfAssociationVm.displayStockOption?.text && (
                              <span className={st.stockOptionTag}>
                                {ArticlesOfAssociationVm.displayStockOption?.text}
                              </span>
                            )}
                          </div>
                          <ToolTip iconType="question" leftOrRight="right">
                            임직원에게 스톡옵션(=주식매수선택권)을 부여하려면 정관에 근거규정이 있어야 해요.
                          </ToolTip>
                        </div>
                        <div className={st.aoaContent} style={{ backgroundImage: 'url(/images/제3자_icon.svg)' }}>
                          <div>
                            <span className={st.bold}>제3자 주식양도제한</span> 규정{' '}
                            <span
                              className={clsx(
                                st.stateBold,
                                ArticlesOfAssociationVm.importantContents?.제3자_주식_양도_제한Yn ? st.yes : st.no,
                              )}
                            >
                              {ArticlesOfAssociationVm.importantContents?.제3자_주식_양도_제한Yn ? '있음' : '없음'}
                            </span>
                          </div>
                          <ToolTip iconType="question" leftOrRight="right">
                            주주는 원래 마음대로 주식을 남에게 팔 수 있어요. 다만 정관에 제3자에게 주식을 양도하는 경우,
                            이사회 또는 주주총회의 승인을 얻도록 제한규정을 넣어둘 수 있어요. 이 경우 주주는 그
                            정관규정을 지켜야 해요.
                          </ToolTip>
                        </div>
                      </div>
                    </div>
                    <div className={clsx(st.aoaDetailBox, ArticlesOfAssociationVm.state.aoaOpenYn ? st.open : null)}>
                      <button
                        type="button"
                        className={st.aoaShowDetail}
                        onClick={() => ArticlesOfAssociationVm.aoaListOpenState()}
                      >
                        정관내용 자세히 보기
                      </button>
                      <div>
                        {ArticlesOfAssociationVm.registerStatus === 'REGISTERED' &&
                          ArticlesOfAssociationVm.state.aoaOpenYn && (
                            <>
                              <div className={st.aoaDetailBoxTitle}>{ArticlesOfAssociationVm.companyName} 정관내용</div>
                              {ArticlesOfAssociationVm.articlesOfAssociation.map((articlesOfAssociation, idx) => (
                                <Table key={idx} className={st.table}>
                                  <thead>
                                    <tr>
                                      <th colSpan="2">{articlesOfAssociation.category}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {articlesOfAssociation.questions.map((question, idx2) => (
                                      <tr key={idx2}>
                                        <td style={{ fontWeight: '500' }}>
                                          {idx2 + 1}. {question.title}
                                        </td>
                                        <td
                                          style={{ textAlign: 'left', lineHeight: '120%' }}
                                          dangerouslySetInnerHTML={{ __html: question.content }}
                                        ></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              ))}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className={st.aoaChange}>
                    <div className={st.title}>정관 변경</div>
                    <button
                      type="button"
                      className={st.changeBtn}
                      onClick={() => ArticlesOfAssociationVm.articlesOfAssociationChangeModal()}
                    >
                      <span className={st.text}>정관 변경하기</span>
                    </button>
                    <img src="/images/aoa_change_info.svg" className={st.aoaChangeInfoImg} />
                  </div>
                  <div className={st.aoaHistory}>
                    <div className={st.title}>정관변경 히스토리</div>
                    <Table className={st.table}>
                      <thead>
                        <tr>
                          <th>일자</th>
                          <th>정관변경 내역</th>
                        </tr>
                      </thead>
                      <tbody>
                        {ArticlesOfAssociationVm.histories.map((history, idx) => {
                          if (idx < 5) {
                            return (
                              <tr key={idx}>
                                <td>{history.date}</td>
                                <td>
                                  {history.title}
                                  {/* <button type="button" className={st.showAoA}>[2023. 9. 10.자 주주총회 내역보기]</button> */}
                                </td>
                              </tr>
                            );
                          } else {
                            if (ArticlesOfAssociationVm.state.aoaHistoryOpenYn) {
                              return (
                                <tr key={idx}>
                                  <td>{history.date}</td>
                                  <td>
                                    {history.title}
                                    {/* <button type="button" className={st.showAoA}>[2023. 9. 10.자 주주총회 내역보기]</button> */}
                                  </td>
                                </tr>
                              );
                            }
                          }
                        })}
                      </tbody>
                    </Table>
                    {ArticlesOfAssociationVm.histories.length > 5 && (
                      <button
                        type="button"
                        className={clsx(st.moreView, ArticlesOfAssociationVm.state.aoaHistoryOpenYn ? st.open : null)}
                        onClick={() => ArticlesOfAssociationVm.aoaHistoryOpenState()}
                      >
                        더보기
                      </button>
                    )}
                  </div>
                </>
              )}
              {(ArticlesOfAssociationVm.corpType === '유한회사' ||
                ArticlesOfAssociationVm.corpType === '유한책임회사') && (
                <>
                  <div className={clsx(st.aoa, st.limit)}>
                    <div className={st.aoaDownload}>
                      <div className={st.imgDownload}>
                        <img className={st.fileImg} src="/images/aoa_sample.svg" />
                        <FileBtn
                          btnType="important"
                          className={st.downloadBtn}
                          onClick={() => ArticlesOfAssociationVm.downLoadFile()}
                        >
                          정관 파일 다운로드
                        </FileBtn>
                      </div>
                      <div className={st.updatedAt}>{ArticlesOfAssociationVm.updatedAt}</div>
                    </div>
                  </div>
                  <div className={clsx(st.aoaChange, st.limit)}>
                    <div className={st.title}>정관 변경</div>
                    <img src="/images/aoa_change_info.svg" className={st.aoaChangeInfoImg} />
                  </div>
                </>
              )}
            </div>
          </ContentBox>
        </>
      )}
    </>
  ));
}

export default ArticlesOfAssociation;
