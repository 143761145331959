import { ReactComponent as NavIcon } from '@src/images/NavIcon.svg';
import corpModel from '@src/model/Corp/corpModel';
import ScrollArea from '@src/pages/common/ScrollArea';
import { MenuType } from '@src/service/Corp/corpService.interface';
import { PeopleAdmin, Plus, TextButton, Typography } from '@standby/dike_ui';
import Color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NavBox from './components/NavBox';
import Register법인 from './components/Register법인';
import SideLink from './components/SideLink';
import st from './Side.module.scss';
import SideVm from './SideVm';

const Side = observer(() => {
  const location = useLocation();
  const selectedMenu = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    selectedMenu.current?.scrollIntoView();
  }, [location.pathname]);

  return (
    <div className={st.side}>
      <div className={st.companySelectOpen}>
        <button type="button">
          <Typography fontType="Body-16M">{SideVm.company.companyName}</Typography>
        </button>
        <div className={st.selectCompany}>
          <Link to="/">
            <div className={st.title}>
              <NavIcon />
              <Typography fontType="Body-15M">등록 법인 목록</Typography>
            </div>
          </Link>
          <div className={st.list}>
            <ScrollArea
              className={st.companyListScrollArea}
              style={{ height: SideVm.companies.length * 32 + 12 }}
              thumbWidth={4}
              thumbColor={'#D6D8DA'}
            >
              {SideVm.companies.map((company, index) => (
                <Register법인
                  key={index}
                  companyId={company.companyId}
                  isDemoYn={company.isDemoYn}
                  companyName={company.companyName}
                  isRegistering={company.status}
                />
              ))}
            </ScrollArea>
          </div>
          <Link className={st.bottomLink} to="/companyRegistration">
            <TextButton type="button" fontType="Body-14M" className={st.textButton}>
              <Plus width={16} height={16} fill={Color.grayScale700} className={st.plusIcon} />내 법인 추가하기
            </TextButton>
          </Link>
        </div>
      </div>
      <ScrollArea className={st.scrollArea} thumbWidth={4} thumbColor={'#D6D8DA'}>
        <nav>
          {SideVm.navItems().map((item, idx) => (
            <NavBox
              key={idx}
              title={item.title}
              iconType={item.iconType}
              isActive={SideVm.selectNavTitle(location.pathname) === item.pathName}
              isActiveLabel={item.isActiveLabel}
            >
              {item.links.map((link, idx) => (
                <SideLink
                  ref={location.pathname.includes(link.path) ? selectedMenu : null}
                  key={idx}
                  menuName={link.menuName}
                  path={link.path}
                  tag={link.tag}
                  boxTag={link.boxTag}
                  type={link.type as MenuType}
                  isActive={location.pathname.includes(link.basePath || link.path)}
                />
              ))}
            </NavBox>
          ))}
        </nav>
      </ScrollArea>
      <div className={st.blogWrap}>
        <div className={st.blogContent}>
          <Typography fontType="Body-14M" className={st.guideTitle}>
            꼭 알아야 할
          </Typography>
          <br />
          <div style={{ display: 'flex', flexDirection: 'column', color: 'white' }}>
            <a
              className={clsx(st.guideExplain, st.topText)}
              href="https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%A4%EB%A6%BD%20%EC%A7%81%ED%9B%84%20%ED%95%A0%20%EC%9D%BC/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography fontType="Body-14M">법인 운영 기본 가이드</Typography>
            </a>
            <a
              className={clsx(st.guideExplain, st.bottomText)}
              href="https://standbylab.featurebase.app/ko"
              target="_blank"
              rel="noreferrer"
            >
              <Typography fontType="Body-14M">개선요청/오류신고</Typography>
            </a>
          </div>
        </div>
      </div>
      <Link to={`/corp/adminSetting?corp=${corpModel.id}`}>
        <Typography fontType="Body-14M" className={st.adminSetting}>
          <PeopleAdmin width={16} height={16} fill={Color.grayScale000} style={{ marginRight: '6px' }} />
          담당자 추가/변경
        </Typography>
      </Link>
    </div>
  );
});

export default Side;
