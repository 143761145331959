import { Typography } from '@standby/dike_ui/components/atoms';
import type { ReactNode } from 'react';

import st from './style.module.scss';

interface Props {
  title: string;
  icon: ReactNode;
}

function PageMainTitle(props: Props) {
  return (
    <div className={st.wrap}>
      <div className={st.icon}>{props.icon}</div>
      <Typography fontType="Title-24M" className={st.title}>
        {props.title}
      </Typography>
    </div>
  );
}

export default PageMainTitle;
