
import { FileUploadButton, Typography } from '@standby/dike_ui/components/atoms';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';

import st from './style.module.scss'

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropImageFile(e: React.DragEvent<HTMLDivElement>): void
}

const FileUploadBox = observer((props: Props) => {
  // browser 에 정의된 drag 기본 동작 disable
  const onDrag = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  return (
    <div className={st.box}
      onDragOver={onDrag}
      onDragLeave={onDrag}
      onDragEnter={onDrag}
      onDragEnd={onDrag}
      onDrop={props.onDropImageFile}
    >
      <Typography fontType='Title-16B'>이미지 업로드</Typography>
      <Typography fontType='Body-16R' className={st.explain}>인감을 하얀 종이에 찍어 업로드 해주세요.</Typography>
      <FileUploadButton
        className={st.upload}
        label="5MB 이하의 jpg, jpeg, png 파일을 업로드 해주세요."
        onChange={props.onChange}
        accept={'.jpg, .jpeg, .png'}
      />
    </div>
  );
});

export default FileUploadBox;

