import { useObserver } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';

import Agenda from './Agenda/Agenda';
import BeforeFinal from './FinalCheck/BeforeFinal/BeforeFinal';
import Final from './FinalCheck/Final/Final';
import Meetings from './Meetings/Meetings';

function Step() {
  return useObserver(() => (
    <Routes>
      {/* 안건스탭 */}
      <Route path="/AGENDA" element={<Agenda />}></Route>
      {/* 주주총회 이사회 정보 */}
      <Route path="/MEETINGS" element={<Meetings />}></Route>
      {/* 최종전 페이지 */}
      <Route path="/BEFORE_FINAL" element={<BeforeFinal />}></Route>
      {/* 최종 페이지 */}
      <Route path="/FINAL" element={<Final />}></Route>
    </Routes>
  ));
}

export default Step;
