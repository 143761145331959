import dateListModel from '@model/Corp/Agenda/Step/dateListModel';
import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import type { MeetingDetailType } from '@model/Corp/Agenda/Step/meetingsStepModel.interface';
import corpModel from '@model/Corp/corpModel';
import { runInAction } from 'mobx';
import type React from 'react';

class NotiOfDraftVm {
  musterNoticeType(idx: number) {
    return meetingsStepModel.tabList[idx].musterNoticeType;
  }
  resetDecisionAt(idx: number) {
    dateListModel.dateList?.forEach((date) => {
      date.decisionAt.year = meetingsStepModel.tabList[idx]?.today?.year;
      date.decisionAt.month = meetingsStepModel.tabList[idx]?.today?.month;
      date.decisionAt.day = meetingsStepModel.tabList[idx]?.today?.day;
    });
  }
  setMusterNoticeType(e: React.ChangeEvent<HTMLInputElement>, idx: number) {
    runInAction(() => {
      meetingsStepModel.tabList[idx].musterNoticeType = e.target.value;
      dateListModel.musterNoticeType = e.target.value;

      const dateList = dateListModel.dateList;

      let _title = '';
      let _type: MeetingDetailType;

      if (meetingsStepModel.tabList[idx].regularYn) {
        _title = '정기주주총회 소집통지';
        _type = '정기주주총회_소집통지';
      } else {
        _title = '주주총회 소집통지';
        _type = '주주총회_소집통지';
      }

      if (dateListModel.musterNoticeType === '온라인' || dateListModel.musterNoticeType === '우편') {
        //소집통지 진행
        let pushIdx = null;

        if (dateList?.some((data) => data.type === '주주전원의_서면결의')) pushIdx = 2;
        else pushIdx = 1;

        if (dateList?.some((data) => data.type === _type) === false) {
          this.resetDecisionAt(idx);

          if (dateListModel.dateList) {
            dateListModel.dateList?.splice(pushIdx, 0, {
              title: _title,
              type: _type,
              decisionAt: {
                year: dateListModel.dateList[pushIdx - 1].decisionAt?.year,
                month: dateListModel.dateList[pushIdx - 1].decisionAt.month,
                day: dateListModel.dateList[pushIdx - 1].decisionAt.day,
                hour: '13',
                minute: '00',
              },
              의결장소Type: '',
              place: '',
              hasRegistrationMattersYn: false,
              agendas: [],
              uneditableDateYn: dateListModel.dateList[pushIdx - 1].uneditableDateYn,
            });
          }
        }
      } else {
        //소집통지 생략
        if (dateList.some((data) => data.type === _type)) {
          const findIdx = dateListModel.dateList.findIndex((obj) => obj.type === _type);

          dateListModel.dateList.splice(findIdx, 1);

          this.resetDecisionAt(idx);
        }
      }
    });
  }
  get hasCapitalOverBillionYn() {
    return meetingsStepModel.hasCapitalOverBillionYn;
  }
  get companyName() {
    return corpModel.company.companyName;
  }
}

export default new NotiOfDraftVm();
