import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import { useQueryClient } from '@tanstack/react-query';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { queryKey, useSealAuthorizedQuery } from '../queries';
import st from './style.module.scss';

interface Props {
  corpId: string;
}

const AuthorizedSealSelect = observer((props: Props) => {
  const { data } = useSealAuthorizedQuery(props.corpId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      SealSelectModel.refresh.refreshCompanyId &&
      String(SealSelectModel.refresh.refreshCompanyId) === String(props.corpId)
    ) {
      void queryClient.invalidateQueries({ queryKey: queryKey.sealAuthorized(props.corpId).queryKey });
    }

    SealSelectModel.refresh.refreshCompanyId = undefined;
  }, [SealSelectModel.refresh.refreshCompanyId]);

  useEffect(() => {
    return () => {
      runInAction(() => {
        SealSelectModel.refresh.refreshCompanyId = undefined;
        SealSelectModel.selectedAuthorizedSeal = undefined;
      });
    };
  }, []);

  return (
    <div className={st.item}>
      <div className={st.itemTitle}>인감선택</div>
      <ul className={st.sealList}>
        {data.authorizedSeals.map((authorizedSeal) => (
          <li key={authorizedSeal.id}>
            <label className={st.sealLabel}>
              <div className={st.imgWrap}>
                <img className={st.sealImg} src={authorizedSeal.image} />
              </div>
              <div className={st.content}>
                <div className={st.info}>
                  <div className={st.badge}>온라인 사용인감</div>
                  <div className={st.sealTitle}>{authorizedSeal.alias}</div>
                </div>
                <div className={st.radioWrap}>
                  <Radio
                    name={`authorizedSealRadio`}
                    value={authorizedSeal.id}
                    checked={authorizedSeal.id === SealSelectModel.selectedAuthorizedSeal}
                    onChange={(e) => (SealSelectModel.selectedAuthorizedSeal = Number(e.target.value))}
                  ></Radio>
                </div>
              </div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
});

export default AuthorizedSealSelect;
