import { _delete, downloadFileGet, get, post, put } from '@common/module/httpRequest';
import dateListModel from '@model/Corp/Agenda/Step/dateListModel';
import corpModel from '@model/Corp/corpModel';
import { pageRouter } from '@model/pageModel';
import beforeFinalModel from '@src/model/Corp/Agenda/Step/FinalCheck/BeforeFinal/beforeFinalModel';
import { runInAction } from 'mobx';

import type {
  fileUploadRq,
  InfoDetail,
  I등기대행,
  I등기연결,
  I서류자동작성,
  I셀프등기,
  I소집통지_온라인,
  I소집통지_우편,
  I수신동의,
  I신주발행통지,
  I주식발행공고,
  I주주동의_신주발행,
  I주주동의_주주총회,
  I주주동의_주주총회_신주발행,
  I파일_업로드,
  LoadRs,
  LoadSchedulesRs,
  NextValidationRs,
  SectionList,
  SectionType,
  SubmitRq,
  ValidationSchedulesParam,
  ValidationSchedulesRq,
} from './beforeFinalService.interface';

class BeforeFinalStepService {
  infoDetail(sectionType: SectionType, infoDetail: InfoDetail) {
    switch (sectionType) {
      case '수신동의':
        {
          const _infoDetail = infoDetail as I수신동의;

          return {
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                completedDate: shareholder.completedDate || '',
                downloadUrl: shareholder.downloadUrl || '',
              };
            }),
            completed: _infoDetail?.completed || false,
          } as I수신동의;
        }

        break;
      case '소집통지_온라인':
        {
          const _infoDetail = infoDetail as I소집통지_온라인;

          return {
            date: _infoDetail.date || '',
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                completedDate: shareholder.completedDate || '',
              };
            }),
          } as I소집통지_온라인;
        }

        break;
      case '소집통지_우편':
        {
          const _infoDetail = infoDetail as I소집통지_우편;

          return {
            date: _infoDetail?.date || '',
          } as I소집통지_우편;
        }

        break;
      case '주주동의_주주총회':
        {
          const _infoDetail = infoDetail as I주주동의_주주총회;

          return {
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                downloadUrl: shareholder.downloadUrl || '',
                completedDate: shareholder.completedDate || '',
              };
            }),
            completed: _infoDetail?.completed || false,
          } as I주주동의_주주총회;
        }

        break;
      case '주주동의_주주총회_신주발행':
        {
          const _infoDetail = infoDetail as I주주동의_주주총회_신주발행;

          return {
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                downloadUrl: shareholder.downloadUrl || '',
                completedDate: shareholder.completedDate || '',
              };
            }),
            completed: _infoDetail?.completed || false,
          } as I주주동의_주주총회_신주발행;
        }

        break;
      case '신주발행통지':
        {
          const _infoDetail = infoDetail as I신주발행통지;

          return {
            date: _infoDetail?.date || '',
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                completedDate: shareholder.completedDate || '',
              };
            }),
          } as I신주발행통지;
        }

        break;
      case '주주동의_신주발행':
        {
          const _infoDetail = infoDetail as I주주동의_신주발행;

          return {
            shareholders: _infoDetail?.shareholders.map((shareholder) => {
              return {
                id: shareholder.id || -1,
                name: shareholder.name || '',
                status: shareholder.status || null,
                stocks: shareholder.stocks || [],
                downloadUrl: shareholder.downloadUrl || '',
                completedDate: shareholder.completedDate || '',
              };
            }),
            completed: _infoDetail?.completed || false,
          } as I주주동의_신주발행;
        }

        break;
      case '주식발행공고':
        {
          const _infoDetail = infoDetail as I주식발행공고;

          return {
            announceType: _infoDetail.announceType || '',
            assignmentType: _infoDetail.assignmentType || '',
            announcedDate: _infoDetail.announcedDate || '',
            newspaper: _infoDetail.newspaper || '',
          } as I주식발행공고;
        }

        break;
      case '등기대행':
        {
          const _infoDetail = infoDetail as I등기대행;

          return {
            steps: _infoDetail.steps || null,
          } as I등기대행;
        }

        break;
      case '등기대행_기진행':
        {
          const _infoDetail = infoDetail as I등기연결;

          return {
            steps: _infoDetail.steps || null,
          } as I등기연결;
        }

        break;
      case '셀프등기':
        {
          const _infoDetail = infoDetail as I셀프등기;

          return {
            status: _infoDetail?.status || null,
          } as I셀프등기;
        }

        break;
      case '서류자동작성':
        {
          const _infoDetail = infoDetail as I서류자동작성;

          return {
            documents: _infoDetail?.documents.map((document) => {
              return {
                title: document.title || '',
                date: document.date || '',
                downloadUrl: document.downloadUrl || '',
                thumbnailUrl: document.thumbnailUrl || '',
                id: document.id || '',
                isActiveButton: document.isActiveButton || false,
              };
            }),
          } as I서류자동작성;
        }

        break;
      case '파일_업로드':
        {
          const _infoDetail = infoDetail as I파일_업로드;

          return {
            uploadFileList: _infoDetail?.uploadFileList.map((uploadFile) => {
              const file = uploadFile.file && {
                id: uploadFile.file.id || -1,
                name: uploadFile.file.name || '',
                downloadUrl: uploadFile.file.downloadUrl || '',
              };
              const files =
                uploadFile.files &&
                uploadFile.files.map((file) => {
                  return {
                    id: file.id || -1,
                    name: file.name || '',
                    downloadUrl: file.downloadUrl || '',
                  };
                });

              return {
                id: uploadFile.id || -1,
                documentType: uploadFile.documentType || '',
                name: uploadFile.name || '',
                required: uploadFile.required || false,
                ...(file && { file: file }),
                ...(files && { files: files }),
              };
            }),
          } as I파일_업로드;
        }

        break;
    }
  }
  setSectionList(sectionList: SectionList[]) {
    beforeFinalModel.sectionList =
      sectionList.map((section) => {
        return {
          type: section.type,
          info: this.infoDetail(section.type, section.info),
        };
      }) || [];
  }
  async load(agendaProgressId: string) {
    const res = await get<LoadRs>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal`);

    runInAction(() => {
      beforeFinalModel.title = res.data.title || '';
      beforeFinalModel.registrationAgencyType = res.data.registrationAgencyType || 'NONE';
      beforeFinalModel.showAgendaPopUpButton = res.data.showAgendaPopUpButton || false;
      beforeFinalModel.isRegular = res.data.isRegular || false;
      beforeFinalModel.meetingSchedule = res.data.meetingSchedule || [];
      beforeFinalModel.newStockAcquirerInfo = res.data?.newStockAcquirerInfo || undefined;
      beforeFinalModel.representativeAddressChangeIsHide = res.data?.representativeAddressChangeIsHide || false;
      this.setSectionList(res.data.sectionList);
    });
  }
  async fileDownload(downloadLink: string) {
    await downloadFileGet(downloadLink);
  }
  async fileAllDownload(agendaProgressId: string, type: SectionType) {
    await downloadFileGet(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/allDownload?type=${type}`,
    );
  }
  async fileUpload(file: FileList, agendaProgressId: string, uploadFileId: number, documentType: string) {
    const formData = new FormData();

    formData.append('id', String(uploadFileId));
    formData.append('documentType', documentType);
    if (file && file.length) {
      for (let i = 0; i < file.length; i++) {
        formData.append('file', file[i]);
      }
    }

    const res = await post<fileUploadRq>(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/file`,
      formData,
    );

    return res.data;
  }
  async fileDelete(agendaProgressId: string, fileListId: number, uploadFileId: number, documentType: string) {
    await _delete(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/document/file?id=${uploadFileId}&type=${documentType}`,
    );

    const sectionListIdx = beforeFinalModel.sectionList.findIndex((item) => item.type === '파일_업로드');

    if (sectionListIdx !== -1) {
      const info = beforeFinalModel.sectionList[sectionListIdx].info as I파일_업로드;
      const uploadFileListIdx = info.uploadFileList.findIndex((item) => item.id === fileListId);

      runInAction(() => {
        if (documentType === '등기부' || documentType === '주주명부' || documentType === '정관') {
          info.uploadFileList[uploadFileListIdx].file = undefined;
        } else {
          if (info.uploadFileList[uploadFileListIdx]?.files) {
            const filesIdx = info.uploadFileList[uploadFileListIdx]?.files?.findIndex(
              (item) => item.id === uploadFileId,
            );

            if (filesIdx !== undefined && filesIdx !== -1) {
              info.uploadFileList[uploadFileListIdx].files?.splice(filesIdx, 1);
            }
          }
        }
      });
    }
  }
  async submit(agendaProgressId: string) {
    const res = await post<SubmitRq>(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/next`);

    pageRouter.replace(`/corp/agenda/step/${res.data.step}/?corp=${corpModel.id}&agendaProgressId=${agendaProgressId}`);
  }
  async nextValidation(agendaProgressId: string) {
    const res = await get<NextValidationRs>(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/validate`,
    );

    runInAction(() => {
      beforeFinalModel.submitValidation.validated = res.data.validated ?? false;
      beforeFinalModel.submitValidation.unValidatedList =
        res.data.unValidatedList && res.data.unValidatedList.length !== 0
          ? res.data.unValidatedList.map((item) => {
              return {
                type: item.type || null,
                name: item.name || '',
                count: item.count || -1,
                status: item.status || null,
              };
            })
          : [];
      beforeFinalModel.submitValidation.newSharesIncludeYn = res.data.newSharesIncludeYn ?? false;
    });
  }
  async loadSchedules(agendaProgressId: string) {
    const res = await get<LoadSchedulesRs>(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/schedules`,
    );

    runInAction(() => {
      dateListModel.onlyRepresentativeChangedAddressYn = res.data.onlyRepresentativeChangedAddressYn ?? false;
      dateListModel.hasCapitalOverBillionYn = res.data.hasCapitalOverBillionYn ?? false;
      dateListModel.dateList = res.data.schedules || [];
      dateListModel.musterNoticeType = res.data.musterNoticeType || '';
      dateListModel.processStatus = res.data.processStatus || '';
      dateListModel.meetingScheduleChangeLockYn = res.data.meetingScheduleChangeLockYn || false;
    });
  }
  async validationSchedules(agendaProgressId: string, param: ValidationSchedulesParam) {
    const res = await post<ValidationSchedulesRq>(
      `/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/schedules/validation`,
      param,
    );

    runInAction(() => {
      beforeFinalModel.changedSchedule.validated = res.data.validated ?? false;
      beforeFinalModel.changedSchedule.changedSchedules =
        res.data.changedSchedules && res.data.changedSchedules.length !== 0
          ? res.data.changedSchedules.map((item) => {
              return {
                type: item.type || '',
                date: item.date || '',
                changedInfo: {
                  date: item.changedInfo.date ?? false,
                  time: item.changedInfo.time ?? false,
                  place: item.changedInfo.place ?? false,
                },
              };
            })
          : [];
    });
  }
  async submitSchedules(agendaProgressId: string, param: ValidationSchedulesParam) {
    await put(`/company/${corpModel.id}/agendaProgress/${agendaProgressId}/beforeFinal/schedules`, param);
  }
}

export default new BeforeFinalStepService();
