import type { Notification } from '@src/model/notificationModel';
import { MarkBang } from '@standby/dike_ui/assets/icon/fill';
import { Button, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';

import st from './style.module.scss';

interface InvitationCardProps {
  notification: Notification;
  acceptInvitation: (callbackUrl: string, acceptYn: boolean, notificationId: number | null) => void;
}

const InvitationCard = observer(({ notification, acceptInvitation }: InvitationCardProps) => {
  return (
    <div className={st.confirm}>
      <div className={st.content}>
        <MarkBang width={20} height={20} fill={color.primary400} />
        <div className={st.message}>
          <Typography fontType="Body-14R" style={{ color: color.grayScale900 }}>
            {notification.content}
          </Typography>
          <Typography fontType="Detail-12M" style={{ color: color.grayScale500 }}>
            {notification.createdAt}
          </Typography>
        </div>
      </div>
      <div className={st.btnArea}>
        <Button
          variant="contained"
          color="primary400"
          onClick={() => acceptInvitation(notification.callbackUrl, true, notification.notificationId)}
        >
          수락
        </Button>
        <Button
          variant="outlined"
          color="primary400"
          onClick={() => acceptInvitation(notification.callbackUrl, false, notification.notificationId)}
        >
          거절
        </Button>
      </div>
    </div>
  );
});

export default InvitationCard;
