import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './FirstPageVm';
import st from './FirstPage.module.scss';
import clsx from 'clsx';

function FirstPage() {
  return useObserver(() => (
    <div className={st.firstPage}>
      <div
        className={clsx(
          st.exampleImgBox,
          vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'JINPYUNG' && st.jinpyeongHelpme,
        )}
      >
        {(vm.pageType === 'INIT_HELPME' || vm.pageType === 'INIT_OTHER') && vm.acctFirmCode === 'JINPYUNG' && (
          <>
            <img src="/images/book_keeping_jp.png" useMap="#jpMap" alt="수임등록 설명 이미지" />
            <map name="jpMap">
              <area shape="rect" coords="462,861,578,885" href="https://jpac.kr/company" target="_blank" />
            </map>
          </>
        )}
        {vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'BARUN' && (
          <img src="/images/bookKeeping_helpme_barun.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_OTHER' && vm.acctFirmCode === 'BARUN' && (
          <img src="/images/book_keeping_other_barun.png" alt="수임등록 설명 이미지" />
        )}
        {vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'BARUN' && (
          <>
            <div className={st.serviceInfoBox}>
              <div className={st.info}>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.calculator)}>법인기장이란?</div>
                  <div className={st.content}>
                    입출금, 매출 등 <span className={st.bold}>모든 내역</span>을<br />
                    <span className={st.bold}>복식부기</span> 방식으로
                    <br />
                    기록하는 것!
                  </div>
                </div>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.document)}>법인기장 필요성?</div>
                  <div className={st.content}>
                    법인은 복식부기{' '}
                    <span className={st.bold}>
                      위반시 <br />
                      세금폭탄
                    </span>{' '}
                    발생
                  </div>
                </div>
                <div className={st.serviceBox}>
                  <div className={clsx(st.title, st.handshake)}>효율적 해결법?</div>
                  <div className={st.content}>
                    성장에 집중하고
                    <br />
                    <span className={st.bold}>관리는 회계 사무소</span>에<br />
                    맡기는 것이 훨씬 <span className={st.bold}>효율적!</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={st.helpmeInfoBox}>
              <div className={st.info}>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.helpme)}>헬프미 설립 Only</div>
                  <div className={st.content}>
                    헬프미 고객을 위한
                    <br />
                    특별한 혜택!
                  </div>
                </div>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.emptyCalendar)}>
                    <span className={st.freeMonth}>{vm.freeMonth}</span>
                    {vm.freeMonth}개월 무료
                  </div>
                  <div className={st.content}>
                    {vm.freeMonth}개월동안 무료로
                    <br />
                    이용 가능!
                  </div>
                </div>
                <div className={st.helpmeBox}>
                  <div className={clsx(st.title, st.calendar)}>약정 없이 이용 가능</div>
                  <div className={st.content}>
                    필수이용 약정기간 없는
                    <br />
                    순수한 혜택!
                  </div>
                </div>
              </div>
              <div className={st.explain}>
                * 무료기장서비스는 헬프미 고객을 위한 특별한 혜택이기 때문에,{' '}
                <span className={st.bold}>법인설립일로부터 10일간만</span> 신청이 가능해요. 양해부탁드려요.
              </div>
            </div>
          </>
        )}
        {(vm.pageType === 'INIT_HELPME' || vm.pageType === 'INIT_OTHER') && vm.acctFirmCode === 'JINPYUNG' && (
          <a target="_blank" href="https://www.youtube.com/watch?v=C1BqMjKMAWY" className={st.jinpyeongVideo}>
            <img src="/images/jinpyeongVideo.png" alt="진평소개 비디오 링크" />
          </a>
        )}
        {vm.pageType === 'INIT_HELPME' && vm.acctFirmCode === 'JINPYUNG' && (
          <img src="/images/book_keeping_helpme_jp.png" alt="진평소개" className={st.jinpyeongInfo} />
        )}
      </div>
      <button type="button" className={st.subApplyBtn} onClick={() => vm.apply()}>
        기장 서비스 신청
      </button>
      <button type="button" className={st.mainApplyBtn} onClick={() => vm.apply()}>
        <div className={st.btnContent}>기장 서비스 상담 신청</div>
        <div className={st.explain}>오른쪽 아래 채팅상담으로 바로 문의해도 돼요!</div>
      </button>
    </div>
  ));
}
export default FirstPage;
