import adminModel from '@model/Corp/AdminSetting/adminModel';
import corpModel from '@model/Corp/corpModel';
import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import { Alert } from '@src/model/newDialogModel';
import { UnorderedList } from '@standby/dike_ui/components/atoms';
import { runInAction } from 'mobx';
import React, { useEffect, useState } from 'react';

import type { Props } from './types';

enum ExecutiveIdKey {
  Executive = 'executiveId',
  Agenda = 'agendaExecutiveId',
}

const useSealSelect = ({
  data,
  type,
  changeEventCallback,
  includeCorporateSeal = true,
  isSignature = false,
}: Props) => {
  const [useSeal, setUseSeal] = useState(false);

  const pickExecutive = (executiveId: string | number | undefined) => {
    if (!executiveId) {
      return;
    }

    return data.executives?.find((executive) => String(executive.id) === String(executiveId));
  };

  const insertAllExecutive = () => {
    SealSelectModel.selectedSeals = data.executives.map((executive) => ({
      [ExecutiveIdKey[type]]: executive.id,
      sealId: useSeal ? executive?.seal?.id : undefined,
    }));
  };

  const changeExecutive = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'ALL') {
      insertAllExecutive();
    } else {
      SealSelectModel.selectedSeals = [
        {
          [ExecutiveIdKey[type]]: Number(e.target.value),
          sealId: useSeal ? pickExecutive(e.target.value)?.seal?.id : undefined,
        },
      ];
    }

    changeEventCallback && changeEventCallback();
  };

  const changeUseSeal = (e: React.ChangeEvent<HTMLInputElement>) => {
    runInAction(() => {
      setUseSeal(e.target.value === 'true');
      if (e.target.value === 'false') {
        SealSelectModel.selectedSeals = SealSelectModel.selectedSeals.map((selectedSeal) => ({
          ...selectedSeal,
          sealId: undefined,
        }));
      } else {
        SealSelectModel.selectedSeals = SealSelectModel.selectedSeals.map((selectedSeal) => ({
          ...selectedSeal,
          sealId: pickExecutive(selectedSeal[ExecutiveIdKey[type]])?.seal?.id,
        }));
      }
    });

    changeEventCallback && changeEventCallback();
  };

  const createSeal = () => {
    /**
     * @todo adminModel이 ts로 되어있지 않아서 임시적으로 ts-ignore적용 adminModel ts로 개선후에 제거 필요함.
     */
    //@ts-ignore
    if (adminModel.authority.menus?.SE?.useYn) {
      window.open(`/corp/seal/create?corp=${corpModel.id}`);
    } else {
      void Alert(
        `법인인감 등록 권한이 없어요!`,
        <UnorderedList>
          <li>[법인인감]페이지 권한이 있으면, 법인인감을 등록할 수 있어요. 최고 관리자에게 권한을 요청해 주세요.</li>
        </UnorderedList>,
      );
    }
  };

  useEffect(() => {
    return () => {
      //다른 서류에서 쓸 수 있기 때문에 언마운트되면 선택목록을 모두비워줌.
      SealSelectModel.selectedSeals = [];
    };
  }, []);

  useEffect(() => {
    //대표가 한명이면 selected를 넣어주고 시작한다
    if (data.executives.length === 1) {
      SealSelectModel.selectedSeals = [
        {
          [ExecutiveIdKey[type]]: data.executives[0].id,
          sealId: undefined,
        },
      ];
      changeEventCallback && changeEventCallback();
    }
    //사용인감만 사용하거나 서명인경우 경우 위의 옵션을 선택해둔다
    if (includeCorporateSeal === false || isSignature === true) {
      //대표 모두 넣음
      insertAllExecutive();
      //인감날인 사용으로 변경
      setUseSeal(true);
    }
  }, [data]);

  return {
    data,
    includeCorporateSeal,
    changeExecutive,
    changeUseSeal,
    useSeal,
    pickExecutive,
    ExecutiveIdKey,
    type,
    changeEventCallback,
    createSeal,
    isSignature,
  };
};

export default useSealSelect;
