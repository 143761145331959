import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';
import vm from '../../UserDashboardVm';

const Card필요 = observer((props: { company: CompanyInfo }) => {
  const minDay = vm.minDay(props.company.executiveMembers);

  return (
    <>
      <div className={st.tagContent}>
        <div className={st.tagWrap}>
          <Typography fontType="Detail-12M" className={st.tag} data-type="필요">
            필요
          </Typography>
        </div>
        <div className={st.text}>
          <Typography fontType="Body-14R" className={st.mainText}>
            임원{' '}
            <span className={st.importantText} data-type="필요">
              임기연장
            </span>{' '}
            필요!
          </Typography>
        </div>
      </div>
      {minDay && (
        <Typography fontType="Detail-12M" className={st.subText}>
          {minDay.name}님의 임기가{' '}
          <span className={st.importantText} data-type="필요">
            {minDay.positionExpiredDays}일
          </span>{' '}
          남았어요.
        </Typography>
      )}
    </>
  );
});

export default Card필요;
