import { BookPeople, BookRule, CompanyBuild, CompanyHome, Money, PeopleMoney } from '@standby/dike_ui/assets/icon/fill';
import color from '@standby/dike_ui/design-token/color.module.scss';

export const tipList = [
  {
    title: '법인 계좌 개설하는 방법',
    url: 'https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8%EA%B3%84%EC%A2%8C%EA%B0%9C%EC%84%A4%ED%95%98%EB%8A%94%EB%B2%95/',
    content: '이 글만 따라하면 계좌 개설 과정에서 실패할 가능성이 거의 없어요!',
  },
  {
    title: '법인계좌 개설 후, 전자세금용 공동인증서 발급 받는 방법',
    url: 'https://standby.kr/blog/article/%EC%82%AC%EC%97%85%EC%9E%90_%EA%B3%B5%EB%8F%99%EC%9D%B8%EC%A6%9D%EC%84%9C_%EB%B0%9C%EA%B8%89/',
    content: '잊지말고 꼭 해주세요! 사업이 훨씬 쉬워져요.',
  },
  {
    title: '법인 자본금은 이 때까지 꼭 이체하세요!',
    url: 'https://standby.kr/blog/article/%EB%B2%95%EC%9D%B8_%EC%9E%90%EB%B3%B8%EA%B8%88%EC%9D%B4%EC%B2%B4/',
    content: '법인 자본금 이체 타이밍을 딱 정해드립니다!',
  },
  {
    title: '대표님이 꼭 알아야 할 연간 세무 일정',
    url: 'https://standby.kr/blog/article/%EC%97%B0%EA%B0%84%EC%84%B8%EB%AC%B4%EC%9D%BC%EC%A0%95/',
    content: '꼭 알아야하는 연간 세무 일정',
  },
  {
    title: '각종 공과금을 개인명의에서 사업자 명의로 변경 하는 법 (전기세, 인터넷 등)',
    url: 'https://standby.kr/blog/article/%EA%B3%B5%EA%B3%BC%EA%B8%88_%EB%AA%85%EC%9D%98%EB%B3%80%EA%B2%BD/',
    content: '이제 대표님이 되셨으니, 개인 명의에서 사업자 명의로 바로 변경하는건 어떠세요?',
  },
];

export const guideList = [
  {
    title: '법인등기부등본의 모든 것',
    url: 'https://standby.kr/blog/group/%EB%B2%95%EC%9D%B8%20%EB%93%B1%EA%B8%B0%EB%B6%80%EB%93%B1%EB%B3%B8%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/',
    content: '법인등기부등본, 어디까지 알고 있나요?',
    icon: <CompanyBuild width="24" height="24" fill={color.primary400} />,
  },
  {
    title: '법인정관의 모든 것',
    url: 'https://standby.kr/blog/group/%EB%B2%95%EC%9D%B8%EC%A0%95%EA%B4%80%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/',
    content: '정관은 회사의 ‘헌법’이라고 할 수 있죠!',
    icon: <BookRule width="24" height="24" fill={color.siGreen800} />,
  },
  {
    title: '주주명부의 모든 것',
    url: 'https://standby.kr/blog/group/%EC%A3%BC%EC%A3%BC%EB%AA%85%EB%B6%80%EC%9D%98%20%EB%AA%A8%EB%93%A0%20%EA%B2%83/',
    content: '주주명부 관리는 이렇게 하세요!',
    icon: <BookPeople width="24" height="24" fill={color.primary800} />,
  },
  {
    title: '사업자등록증',
    url: 'https://standby.kr/blog/category/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A6%9D/',
    content: '사업자등록증에 관한 모든 것',
    icon: <CompanyHome width="24" height="24" fill={color.primary800} />,
  },
  {
    title: '주주 & 자본금',
    url: 'https://standby.kr/blog/category/%EC%A3%BC%EC%A3%BC&%EC%9E%90%EB%B3%B8%EA%B8%88/',
    content: '주주와 자본금, 어디까지 알고 있나요?',
    icon: <PeopleMoney width="24" height="24" fill={color.primary800} />,
  },
  {
    title: '법인세 & 부가세',
    url: 'https://standby.kr/blog/category/%EB%B2%95%EC%9D%B8%EC%84%B8&%EB%B6%80%EA%B0%80%EC%84%B8/',
    content: '이제 세금으로 머리 아프지 마세요!',
    icon: <Money width="24" height="24" fill={color.primary800} />,
  },
];
