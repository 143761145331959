import { makeObservable, observable } from 'mobx';

import type {
  CorporateTax,
  CorporateTaxes,
  Guide,
  TaxFilingPreparationData,
  TaxFilingState,
} from './corporateTaxModel.interface';

class CorporateTaxModelClass implements CorporateTax {
  guides: Guide[] = [];
  taxFilingState: TaxFilingState = {
    selectableCorporateTaxes: [],
    steps: [],
    settlementPeriod: {
      startAt: {
        year: '',
        month: '',
        day: '',
      },
      endAt: {
        year: '',
        month: '',
        day: '',
      },
    },
    taxFilingEndAt: {
      year: '',
      month: '',
      day: '',
    },
    faithfulnessTaxPayerYn: null,
    settlementMonth: '',
  };
  taxFilingPreparationData: TaxFilingPreparationData = {
    deadline: {
      year: '',
      month: '',
      day: '',
    },
    emailToSubmit: '',
  };
  corporateTaxes: CorporateTaxes[] = [];
  // 값이 있으면 기장계약 해지 상태
  bookkeepingServiceEndAt: string | null = null;

  constructor() {
    makeObservable(this, {
      guides: observable,
      taxFilingState: observable,
      taxFilingPreparationData: observable,
      corporateTaxes: observable,
    });
  }
}

export default new CorporateTaxModelClass();
