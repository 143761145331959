import { useObserver } from 'mobx-react';

import st from './FirstPage.module.scss';

function FirstPage() {
  return useObserver(() => (
    <>
      <img src="/images/vatTax_first_page.png?1" className={st.image} />
    </>
  ));
}

export default FirstPage;
