import Nukki from '@src/common/module/Nukki';
import formDataToJson from '@src/common/module/submit';
import { Alert } from '@src/model/newDialogModel';
import { type PostSealRq, StampEnum, type StampType } from '@src/service/Corp/Seal/SealService.interface';
import { useLocalObservable } from 'mobx-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePostSealMutation, useRepresentativeListQuery } from '../../queries';

export interface SealMob {
  executiveId: number | null;
  stampType: StampType | null;
  alias: string;
  setAlias: (alias: string) => void;
}

const useCreateSeal = (corpId: string) => {
  const navigation = useNavigate();

  const { data, isSuccess } = useRepresentativeListQuery(corpId);
  const uploadMutation = usePostSealMutation(corpId);

  const sealMob = useLocalObservable<SealMob>(() => ({
    executiveId: null,
    stampType: null,
    alias: '',
    setAlias(alias: string) {
      if (alias.length <= 20) {
        this.alias = alias;
      }
    },
  }));

  const nukkiRef = useRef(new Nukki());

  const [imageFile, setImageFile] = useState<File | null>(null);
  // 이미지 파일이 업로드 될 때마다 새로운 input 으로 갱신해 다시 이미지를 삽입할 수 있게 하는 상태
  const [uploadKey, setUploadKey] = useState<number>(0);

  const uploadImageFile = useCallback((file?: File) => {
    if (!file) {
      void Alert('파일 업로드 실패', '파일이 없습니다.');
      setImageFile(null);

      return;
    }

    if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) || file.size > 5 * 1024 * 1024) {
      void Alert('파일 업로드 실패', '5MB이하의 jpg, jpeg, png 파일을 업로드 해주세요.');
      setImageFile(null);

      return;
    }

    setImageFile(file);
  }, []);

  const onUploadImageFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadKey((prev) => prev + 1);

    uploadImageFile(e.target?.files?.[0]);
  }, []);

  const onDropImageFile = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    uploadImageFile(e.dataTransfer?.files?.[0]);
  }, []);

  const onDeleteImageFile = useCallback(() => {
    nukkiRef.current.clear();
    setImageFile(null);
  }, []);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 필수 항목 검증
    if (
      formDataToJson(e.target).error ||
      !sealMob.stampType ||
      !sealMob.alias ||
      (sealMob.stampType === StampEnum.CORPORATE && !sealMob.executiveId)
    ) {
      // 다음의 경우 Alert 으로 가이드
      if (!sealMob.stampType) {
        void Alert('인감 유형을 선택해주세요.');
      } else if (!sealMob.alias) {
        void Alert('별칭을 입력해주세요.');
      } else if (sealMob.stampType === StampEnum.CORPORATE && !sealMob.executiveId) {
        void Alert('대표이사를 선택해주세요.');
      }

      return;
    }

    if (!imageFile) {
      return void Alert('이미지를 업로드해주세요.');
    }

    const imageDataUrl = await nukkiRef.current.exportImageToProcessedBase64();

    if (!imageDataUrl) {
      return void Alert('이미지 프로세싱 실패', '새로고침 후 다시 시도해주세요.');
    }

    const body: PostSealRq = {
      alias: sealMob.alias.trim(),
      stampType: sealMob.stampType as StampType,
      image: imageDataUrl ?? '',
    };

    if (body.stampType === StampEnum.CORPORATE && sealMob.executiveId) {
      body.executiveId = sealMob.executiveId;
    }

    uploadMutation.mutate(body, {
      onSuccess() {
        navigation(`/corp/seal?corp=${corpId}`, { replace: true });
      },
      onError(e) {
        console.error(e);
      },
    });
  };

  useEffect(() => {
    if (imageFile) {
      nukkiRef.current.insertImageFile(imageFile);
    } else {
      nukkiRef.current.clear();
    }
  }, [imageFile]);

  // 대표가 1명일 경우, 유형_법인인감 선택필드 Default로 해당 대표 자동선택
  useEffect(() => {
    if (isSuccess && data.representatives?.length === 1) {
      sealMob.executiveId = data.representatives?.[0]?.id || null;
    }
  }, [data.representatives, isSuccess]);

  return {
    sealMob,
    nukki: nukkiRef.current,
    uploadKey,
    imageFile,
    representatives: data.representatives,
    onUploadImageFile,
    onDeleteImageFile,
    onDropImageFile,
    submit,
  };
};

export default useCreateSeal;
