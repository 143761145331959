import { config, FabricImage, filters } from 'fabric';

import { normalize, resetObjectTransform } from '../utils';
import RedFilter from './RedFilter';
import { convertToPngSrc, loadImageAsync } from './utils';

// webgl 사용시 image 용량 제한이 있어서 비활성화
config.enableGLFiltering = false;

class NukkiImage {
  static name = 'image';

  /**
   * @type {Canvas}
   */
  _canvas;
  /**
   * @type {FabricImage}
   */
  _imageObject;
  /**
   * @type {HTMLImageElement}
   */
  _originalImage;
  /**
   * contrast value, range from -1 to 1.
   * @type {number}
   */
  _contrast = 0;

  constructor(canvas, imageSrc, options = {}) {
    this.initialize(canvas, imageSrc, options).catch(console.error);
  }

  async initialize(canvas, imageSrc, options = {}) {
    this._canvas = canvas;

    const img = await loadImageAsync(imageSrc);
    const pngSrc = convertToPngSrc(img);

    this._originalImage = await loadImageAsync(pngSrc);

    this._imageObject = new FabricImage(this._originalImage, {
      name: NukkiImage.name,
      selectable: false,
      hasControls: false,
      hasBorders: false,
    });

    const padding = options.padding ?? 0;

    this._imageObject.scaleToHeight(this._canvas.height - padding * 2);
    this.filterRed();

    this._canvas.add(this._imageObject);
    this._canvas.centerObject(this._imageObject);

    this._canvas.renderAll();
  }

  destroy() {
    this._imageObject.dispose();
    this._originalImage = null;
    this._imageObject = null;
    this._canvas = null;
  }

  get object() {
    return this._imageObject;
  }

  rotate(angle = 90) {
    this._imageObject.rotate(angle % 360);
    this._canvas.renderAll();
  }

  /**
   * contrast value, range from -1 to 1.
   * @param {Number} contrast
   * @default 0
   */
  contrast(contrast = 0) {
    this._contrast = contrast;
    this._imageObject.applyFilters([new filters.Contrast({ contrast: normalize(contrast, -1, 1) }), new RedFilter()]);
    this._canvas.renderAll();
  }

  filterRed() {
    this._imageObject.applyFilters([new RedFilter()]);
    this._canvas.renderAll();
  }

  async toDataURL() {
    const clonedImage = await this._imageObject.clone();

    // clone 시 filter가 제외되서 다시 적용
    clonedImage.applyFilters([new filters.Contrast({ contrast: normalize(this._contrast, -1, 1) }), new RedFilter()]);

    // 불필요한 transformation 초기화
    resetObjectTransform(clonedImage);

    const dataUrl = clonedImage.toDataURL({
      format: 'png',
    });

    return dataUrl;
  }
}

export default NukkiImage;
