import qst from '@pages/Corp/Agenda/Step/AgendaQuestion/commonQuestion.module.scss';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import Tooltip소집통지생략 from './components/Tooltip소집통지생략';
import st from './NotiOfDraft.module.scss';
import Vm from './NotiOfDraftVm';

interface NotiOFDraftProps {
  index: number;
}

const NotiOfDraft = observer((props: NotiOFDraftProps) => {
  return (
    <div className={qst.questionForm} style={{ marginBottom: '36px' }}>
      <div className={qst.qusetion}>
        <div className={qst.questionTitle}>
          <div style={{ marginBottom: '10px' }}>주주총회 절차를 선택하세요.</div>
          {!Vm.hasCapitalOverBillionYn && <Tooltip소집통지생략 />}
        </div>
        <div style={{ width: '460px' }}>
          {!Vm.hasCapitalOverBillionYn && (
            <RadioLabel
              className={clsx(qst.radio, st.radio)}
              name="musterNoticeType"
              value="생략"
              checked={Vm.musterNoticeType(props.index) === '생략'}
              onChange={(e) => Vm.setMusterNoticeType(e, props.index)}
              disabled={Vm.hasCapitalOverBillionYn}
            >
              <span className={st.bold}>주주의 동의</span>를 받아, <span className={st.bold}>바로</span> 주주총회를{' '}
              <span className={st.bold}>진행</span>해요.
              <div
                className={clsx(
                  qst.radioExplain,
                  st.radioExplain,
                  Vm.hasCapitalOverBillionYn ? st.disabled : null,
                )}
                style={{ fontSize: '12px', marginTop: '15px' }}
              >
                [온라인 동의받기] 기능 제공
              </div>
              <span className={st.tag}>추천</span>
            </RadioLabel>)}
          <RadioLabel
            className={clsx(qst.radio, st.radio)}
            name="musterNoticeType"
            value="온라인"
            checked={Vm.musterNoticeType(props.index) === '온라인'}
            onChange={(e) => Vm.setMusterNoticeType(e, props.index)}
          >
            <span className={st.bold}>온라인 소집통지</span>를 하고, <span className={st.bold}>10~14일 후</span>{' '}
            주주총회를 <span className={st.bold}>진행</span>해요.
            <div
              className={clsx(qst.radioExplain, st.radioExplain)}
              style={{ fontSize: '12px', marginTop: '15px' }}
            >
              [온라인 소집통지] 기능 제공
            </div>
          </RadioLabel>
          <RadioLabel
            className={clsx(qst.radio, st.radio)}
            name="musterNoticeType"
            value="우편"
            checked={Vm.musterNoticeType(props.index) === '우편'}
            onChange={(e) => Vm.setMusterNoticeType(e, props.index)}
          >
            <span className={st.bold}>우편 소집통지</span>를 하고, <span className={st.bold}>10~14일 후</span>{' '}
            주주총회를 <span className={st.bold}>진행</span>해요.
            <div
              className={clsx(qst.radioExplain, st.radioExplain)}
              style={{ fontSize: '12px', marginTop: '15px' }}
            >
              [소집통지문 자동작성] 기능 제공
            </div>
          </RadioLabel>
          <div>
            <Error
              name="musterNoticeType"
              value={Vm.musterNoticeType(props.index)}
              errorCase={{
                required: '주주총회 소집통지 진행을 선택해 주세요.',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
})


export default NotiOfDraft;
