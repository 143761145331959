import SealSelectModel from '@src/model/Corp/Document/SealSelectModel';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import SealSelect from '../../SealSelect';
import { queryKey, useSealExecutiveQuery } from '../queries';

interface Props {
  includeCorporateSeal: boolean;
  corpId: string;
  changeEventCallback?: () => void;
  isSignature?: boolean;
}

const ExecutiveSealSelect = observer((props: Props) => {
  const { data, isSuccess } = useSealExecutiveQuery(props.corpId, props.includeCorporateSeal);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      SealSelectModel.refresh.refreshCompanyId &&
      String(SealSelectModel.refresh.refreshCompanyId) === String(props.corpId)
    ) {
      void queryClient.invalidateQueries({ queryKey: queryKey.sealExecutive(props.corpId).queryKey });
    }

    SealSelectModel.refresh.refreshCompanyId = undefined;
  }, [SealSelectModel.refresh.refreshCompanyId]);

  useEffect(() => {
    return () => {
      SealSelectModel.refresh.refreshCompanyId = undefined;
    };
  }, []);

  return isSuccess ? (
    <SealSelect
      data={data}
      type="Executive"
      changeEventCallback={props.changeEventCallback}
      includeCorporateSeal={props.includeCorporateSeal}
      isSignature={props.isSignature}
    />
  ) : (
    <></>
  );
});

export default ExecutiveSealSelect;
