import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';
import vm from '../../UserDashboardVm';

const Card필수 = observer((props: { company: CompanyInfo }) => {
  return (
    <>
      <div className={st.tagContent}>
        <div className={st.tagWrap}>
          <Typography fontType="Detail-12M" className={st.tag} data-type="필수">
            필수
          </Typography>
        </div>
        <div className={st.text}>
          <Typography fontType="Body-14R" className={st.mainText}>
            임원{' '}
            <span className={st.importantText} data-type="필수">
              임기연장
            </span>{' '}
            필수!
          </Typography>
        </div>
      </div>
      <Typography fontType="Detail-12M" className={st.subText}>
        {props.company.executiveTags.필수 === 1 && (
          <>{vm.expiration(props.company.executiveMembers).name}님의 임기가 만료되었어요.</>
        )}
        {props.company.executiveTags.필수 > 1 && (
          <>
            {vm.expiration(props.company.executiveMembers).name}님 외 {props.company.executiveTags.필수 - 1}
            명의 임기가 만료되었어요.
          </>
        )}
      </Typography>
    </>
  );
});

export default Card필수;
