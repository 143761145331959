import { Lock, Typography } from '@standby/dike_ui';
import st from './HeaderSideLink.module.scss';
import clsx from 'clsx';
import Color from '@standby/dike_ui/design-token/color.module.scss';

interface HeaderSideLinkProps {
  menuName: string;
  publicLink?: string;
  isActiveLabel?: boolean;
  isLock?: boolean;
  onClick?: () => void;
}

const HeaderSideLink = ({ menuName, publicLink, isActiveLabel, isLock = true, onClick }: HeaderSideLinkProps) => {
  return publicLink ? (
    <a
      href={publicLink}
      target="_blank"
      className={clsx(st.sideButton, st.sideButtonGap, st.publicLink, isActiveLabel && st.active)}
      rel="noreferrer"
    >
      <Typography fontType="Body-14M">{menuName}</Typography>
    </a>
  ) : (
    <button
      type="button"
      className={clsx(st.sideButton, !isActiveLabel && isLock && st.buttonHover, isActiveLabel && st.active)}
      onClick={onClick}
    >
      {!isActiveLabel && isLock && <Lock width={16} height={16} fill={Color.siYellow500} />}
      <Typography fontType="Body-14M">{menuName}</Typography>
    </button>
  );
};

export default HeaderSideLink;
