import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import ProgressVm from './ProgressVm';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import st from './Progress.module.scss';
import Guide from '@commonComponents/Guide/Guide';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import clsx from 'clsx';

function Progress() {
  useEffect(() => {
    ProgressVm.load();
  }, []);
  return useObserver(() =>
    ProgressVm.guides.length !== 0 ? (
      <>
        <PageTitle>
          <span className={st.pageTitle}>주주총회, 이사회, 등기</span>
        </PageTitle>
        <Guide
          title="주주총회, 이사회, 등기 가이드"
          localStorageKey="agendaGuideHide"
          corpId={String(ProgressVm.corpId)}
          items={ProgressVm.guides}
          fullLink="https://standby.kr/blog/"
        />
        <div className={st.agendaStart}>
          <img src="/images/안건광고.gif?1" className={st.advertisement} />
          <div className={st.start}>
            <div className={st.startTitle}>
              <span className={st.important}>이메일, 카톡</span>으로 처리하는{' '}
              <span className={st.important}>주주총회</span>
            </div>
            <button type="button" className={st.startContent} onClick={() => ProgressVm.hrefBtn()}>
              <div className={st.explanation}>번거로운 도장날인, 우편발송 최대한 줄이세요.</div>
              <div className={st.explanationSub}>주주 동의서도 온라인으로 바로 받으세요.</div>
              <span className={st.likeBtn}>주주총회/이사회/등기 시작하기</span>
            </button>
          </div>
        </div>
        <ContentBox
          title="진행 중 안건"
          titleStyle={{ top: '25px', paddingLeft: '20px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.listTitle}>
            <div>순번</div>
            <div>일자</div>
            <div>안건</div>
            <div>등기</div>
            <div>결정기관</div>
            <div className={st.createdAt}>만든 날짜</div>
            <div className={st.cancelAgenda}>진행취소</div>
          </div>
          {ProgressVm.progressAgendas.length !== 0 ? (
            <ul className={st.agendaList}>
              {ProgressVm.progressAgendas.map((progressAgenda, index) => (
                <li key={index}>
                  <div>{ProgressVm.progressAgendas.length - index}</div>
                  <div>{progressAgenda.date}</div>
                  <div>
                    <a
                      href={`/corp/agenda/step/${progressAgenda.step}/?corp=${ProgressVm.corpId}&agendaProgressId=${progressAgenda.id}`}
                    >
                      {progressAgenda.title}
                    </a>
                  </div>
                  <div>{progressAgenda.registrationType}</div>
                  <div>
                    {progressAgenda.결정기관s.map((결정기관, index2) => (
                      <div key={index2}>{결정기관}</div>
                    ))}
                  </div>
                  <div className={st.createdAt}>{progressAgenda.createdAt}</div>
                  <div className={st.cancelAgenda}>
                    <button type="button" onClick={() => ProgressVm.cancelAgenda(progressAgenda.id)}>
                      진행취소
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={st.empty}>
              <span>새로운 안건을 진행해보세요!</span>
            </div>
          )}
        </ContentBox>
        <ContentBox
          title="진행 완료 안건"
          titleStyle={{ top: '25px', paddingLeft: '20px', fontSize: '18px' }}
          className={st.contentBox}
        >
          <div className={st.listTitle}>
            <div>순번</div>
            <div>일자</div>
            <div>안건</div>
            <div>등기</div>
            <div>결정기관</div>
            <div className={st.agreements}>통지, 동의</div>
          </div>
          {ProgressVm.completedAgendas.length !== 0 ? (
            <ul className={st.agendaList}>
              {ProgressVm.completedAgendas.map((completedAgenda, index) => (
                <li className={st.completed} key={index}>
                  <div>{ProgressVm.completedAgendas.length - index}</div>
                  <div>{completedAgenda.date}</div>
                  <div>
                    <a
                      href={`/corp/agenda/step/${completedAgenda.step}/?corp=${ProgressVm.corpId}&agendaProgressId=${completedAgenda.id}`}
                    >
                      {completedAgenda.title}
                    </a>
                  </div>
                  <div>{completedAgenda.registrationType}</div>
                  <div>
                    {completedAgenda.결정기관s.map((결정기관, index2) => (
                      <div key={index2}>{결정기관}</div>
                    ))}
                  </div>
                  <div className={st.agreements}>
                    {completedAgenda.agreements.map((agreement, index2) => (
                      <div key={index2}>{agreement.replace('(', '\n(')}</div>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className={clsx(st.empty, st.completed)}>
              <span>진행 완료된 안건이 없습니다.</span>
            </div>
          )}
        </ContentBox>
      </>
    ) : (
      <></>
    ),
  );
}
export default Progress;
