import Content from '@commonComponents/Content/Content';
import Header from '@commonComponents/Header';
import PageMainTitle from '@commonComponents/PageMainTitle';
import { RoundBoxButton, Typography } from '@standby/dike_ui';
import { Menu } from '@standby/dike_ui/assets/icon/fill';
import { Plus } from '@standby/dike_ui/assets/icon/line';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import BlogContent from './BlogContent';
import ComponyCard from './ComponyCard';
import st from './UserDashboard.module.scss';
import vm from './UserDashboardVm';

const UserDashboard = observer(() => {
  useEffect(() => {
    void vm.load();
  }, []);

  return (
    <>
      <Header />
      <Content>
        <div className={st.corpList}>
          {vm.companiesInfo.length > 3 && (
            <RoundBoxButton
              size="small"
              className={st.moreView}
              iconDirection={vm.state.moreViewYn ? 'up' : 'down'}
              iconPosition="right"
              onClick={() => vm.moreView()}
            >
              {vm.state.moreViewYn ? '접기' : '펼치기'}
            </RoundBoxButton>
          )}
          <div className={st.content}>
            <PageMainTitle icon={<Menu width="24" height="24" fill={color.primary800} />} title="등록 법인 목록" />
            <ul className={st.cardList}>
              <li>
                <Link to={`/companyRegistration`} className={clsx(st.companyRegistrationLink, st.corpCard)}>
                  <img src="/images/Img_Corp.svg" />
                  <Typography fontType="Title-18M" className={st.companyRegistrationText}>
                    <Plus width="20" height="20" fill={color.primary400} />
                    법인 신규등록
                  </Typography>
                </Link>
              </li>
              {vm.companiesInfo.length < 3 || vm.state.moreViewYn === true
                ? vm.companiesInfo.map((company, index) => <ComponyCard key={index} company={company} />)
                : vm.companiesInfo.slice(0, 3).map((company, index) => <ComponyCard key={index} company={company} />)}
            </ul>
          </div>
        </div>
        <BlogContent companiesInfo={vm.companiesInfo} />
      </Content>
    </>
  );
});

export default UserDashboard;
