import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import OnlyFileVm from './OnlyFileVm';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import st from './OnlyFile.module.scss';
import Guide from '@commonComponents/Guide/Guide';
import ContentBox from '@commonComponents/ContentBox/ContentBox';

function OnlyFile() {
  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>사업자등록</span>
      </PageTitle>
      <Guide
        title="사업자등록 가이드"
        localStorageKey="businessRegistration"
        corpId={String(OnlyFileVm.corpId)}
        items={OnlyFileVm.guides}
      />
      <ContentBox
        title="사업자등록증"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
      >
        <div className={st.content}>
          <div className={st.businessBox}>
            <img className={st.businessImg} src={OnlyFileVm.businessRegistrationFile.thumbnailFileUrl} />
            <button
              type="button"
              onClick={() => OnlyFileVm.downLoadUrl(OnlyFileVm.businessRegistrationFile.fileUrl)}
              className={st.businessDownloadBtn}
            >
              <span>{OnlyFileVm.businessRegistrationFile.createdAt} 자 사업자등록증 다운로드</span>
            </button>
          </div>
          <div className={st.companyCopyDate}>{OnlyFileVm.businessRegistrationFile.createdAt} 발급</div>
        </div>
      </ContentBox>
    </>
  ));
}
export default OnlyFile;
