import { Alert, Confirm } from '@model/newDialogModel';
import NewModalModel from '@model/newModalModel';
import {
  queryKey,
  useChangeSealMutation,
  useRepresentativeListQuery,
  useSealChangeValidateMutation,
} from '@pages/Corp/Seal/queries';
import { ChangeSealRq, Seal } from '@service/Corp/Seal/SealService.interface';
import formDataToJson from '@src/common/module/submit';
import { UnorderedList } from '@standby/dike_ui/components/atoms';
import { useQueryClient } from '@tanstack/react-query';
import { useLocalObservable } from 'mobx-react';
import React from 'react';

import SealEditModalModel from './SealEditModalModel';

const useSealEdit = (sealData: Seal, corpId: string) => {
  const queryClient = useQueryClient();
  const { data: representativeList } = useRepresentativeListQuery(corpId);

  const sealChangeValidateMutation = useSealChangeValidateMutation(corpId);
  const changeSealMutation = useChangeSealMutation(corpId);

  const sealMob = useLocalObservable(() => ({
    ...sealData,
    onChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: 'stampType' | 'alias' | 'executiveId') {
      if (key === 'stampType') {
        this[key] = e.target.value as 'AUTHORIZED' | 'CORPORATE';
      } else {
        this[key] = e.target.value;
      }
    },
  }));

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>, sealId: string) => {
    e.preventDefault();

    const formData = formDataToJson(e.target);

    if (formData.error) {
      return;
    }

    const param = { ...(formData.data as ChangeSealRq), sealId };

    sealChangeValidateMutation.mutate(
      { sealId: sealData.sealId, executiveId: param.executiveId },
      {
        async onSuccess(data) {
          const selectedRepresentative = representativeList?.representatives?.find(
            (representative) => String(representative.id) === param.executiveId,
          );

          if (data.isDeletable === false) {
            return Alert(
              `사용인감을 법인인감으로 수정할 수 없어요.`,
              <UnorderedList>
                <li>
                  대표님이 {data.representativeCount}명 이어서, 최소 {data.representativeCount}개의 사용인감이 필요해요.
                  수정을 원한다면, 새로운 사용인감을 등록한 후 수정해주세요.
                </li>
              </UnorderedList>,
            );
          }

          if (data.isChangingOthersSeal === true) {
            if (
              await Confirm(
                `“${param.alias}"를 ${selectedRepresentative?.name} 대표님의 법인인감으로 변경하나요?`,
                <UnorderedList>
                  <li>
                    변경시 {selectedRepresentative?.name} 대표님의 기존 법인인감의 대표선택이 필요해요. (미사용시 삭제
                    필요)
                  </li>
                </UnorderedList>,
              )
            ) {
              changeSealMutation.mutate(param, {
                async onSuccess() {
                  await Alert('저장되었어요.');
                  await queryClient.invalidateQueries({ queryKey: queryKey.sealList(corpId).queryKey });
                  SealEditModalModel.nextOpenModalSealId = data.remainingSealId;
                },
              });
            }
          } else {
            changeSealMutation.mutate(param, {
              async onSuccess() {
                await Alert('저장되었어요.');
                await queryClient.invalidateQueries({ queryKey: queryKey.sealList(corpId).queryKey });
                NewModalModel.closeModal();
              },
            });
          }
        },
      },
    );
  };

  return {
    sealMob,
    representatives: representativeList?.representatives,
    onSubmitForm,
  };
};

export default useSealEdit;
