import type { Authority } from "@src/service/Corp/corpService.interface";

export const defaultAuthority: Authority = {
  authorityType: null,
  menus: {
    AC: {
      menuName: '',
      useYn: false,
    },
    AOA: {
      menuName: '',
      useYn: false,
    },
    BC: {
      menuName: '',
      useYn: false,
    },
    BR: {
      menuName: '',
      useYn: false,
    },
    CR: {
      menuName: '',
      useYn: false,
    },
    DB: {
      menuName: '',
      useYn: false,
    },
    SH: {
      menuName: '',
      useYn: false,
    },
    VAT: {
      menuName: '',
      useYn: false,
    },
    CT: {
      menuName: '',
      useYn: false,
    },
    AG: {
      menuName: '',
      useYn: false,
    },
    IT: {
      menuName: '',
      useYn: false,
    },
    SE: {
      menuName: '',
      useYn: false,
    },
  },
};
