import { ReactComponent as AlarmOffSvg } from '@src/images/Img_AlarmOff.svg';
import { ReactComponent as AlarmOnSvg } from '@src/images/Img_AlarmOn.svg';
import { ReactComponent as UserProfileSvg } from '@src/images/Img_Profile.svg';
import { Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Notification from './components/Notification';
import st from './style.module.scss';
import useHeader from './useHeader';

const Header = observer(() => {
  const { openNotify, openUserSetting, toggleNotification, newNotifyYn, userName, notifications } = useHeader();

  return (
    <>
      <header className={st.header}>
        <Link to="/">
          <img src="/images/logo.svg?1" alt="스탠바이 서비스 로고" title="스탠바이 서비스 로고" />
        </Link>
        <div className={st.buttonWrapper}>
          <button className={st.buttonIcon} onClick={() => toggleNotification()}>
            {newNotifyYn ? <AlarmOnSvg /> : <AlarmOffSvg />}
            <Typography fontType="Body-14M">알림</Typography>
          </button>
          <button className={st.buttonIcon} onClick={() => openUserSetting()}>
            <UserProfileSvg />
            <Typography fontType="Body-14M" style={{ minWidth: '49px' }}>
              {userName}님
            </Typography>
          </button>
        </div>
      </header>
      <Notification open={openNotify} notifications={notifications} onClickBackGround={toggleNotification} />
    </>
  );
});

export default Header;
