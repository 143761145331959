import regExp from '@common/module/regExp';
import ScrollArea from '@src/pages/common/ScrollArea';
import { Button, Label, SelectField, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import { useObserver } from 'mobx-react';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import st from './AdditionalInformation.module.scss';
import AdditionalInformationVm from './AdditionalInformationVm';

function AdditionalInformation() {
  const location = useLocation();
  const token = queryString.parse(location.search).token;

  useEffect(() => {
    AdditionalInformationVm.loadEmailData(token);
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => AdditionalInformationVm.submit(e, token)} className={st.additionalInformationContent}>
      <ScrollArea className={st.scrollArea} thumbWidth={4} thumbColor={'#D6D8DA'}>
        <div className={st.contentView}>
          <div className={st.logoWrap}>
            <a href="https://standby.kr/" className={st.logo}>
              <img src="/images/logoStadnby-Login.svg" />
            </a>
          </div>
          <div className={st.mainTitle}>
            <Typography fontType="Title-18M">
              {AdditionalInformationVm.email}님<br />
              안녕하세요 :) 스탠바이 헬프미에 오신 것을 환영합니다.
            </Typography>
          </div>
          <TextField
            label="새 비밀번호"
            labelAsteriskMark
            labelBottom={`* 8글자 이상 입력\n* 영문, 숫자, 특수문자 각 1개 이상 사용`}
            name="password"
            type="password"
            className={st.inputText}
            value={AdditionalInformationVm.password}
            onChange={AdditionalInformationVm.setPassword}
            error={
              <ErrorValidation
                name="password"
                value={AdditionalInformationVm.password}
                errorCase={{
                  required: '비밀번호를 입력해 주세요.',
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                        AdditionalInformationVm.password,
                      ),
                    message: '올바른 비밀번호 형식으로 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <TextField
            label="새 비밀번호 확인"
            labelAsteriskMark
            name="passwordCheck"
            type="password"
            className={st.inputText}
            value={AdditionalInformationVm.passwordCheck}
            onChange={AdditionalInformationVm.setPasswordCheck}
            error={
              <ErrorValidation
                name="passwordCheck"
                value={AdditionalInformationVm.passwordCheck}
                errorCase={{
                  required: '비밀번호 확인을 입력해 주세요.',
                  validate: {
                    func: AdditionalInformationVm.isSame(),
                    message: '동일한 비밀번호를 입력해주세요.',
                  },
                }}
              />
            }
          />
          <TextField
            label="이름"
            labelAsteriskMark
            labelBottom="* 실명 사용을 추천드려요!"
            name="name"
            className={st.inputText}
            value={AdditionalInformationVm.name}
            onChange={AdditionalInformationVm.setName}
            error={
              <ErrorValidation
                name="name"
                value={AdditionalInformationVm.name}
                errorCase={{
                  required: '이름을 입력해 주세요.',
                  pattern: {
                    value: regExp.onlyKorEng().test(AdditionalInformationVm.name),
                    message: '한글 또는 영문으로 입력해 주세요.',
                  },
                }}
              />
            }
          />
          <div className={st.phoneNumber}>
            <Label asteriskMark>전화번호</Label>
            <div className={st.phoneNumberBox}>
              <SelectField
                name="countryCode"
                value={AdditionalInformationVm.countryCode}
                disabled
                style={{ width: '64px' }}
              >
                <option value="">-</option>
                <option value="82">+82</option>
              </SelectField>
              <TextField
                name="phoneNumber"
                value={AdditionalInformationVm.phoneNumber}
                onChange={AdditionalInformationVm.setPhoneNumber}
                style={{ width: '356px' }}
                error={
                  <ErrorValidation
                    name="phoneNumber"
                    value={AdditionalInformationVm.phoneNumber}
                    errorCase={{
                      required: '전화번호를 입력해 주세요.',
                      pattern: {
                        value: regExp.phoneNumber().test(AdditionalInformationVm.phoneNumber),
                        message: '올바른 전화번호를 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
            </div>
          </div>
          <Button type="submit" className={st.mainBtn} color="primary400" size="large">
            스탠바이 바로 시작하기
          </Button>
        </div>
      </ScrollArea>
    </form>
  ));
}

export default AdditionalInformation;
