import { observable } from 'mobx';

interface UserSetting {
  countryCode: string;
  email: string;
  marketingAcceptEmailYn: boolean;
  marketingAcceptSmsYn: boolean;
  marketingAcceptYn: boolean;
  marketingTermContent: string;
  marketingTermId: number | null;
  marketingYnChangedDate: {
    year: string;
    month: string;
    day: string;
  } | null;
  name: string;
  phoneNumber: string;
}

interface UserSettingModel {
  userSetData: UserSetting;
  setUserSetData(data: UserSetting): void;
}

const userSettingModel = observable<UserSettingModel>({
  userSetData: {
    countryCode: '',
    email: '',
    marketingAcceptEmailYn: false,
    marketingAcceptSmsYn: false,
    marketingAcceptYn: false,
    marketingTermContent: '',
    marketingTermId: null,
    marketingYnChangedDate: null,
    name: '',
    phoneNumber: '',
  },
  setUserSetData(data: UserSetting) {
    this.userSetData = {
      countryCode: data?.countryCode || '',
      email: data?.email || '',
      marketingAcceptEmailYn: data?.marketingAcceptEmailYn || false,
      marketingAcceptSmsYn: data?.marketingAcceptSmsYn || false,
      marketingAcceptYn: data?.marketingAcceptYn || false,
      marketingTermContent: data?.marketingTermContent || '',
      marketingTermId: data?.marketingTermId ?? null,
      marketingYnChangedDate: data.marketingYnChangedDate || null,
      name: data?.name || '',
      phoneNumber: data?.phoneNumber || '',
    };
  },
});

export default userSettingModel;
