import { observer } from 'mobx-react';
import { useQueryClient } from '@tanstack/react-query';
import SealSelect from '../../SealSelect';
import { queryKey, useSealAgendaQuery } from '../queries';
import { useEffect } from 'react';
import SealSelectModel from '@src/model/Corp/Document/SealSelectModel';

interface Props {
  agendaProgressId: string;
  corpId: string;
  changeEventCallback?: () => void;
}

const AgendaSealSelect = observer((props: Props) => {
  const { data, isSuccess } = useSealAgendaQuery(props.corpId, props.agendaProgressId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      SealSelectModel.refresh.refreshCompanyId &&
      String(SealSelectModel.refresh.refreshCompanyId) === String(props.corpId)
    ) {
      void queryClient.invalidateQueries({
        queryKey: queryKey.sealAgenda(props.corpId, props.agendaProgressId).queryKey,
      });
    }

    SealSelectModel.refresh.refreshCompanyId = undefined;
  }, [SealSelectModel.refresh.refreshCompanyId]);

  useEffect(() => {
    return () => {
      SealSelectModel.refresh.refreshCompanyId = undefined;
    };
  }, []);

  return isSuccess ? <SealSelect data={data} type="Agenda" changeEventCallback={props.changeEventCallback} /> : <></>;
});

export default AgendaSealSelect;
