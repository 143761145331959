import adminModel from '@src/model/Corp/AdminSetting/adminModel';
import { Alert } from '@src/model/newDialogModel';
import type { MenuType } from '@src/service/Corp/corpService.interface';
import { UnorderedList } from '@standby/dike_ui';

import st from './SideLink.module.scss';
import corpModel from '@src/model/Corp/corpModel';

export const getStyle = (text: string) => {
  switch (text) {
    case 'NEW':
      return st.new;
    case '등록중':
    case '진행중':
    case '신고 진행중':
    case '결산 진행중':
    case '개통 진행중':
      return st.ongoing;
    case '등록완료':
    case '신고완료':
    case '신고완료(국세)':
    case '신고완료(지방세)':
    case '계약완료':
    case '반영완료':
    case '개통완료':
      return st.complete;
    case '자료제출 대기중':
    case '계좌 입력중':
      return st.waiting;
    case '고지세액 안내':
    case '안내완료':
    case '업로드필요':
      return st.guide;
    case '등기완료':
      return st.registerComplete;
    default:
      return st.waiting;
  }
};

export const checkAuthority = (type?: MenuType) => {
  if (!type) return false;

  return adminModel?.authority?.menus?.[type]?.useYn;
};

export const showAuthorityAlert = (menuName: string) => {
  void Alert(
    `[${menuName}]페이지는 접근 권한이 없어요.`,
    <UnorderedList className={st.alertContent}>
      <li>권한이 필요하다면, {corpModel.company.companyName}의 최고관리자에게 권한을 요청하세요.</li>
    </UnorderedList>,
    '확인',
  );
};
