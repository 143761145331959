import { useObserver } from 'mobx-react';

import DateList from './TabComponent/DateList/DateList';
import NewShareholders from './TabComponent/NewShareholders/NewShareholders';
import NotiOfDraft from './TabComponent/NotiOfDraft/NotiOfDraft';
import RegistrationAgency from './TabComponent/RegistrationAgency/RegistrationAgency';
import ShareholderList from './TabComponent/ShareholderList/ShareholderList';
import WrittenResolution from './TabComponent/WrittenResolution/WrittenResolution';
import TabVm from './TabVm';

interface TabProps {
  index: number;
}

function Tab(props: TabProps) {
  return useObserver(() => (
    <>
      {/* 신주발행 */}
      {TabVm.displayNewShareholders(props.index) && <NewShareholders index={props.index} />}
      {/* 서면결의방식 */}
      {TabVm.displayWrittenResolution(props.index) && <WrittenResolution index={props.index} />}
      {/* 소집통지 */}
      {TabVm.displayNotiOfDraft(props.index) && <NotiOfDraft index={props.index} />}
      {/* 일자입력 */}
      {TabVm.displayDateList(props.index) && <DateList name="meetingDetailList" />}
      {/* 등기 방법 선택 */}
      {TabVm.displayRegistrationAgency(props.index) && <RegistrationAgency index={props.index} />}
      {/* 주주명부 확인 */}
      {TabVm.displayShareholderList(props.index) && <ShareholderList index={props.index} />}
    </>
  ));
}

export default Tab;
