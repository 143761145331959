import { makeObservable, observable } from 'mobx';

import type { Refresh, SelectedSeal } from './SealSelectModel.interface';

class SealSelectModel {
  selectedSeals: SelectedSeal[] = [];
  refresh: Refresh = {
    refreshCompanyId: undefined,
  };
  selectedAuthorizedSeal?: number = undefined;
  constructor() {
    makeObservable(this, {
      selectedSeals: observable,
      refresh: observable,
      selectedAuthorizedSeal: observable,
    });
  }
}

export default new SealSelectModel();
