import React from 'react';
import { Scrollbar, ScrollbarProps } from 'react-scrollbars-custom';

import st from './style.module.scss';

interface ScrollAreaProps extends Omit<ScrollbarProps, 'elementRef' | 'ref'> {
  children?: React.ReactNode;
  /**
   * 값이 주어지지 않으면 기본 브라우저 스크롤바 너비를 사용합니다.
   */
  thumbWidth?: number;
  /**
   * 스크롤바 색상
   * @default black
   */
  thumbColor?: string;
}

/**
 * @note style 혹은 class로 width를 반드시 설정해야 정상 작동합니다.
 * @link https://www.npmjs.com/package/react-scrollbars-custom
 */
const ScrollArea = ({ children, thumbWidth, thumbColor, ...props }: ScrollAreaProps) => {
  return (
    <Scrollbar
      {...props}
      wrapperProps={{
        renderer: (props) => {
          const { key, elementRef, ...restProps } = props;

          return <span key={key} {...restProps} ref={elementRef} className={st.wrapper} />;
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { key, elementRef, ...restProps } = props;

          return <span key={key} {...restProps} ref={elementRef} className={st.trackX} />;
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { key, elementRef, style, ...restProps } = props;

          return (
            <div
              {...restProps}
              key={key}
              ref={elementRef}
              style={{ ...style, backgroundColor: thumbColor || 'black', width: thumbWidth }}
            />
          );
        },
      }}
    >
      {children}
    </Scrollbar>
  );
};

export default ScrollArea;
