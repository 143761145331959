import type { Manager } from '@src/service/Corp/AdminSetting/adminSettingService.interface';
import type { Authority } from '@src/service/Corp/corpService.interface';
import { observable } from 'mobx';

import { defaultAuthority } from './constant';

interface AdminModel {
  managers: Manager[];
  authority: Authority;
  setAuthority(authority?: Authority): void;
  setManagers(managers?: Manager[]): void;
}

const adminModel = observable<AdminModel>({
  managers: [],
  authority: defaultAuthority,
  setAuthority(authority) {
    this.authority = {
      authorityType: authority?.authorityType || null,
      menus: {
        AC: {
          menuName: authority?.menus?.AC?.menuName || '',
          useYn: authority?.menus?.AC?.useYn || false,
        },
        AOA: {
          menuName: authority?.menus?.AOA?.menuName || '',
          useYn: authority?.menus?.AOA?.useYn || false,
        },
        BC: {
          menuName: authority?.menus?.BC?.menuName || '',
          useYn: authority?.menus?.BC?.useYn || false,
        },
        BR: {
          menuName: authority?.menus?.BR?.menuName || '',
          useYn: authority?.menus?.BR?.useYn || false,
        },
        CR: {
          menuName: authority?.menus?.CR?.menuName || '',
          useYn: authority?.menus?.CR?.useYn || false,
        },
        DB: {
          menuName: authority?.menus?.DB?.menuName || '',
          useYn: authority?.menus?.DB?.useYn || false,
        },
        SH: {
          menuName: authority?.menus?.SH?.menuName || '',
          useYn: authority?.menus?.SH?.useYn || false,
        },
        VAT: {
          menuName: authority?.menus?.VAT?.menuName || '',
          useYn: authority?.menus?.VAT?.useYn || false,
        },
        CT: {
          menuName: authority?.menus?.CT?.menuName || '',
          useYn: authority?.menus?.CT?.useYn || false,
        },
        AG: {
          menuName: authority?.menus?.AG?.menuName || '',
          useYn: authority?.menus?.AG?.useYn || false,
        },
        IT: {
          menuName: authority?.menus?.IT?.menuName || '',
          useYn: authority?.menus?.IT?.useYn || false,
        },
        SE: {
          menuName: authority?.menus?.SE?.menuName || '',
          useYn: authority?.menus?.SE?.useYn || false,
        },
      },
    };
  },
  setManagers(managers = []) {
    this.managers = managers.map((manager) => {
      return {
        userId: manager.userId || null,
        name: manager.name || '',
        email: manager.email || '',
        authorityType: manager.authorityType || null,
        role: manager.role || '',
        authorities: {
          acct: manager.authorities.acct.map((acct) => {
            return {
              menuCode: acct.menuCode || '',
              menuName: acct.menuName || '',
              useYn: acct.useYn || false,
            };
          }),
          law: manager.authorities.law.map((law) => {
            return {
              menuCode: law.menuCode || '',
              menuName: law.menuName || '',
              useYn: law.useYn || false,
            };
          }),
          partner: manager.authorities.partner.map((partner) => {
            return {
              menuCode: partner.menuCode || '',
              menuName: partner.menuName || '',
              useYn: partner.useYn || false,
            };
          }),
        },
        inviting: manager.inviting || false,
      };
    });
  },
});

export default adminModel;
