import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import bookKeepingContractModel from '@model/Corp/BookKeepingContract/bookKeepingContractModel';
import bookKeepingContractService from '@service/Corp/BookKeepingContract/bookKeepingContractService';
import { observable, runInAction, toJS } from 'mobx';
import portalModel from '@model/portalModel';
import CMSAccountPopup from '../CMSAccountPopup/CMSAccountPopup';
import { _alert } from '@model/dialogModel';
import AddAccount from '@pages/Corp/BusinessRegistration/BusinessRegistrationDocument/AddAccount/AddAccount';

class AccountRegistrationPopupVm {
  constructor() {
    this.state = observable({
      bankName: '',
      accountNumber: '',
      bankBoxOpenYn: false,
      bankbookId: '',
      accountUsage: '',
    });
  }
  async load() {
    await bookKeepingContractService.bankbooks();
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = new FormData(e.target);

      if (this.bankbooks && this.bankbooks.length) {
        param = this.state.bankbookId;
      } else {
        const regExp = /\[\d+\]/g;
        let _key = [];
        for (let [key, value] of param) {
          _key.push(key.replace(regExp, ''));
        }

        if (!_key.includes('file')) {
          await _alert('통장사본을 업로드하세요.');
          return;
        }
      }

      runInAction(() => {
        portalModel.title = 'CMS 자동이체 신청서';
        portalModel.content = <CMSAccountPopup param={param} />;
      });
    }
  }
  get cmsAccountInputDeadline() {
    return bookKeepingContractModel.cmsAccountInputDeadline;
  }
  get paymentDay() {
    return bookKeepingContractModel.paymentDay;
  }
  get bankList() {
    return bookKeepingContractModel.bankList;
  }
  get companyName() {
    const company = corpModel.company;
    let companyName = '';

    if (company.prefixedYn) {
      if (company.corpType === '주식회사') {
        companyName = `(주)${company.companyName}`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `(유)${company.companyName}`;
      }
    } else {
      if (company.corpType === '주식회사') {
        companyName = `${company.companyName}(주)`;
      } else if (company.corpType === '유한회사' || company.corpType === '유한책임회사') {
        companyName = `${company.companyName}(유)`;
      }
    }

    return companyName;
  }
  chooseBtn() {
    this.state.bankBoxOpenYn = !this.state.bankBoxOpenYn;
  }
  setBankName(e) {
    this.state.bankName = e.target.value;
    this.state.bankBoxOpenYn = false;
  }
  setAccountNumber(e) {
    let accountNumber = e.target.value.replace(/[^0-9-]/g, '');
    this.state.accountNumber = accountNumber;
  }
  setAccountUsage(e) {
    this.state.accountUsage = e.target.value;
  }
  get bankbooks() {
    return bookKeepingContractModel.bankbooks;
  }
  setBankbookId(e) {
    this.state.bankbookId = e.target.value;
  }
  addBankbook() {
    runInAction(() => {
      portalModel.title = '새로운 계좌 추가하기';
      portalModel.content = <AddAccount />;
    });
  }
}

export default new AccountRegistrationPopupVm();
