import { useObserver } from 'mobx-react';
import RegistrationAgencyVm from './RegistrationAgencyVm';
import st from './RegistrationAgency.module.scss';
import clsx from 'clsx';
import Icon from '@standby/common-ui/components/atoms/Icon/Icon';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import TextArea from '@standby/common-ui/components/atoms/Input/TextArea/TextArea';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import RadioLabel from '@standby/common-ui/components/molecules/RadioLabel/RadioLabel';
import josa, { josaType } from '@common/module/josa';
import regExp from '@common/module/regExp';
import { useEffect } from 'react';

interface RegistrationAgencyProps {
  index: number;
}

function RegistrationAgency(props: RegistrationAgencyProps) {
  useEffect(() => {
    RegistrationAgencyVm.load(props.index);
  }, []);

  return useObserver(() => (
    <div className={st.questionForm}>
      <div className={st.questionBox}>
        <div className={st.questionBoxTitle}>등기 방법</div>
        <div className={st.qusetion}>
          <div className={st.questionTitle}>등기 방법을 선택하세요.</div>
          <div className={st.progressType}>
            <label
              className={clsx(
                st.checkboxLabel,
                st.registrationAgency,
                RegistrationAgencyVm.corpRegProgressType(props.index) === 'REGISTRATION_AGENCY' ? st.checked : null,
              )}
            >
              <input
                type="checkbox"
                name="corpRegProgressType"
                value="REGISTRATION_AGENCY"
                checked={RegistrationAgencyVm.corpRegProgressType(props.index) === 'REGISTRATION_AGENCY'}
                onChange={(e) => RegistrationAgencyVm.setCorpRegProgressType(e, props.index)}
              />
              <div className={st.title}>등기 대행</div>
              <div className={st.explain}>대표님! 법인등기에 시간 빼앗기지 마세요!</div>
              <img
                className={st.img}
                src={
                  RegistrationAgencyVm.corpRegProgressType(props.index) === 'REGISTRATION_AGENCY'
                    ? '/images/registration_agency_positive.svg'
                    : '/images/registration_agency_negative.svg'
                }
                alt="등기대행"
              />
              <div className={st.subExplain}>제휴 법률사무소에 맡기면 간단하게 끝!</div>
            </label>
            <label
              className={clsx(
                st.checkboxLabel,
                st.small,
                RegistrationAgencyVm.corpRegProgressType(props.index) === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY'
                  ? st.checked
                  : null,
              )}
            >
              <input
                type="checkbox"
                name="corpRegProgressType"
                value="ALREADY_PROGRESSED_REGISTRATION_AGENCY"
                checked={
                  RegistrationAgencyVm.corpRegProgressType(props.index) === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY'
                }
                onChange={(e) => RegistrationAgencyVm.setCorpRegProgressType(e, props.index)}
              />
              <div className={st.title}>현재 등기진행 중</div>
              <div className={st.explain}>
                법률사무소에 등기를
                <br />
                맡겨 이미 진행 중이라면
                <br />
                선택하세요!
              </div>
              <img
                className={st.img}
                src={
                  RegistrationAgencyVm.corpRegProgressType(props.index) === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY'
                    ? '/images/ALREADY_PROGRESSED_REGISTRATION_AGENCY_positive.svg'
                    : '/images/ALREADY_PROGRESSED_REGISTRATION_AGENCY_negative.svg'
                }
                alt="셀프등기"
              />
            </label>
            <label
              className={clsx(
                st.checkboxLabel,
                st.small,
                RegistrationAgencyVm.corpRegProgressType(props.index) === 'SELF' ? st.checked : null,
              )}
            >
              <input
                type="checkbox"
                name="corpRegProgressType"
                value="SELF"
                checked={RegistrationAgencyVm.corpRegProgressType(props.index) === 'SELF'}
                onChange={(e) => RegistrationAgencyVm.setCorpRegProgressType(e, props.index)}
              />
              <div className={st.title}>셀프 등기</div>
              <div className={st.explain}>
                언제든지 등기대행으로
                <br />
                변경 가능해요!
              </div>
              <img
                className={st.img}
                src={
                  RegistrationAgencyVm.corpRegProgressType(props.index) === 'SELF'
                    ? '/images/self_positive_new.svg'
                    : '/images/self_negative_new.svg'
                }
                alt="셀프등기"
              />
            </label>
          </div>
          <div>
            <Error
              name="corpRegProgressType"
              value={RegistrationAgencyVm.corpRegProgressType(props.index) || ''}
              errorCase={{
                required: '등기 방법을 선택하세요.',
              }}
            />
          </div>
          <div className={st.progressTypeExplain}>
            <div className={st.explain}>스탠바이는 소개 수수료를 일절 받지 않습니다.</div>
            <div className={st.agencyInfo}>
              <div className={st.info}>
                <a href="https://reg.help-me.kr/pricing/법인설립/주식회사-일반" target="_blank">
                  등기대행 비용확인
                  <Icon type="aLink" className={st.icon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {RegistrationAgencyVm.corpRegProgressType(props.index) === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY' && (
        <>
          <div className={st.questionBox}>
            <div className={st.questionBoxTitle}>등기절차</div>
            <div className={st.qusetion}>
              <img
                src="/images/progressed_registraion_agency_procedure.svg"
                className={st.procedureImg}
                alt="등기절차"
              />
              <div className={st.progressTypeExplain}>
                <div className={st.explain}>법률사무소가 실시간 상담제공</div>
              </div>
            </div>
          </div>
        </>
      )}

      {RegistrationAgencyVm.corpRegProgressType(props.index) === 'REGISTRATION_AGENCY' && (
        <>
          <div className={st.questionBox}>
            <div className={st.questionBoxTitle}>등기절차</div>
            <div className={st.qusetion}>
              <img src="/images/registraion_agency_procedure.svg" className={st.procedureImg} alt="등기절차" />
              <div className={st.progressTypeExplain}>
                <div className={st.explain}>법률사무소가 실시간 상담제공</div>
                <div className={st.explain}>상담 후 등기대행 여부를 결정할 수 있어요.</div>
              </div>
            </div>
          </div>
          {RegistrationAgencyVm.jointCertificate(props.index) && (
            <div className={st.questionBox}>
              <div className={st.questionBoxTitle}>공동인증서</div>
              <div className={st.qusetion}>
                <div className={st.questionTitle} style={{ marginBottom: '10px' }}>
                  아래 분들이 모두 공동인증서 사용이 가능한가요?
                </div>
                <QuestionTooltip title="공동인증서를 이용한 전자등기 안내">
                  <div className={st.tooltipContent}>
                    <div className={st.tooltip}>
                      <div className={st.title}>
                        1. 은행용 공동 인증서가 있으면, 인감증명서 준비 없이 전자등기가 가능해요.
                      </div>
                      <div className={st.content}>
                        범용 인증서 사용도 가능해요. 만약 서류등기로 진행한다면, 주주, 임원, 법인의 인감증명서 원본(발급
                        3개월내), 주민등록초본, 인감도장 등이 필요해요. 시간도 3일 정도가 더 걸려요.
                      </div>
                    </div>
                    <div className={st.tooltip}>
                      <div className={st.title}>2. 법인주주의 경우 공동인증서가 아닌 전자증명서로 서명해야 해요.</div>
                      <div className={st.content}>
                        대법원 규칙상 법인주주는 공동인증서가 아니라, 등기소에서 발급하는 온라인 등기 전용
                        '전자증명서'로 전자서명이 가능해요. 전자 증명서가 없다면, 등기대행업체에서 대리발급이 가능해요.
                        (법인인감도장을 찍은 서류 실물 필요)
                      </div>
                    </div>
                    <div className={st.tooltip}>
                      <div className={st.title}>3. 펀드/투자조합이나 외국인은 전자서명이 불가능해요.</div>
                      <div className={st.content}>
                        대법원에서는 펀드/투자조합, 외국인의 전자서명은 아직 허용하지 않고 있어요. 향후에는 허용할 수도
                        있다고 해요.
                      </div>
                    </div>
                    <div className={st.tooltip}>
                      <div className={st.title}>
                        4. 공동인증서와 금융인증서만 사용 가능하고, 간편인증서 사용은 불가능해요.
                      </div>
                      <div className={st.content}>
                        대법원에서 운영하는 인터넷등기소는 공동인증서와 금융인증서만 허용하고 있어요.
                      </div>
                    </div>
                    <div className={st.tooltip}>
                      <div className={st.title}>
                        5. 일부 사람은 공동인증서로, 일부 사람은 인감증명서 준비하는 방식은 불가능해요.
                      </div>
                      <div className={st.content}>
                        대법원에서는 등기를 할 때 방식을 통일하도록 정하고 있어요. 그래서 일부 사람은 공동인증서로
                        전자서명을 하고, 일부 사람은 인감증명서를 준비하는 방식은 불가능해요.
                      </div>
                    </div>
                    <div className={st.tooltip}>
                      <div className={st.title}>
                        6. 주주, 임원들이 각자 집에서 대법원 사이트에서 공동인증서 비밀번호를 넣어주시면 되어요.
                      </div>
                      <div className={st.content}>
                        각자 집에서 공동인증서 비밀번호만 넣으면 되고, 공동인증서를 등기대행 업체에 보내거나, 한 장소에
                        모일 필요가 없어요.
                      </div>
                    </div>
                  </div>
                </QuestionTooltip>
                <div className={st.radioBox}>
                  <RadioLabel
                    className={st.radioLabel}
                    name="jointCertificateUseYn"
                    value="true"
                    checked={String(RegistrationAgencyVm.jointCertificate(props.index)?.useYn) === 'true'}
                    onChange={(e) => RegistrationAgencyVm.setJointCertificateUseYn(e, props.index)}
                  >
                    사용 가능합니다. <span className={st.radioTag}>(전자등기로 진행)</span>
                    <ul className={st.shareholderList}>
                      {RegistrationAgencyVm.jointCertificate(props.index)?.shareholderNameList?.map(
                        (shareholder, idx2) => <li key={idx2}>{shareholder}</li>,
                      )}
                    </ul>
                  </RadioLabel>
                  <RadioLabel
                    className={st.radioLabel}
                    name="jointCertificateUseYn"
                    value="false"
                    checked={String(RegistrationAgencyVm.jointCertificate(props.index)?.useYn) === 'false'}
                    onChange={(e) => RegistrationAgencyVm.setJointCertificateUseYn(e, props.index)}
                  >
                    사용 불가능합니다. <span className={st.radioTag}>(서류등기로 진행)</span>
                  </RadioLabel>
                </div>
                <div>
                  <Error
                    name="jointCertificateUseYn"
                    value={String(RegistrationAgencyVm.jointCertificate(props.index)?.useYn)}
                    errorCase={{
                      required: '공동인증서 사용 가능여부를 선택하세요.',
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={st.questionBox}>
            <div className={st.questionBoxTitle}>전자증명서</div>
            <div className={st.qusetion}>
              <div className={st.certificateBox} style={{ marginBottom: '36px' }}>
                <div className={st.title}>전자증명서 이미지</div>
                <img src="/images/certificate.svg?1" alt="전자증명서" />
              </div>
              <QuestionTooltip title="전자증명서란?" style={{ width: '460px' }}>
                <div className={clsx(st.tooltipContent, st.certificate)}>
                  <div className={st.tooltip}>
                    <div className={st.title}>전자증명서는 전자등기 전용 인증서에요.</div>
                    <div className={st.content}>
                      등기소에서 접근번호를 발급해 온라인등기소에서 발급이 가능해요. 접근번호는 등기소 양식에
                      법인인감도장을 찍어서 발급 받을 수 있어요.
                    </div>
                  </div>
                  <div className={st.tooltip}>
                    <div className={st.title}>법인은 전자등기를 할 때 꼭 전자증명서가 있어야 해요.</div>
                    <div className={st.content}>
                      전자등기시, 개인의 경우 공동인증서로 전자서명을 하지만, 법인의 경우 전자증명서로만 전자서명을 할
                      수 있어요. (법인의 공동인증서로 전자서명 불가능)
                    </div>
                  </div>
                  <div className={st.tooltip}>
                    <div className={st.title}>전자증명서는 접근번호를 받아 발급이 가능해요.</div>
                    <div className={st.content}>
                      전자증명서는 등기소에 꼭 방문을 해 접근번호를 받아야해요. 등기대행을 맡기면, 법률사무소에서 대신
                      방문해서 발급받아 드려요.
                    </div>
                  </div>
                </div>
              </QuestionTooltip>
            </div>
          </div>
          {RegistrationAgencyVm.jointCertificate(props.index)?.useYn &&
            RegistrationAgencyVm.companyShareholderList(props.index) && (
              <div className={st.questionBox}>
                <div className={st.questionBoxTitle}>법인주주</div>
                <div className={st.qusetion}>
                  {RegistrationAgencyVm.companyShareholderList(props.index)?.map((companyShareholder, idx2) => {
                    return (
                      <div key={idx2} className={st.companyShareholder}>
                        <div className={st.questionTitle}>
                          법인주주인 <span className={st.bold}>{josa(companyShareholder.name, josaType.은는)}</span> 위
                          사진과 같은 <span className={st.bold}>전자증명서</span>를 가지고 있나요?
                        </div>
                        <input
                          type="hidden"
                          name={`digitalCertificate.companyShareholderList[${idx2}].id`}
                          value={companyShareholder.id}
                        />
                        <div className={st.radioBox}>
                          <RadioLabel
                            className={st.radioLabel}
                            name={`digitalCertificate.companyShareholderList[${idx2}].useType`}
                            value="네"
                            checked={companyShareholder.useType === '네'}
                            onChange={(e) => RegistrationAgencyVm.setCompanyUseType(e, props.index, idx2)}
                          >
                            네
                          </RadioLabel>
                          <RadioLabel
                            className={st.radioLabel}
                            name={`digitalCertificate.companyShareholderList[${idx2}].useType`}
                            value="아니오_신규발급_신청_가능해요"
                            checked={companyShareholder.useType === '아니오_신규발급_신청_가능해요'}
                            onChange={(e) => RegistrationAgencyVm.setCompanyUseType(e, props.index, idx2)}
                          >
                            아니오. 신규발급 신청 가능해요.
                            <div className={st.radioExplain}>
                              신청서에 {companyShareholder.name}의 법인인감도장을 날인하면, 법률사무소에서 발급을
                              <br />
                              대행해드려요.
                            </div>
                          </RadioLabel>
                          <RadioLabel
                            className={st.radioLabel}
                            name={`digitalCertificate.companyShareholderList[${idx2}].useType`}
                            value="아니오_신규발급_신청_불가능해요"
                            checked={companyShareholder.useType === '아니오_신규발급_신청_불가능해요'}
                            onChange={(e) => RegistrationAgencyVm.setCompanyUseType(e, props.index, idx2)}
                          >
                            아니오. 신규발급 신청 불가능해요.
                          </RadioLabel>
                          <RadioLabel
                            className={st.radioLabel}
                            name={`digitalCertificate.companyShareholderList[${idx2}].useType`}
                            value="잘_모르겠어요"
                            checked={companyShareholder.useType === '잘_모르겠어요'}
                            onChange={(e) => RegistrationAgencyVm.setCompanyUseType(e, props.index, idx2)}
                          >
                            잘 모르겠어요.
                          </RadioLabel>
                        </div>
                        <div>
                          <Error
                            name={`digitalCertificate.companyShareholderList[${idx2}].useType`}
                            value={companyShareholder.useType}
                            errorCase={{
                              required: '전자증명서 소지여부를 선택하세요.',
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          <div className={st.questionBox}>
            <div className={st.questionBoxTitle}>{RegistrationAgencyVm.companyName}</div>
            <div className={st.qusetion}>
              <div className={st.questionTitle}>
                <span className={st.bold}>{josa(RegistrationAgencyVm.companyName, josaType.은는)}</span> 위 사진과 같은{' '}
                <span className={st.bold}>전자증명서</span>를 가지고 있나요?
              </div>
              {/* @todo 전자증명서 발급일자 개발되면 이 부분 표시 */}
              {/* <div className={st.certificateBox}>
                            <div className={st.title}>{RegistrationAgencyVm.companyName} 전자증명서 발급이력</div>
                            <div className={st.finalIssue}>2023-04-05 최종발급</div>
                            <div className={st.finalIssueState}>사용가능 (분실시 3,000원 공과금 발생)</div>
                        </div> */}
              <div className={st.radioBox}>
                <RadioLabel
                  className={st.radioLabel}
                  name="digitalCertificate.company.useType"
                  value="네"
                  checked={RegistrationAgencyVm.digitalCertificate(props.index)?.company?.useType === '네'}
                  onChange={(e) => RegistrationAgencyVm.setUseType(e, props.index)}
                >
                  네
                </RadioLabel>
                <RadioLabel
                  className={st.radioLabel}
                  name="digitalCertificate.company.useType"
                  value="아니오"
                  checked={RegistrationAgencyVm.digitalCertificate(props.index)?.company?.useType === '아니오'}
                  onChange={(e) => RegistrationAgencyVm.setUseType(e, props.index)}
                >
                  아니오
                </RadioLabel>
                <RadioLabel
                  className={st.radioLabel}
                  name="digitalCertificate.company.useType"
                  value="잘_모르겠어요"
                  checked={RegistrationAgencyVm.digitalCertificate(props.index)?.company?.useType === '잘_모르겠어요'}
                  onChange={(e) => RegistrationAgencyVm.setUseType(e, props.index)}
                >
                  잘 모르겠어요
                </RadioLabel>
              </div>
              <div>
                <Error
                  name="digitalCertificate.company.useType"
                  value={RegistrationAgencyVm.digitalCertificate(props.index)?.company?.useType}
                  errorCase={{
                    required: '전자증명서 소지여부를 선택하세요.',
                  }}
                />
              </div>
            </div>
          </div>
          <div className={st.questionBox}>
            <div className={st.questionBoxTitle}>요청사항</div>
            <div className={st.qusetion}>
              <div className={st.questionTitle}>등기대행 요청사항</div>
              <button
                type="button"
                className={clsx(
                  st.requestRequirementBtn,
                  RegistrationAgencyVm.state.requestRequirementYn ? st.open : '',
                )}
                onClick={() => RegistrationAgencyVm.openRequirement()}
              >
                <span>입력하기</span>
              </button>
              {RegistrationAgencyVm.state.requestRequirementYn && (
                <TextArea
                  name="requestRequirement"
                  value={RegistrationAgencyVm.requestRequirement(props.index)}
                  onChange={(e) => RegistrationAgencyVm.setRequestRequirement(e, props.index)}
                  placeholder="요청사항 입력"
                  style={{ width: '460px', height: '178px', resize: 'none' }}
                />
              )}
            </div>
          </div>
        </>
      )}
      {RegistrationAgencyVm.corpRegProgressType(props.index) === 'REGISTRATION_AGENCY' && (
        <div className={st.questionBox}>
          <div className={st.questionBoxTitle}>담당자</div>
          <div className={st.qusetion}>
            <div className={st.questionTitle}>
              등기대행 담당자
              <div className={st.titleSubInfo}>* 등기대행 연락을 받을 담당자 정보를 입력하세요.</div>
            </div>
            <div className={st.personInChargeBox}>
              <div className={st.personInCharge}>
                <div className={st.title}>성함</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.name`}
                  placeholder="홍길동"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).name || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'name')}
                  errorText={
                    <Error
                      name={`personInCharge.name`}
                      value={RegistrationAgencyVm.personInCharge(props.index).name}
                      errorCase={{
                        required: '이름을 입력하세요.',
                      }}
                    />
                  }
                />
              </div>
              <div className={st.personInCharge}>
                <div className={st.title}>전화번호</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.phoneNum`}
                  placeholder="010-1234-1234"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).phoneNum || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'phoneNum')}
                  errorText={
                    <Error
                      name={`personInCharge.phoneNum`}
                      value={RegistrationAgencyVm.personInCharge(props.index).phoneNum}
                      errorCase={{
                        required: '전화번호를 입력하세요.',
                        pattern: {
                          value: regExp.phoneNumber().test(RegistrationAgencyVm.personInCharge(props.index).phoneNum),
                          message: '올바른 전화번호를 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
              <div className={st.personInCharge}>
                <div className={st.title}>이메일</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.email`}
                  placeholder="email@standby.kr"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).email || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'email')}
                  errorText={
                    <Error
                      name={`personInCharge.email`}
                      value={RegistrationAgencyVm.personInCharge(props.index).email}
                      errorCase={{
                        required: '이메일을 입력하세요.',
                        pattern: {
                          value: regExp.email().test(RegistrationAgencyVm.personInCharge(props.index).email),
                          message: '올바른 이메일 형식으로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.termsBox}>
              <Check
                name="informationProvideYn"
                value={String(RegistrationAgencyVm.informationProvideYn(props.index))}
                checked={RegistrationAgencyVm.informationProvideYn(props.index) === true}
                onChange={(e) => RegistrationAgencyVm.setInformationProvideYn(e, props.index)}
                className={st.checkbox}
              >
                등기대행 진행을 위한{' '}
                <button type="button" className={st.termsBtn} onClick={() => RegistrationAgencyVm.termsPopup()}>
                  제3자 정보제공에 동의
                </button>{' '}
                (필수)<span className={st.required}>*</span>
              </Check>
              <Check
                name="telephoneCounselingYn"
                value={String(RegistrationAgencyVm.telephoneCounselingYn(props.index))}
                checked={RegistrationAgencyVm.telephoneCounselingYn(props.index) === true}
                onChange={(e) => RegistrationAgencyVm.setTelephoneCounselingYn(e, props.index)}
                className={st.checkbox}
              >
                견적받은 후 전화상담 요청(선택)
              </Check>
            </div>
            <Error
              name="informationProvideYn"
              value={
                RegistrationAgencyVm.informationProvideYn(props.index)
                  ? String(RegistrationAgencyVm.informationProvideYn(props.index))
                  : ''
              }
              errorCase={{
                required: '필수 약관에 동의해주세요.',
              }}
            />
          </div>
        </div>
      )}
      {RegistrationAgencyVm.corpRegProgressType(props.index) === 'ALREADY_PROGRESSED_REGISTRATION_AGENCY' && (
        <div className={st.questionBox}>
          <div className={st.questionBoxTitle}>담당자</div>
          <div className={st.qusetion}>
            <div className={st.questionTitle}>
              등기대행 담당자
              <div className={st.titleSubInfo}>* 등기대행 연락을 받을 담당자 정보를 입력하세요.</div>
            </div>
            <div className={st.personInChargeBox}>
              <div className={st.personInCharge}>
                <div className={st.title}>성함</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.name`}
                  placeholder="홍길동"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).name || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'name')}
                  errorText={
                    <Error
                      name={`personInCharge.name`}
                      value={RegistrationAgencyVm.personInCharge(props.index).name}
                      errorCase={{
                        required: '이름을 입력하세요.',
                      }}
                    />
                  }
                />
              </div>
              <div className={st.personInCharge}>
                <div className={st.title}>전화번호</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.phoneNum`}
                  placeholder="010-1234-1234"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).phoneNum || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'phoneNum')}
                  errorText={
                    <Error
                      name={`personInCharge.phoneNum`}
                      value={RegistrationAgencyVm.personInCharge(props.index).phoneNum}
                      errorCase={{
                        required: '전화번호를 입력하세요.',
                        pattern: {
                          value: regExp.phoneNumber().test(RegistrationAgencyVm.personInCharge(props.index).phoneNum),
                          message: '올바른 전화번호를 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
              <div className={st.personInCharge}>
                <div className={st.title}>이메일</div>
                <InputText
                  className={st.inputText}
                  name={`personInCharge.email`}
                  placeholder="email@standby.kr"
                  fullSize
                  value={RegistrationAgencyVm.personInCharge(props.index).email || ''}
                  onChange={(e) => RegistrationAgencyVm.setPersonInCharge(e, props.index, 'email')}
                  errorText={
                    <Error
                      name={`personInCharge.email`}
                      value={RegistrationAgencyVm.personInCharge(props.index).email}
                      errorCase={{
                        required: '이메일을 입력하세요.',
                        pattern: {
                          value: regExp.email().test(RegistrationAgencyVm.personInCharge(props.index).email),
                          message: '올바른 이메일 형식으로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.termsBox}>
              <Check
                name="informationProvideYn"
                value={String(RegistrationAgencyVm.informationProvideYn(props.index))}
                checked={RegistrationAgencyVm.informationProvideYn(props.index) === true}
                onChange={(e) => RegistrationAgencyVm.setInformationProvideYn(e, props.index)}
                className={st.checkbox}
              >
                데이터 자동전송을 위한{' '}
                <button type="button" className={st.termsBtn} onClick={() => RegistrationAgencyVm.termsPopup()}>
                  제3자 정보제공에 동의
                </button>{' '}
                (필수)<span className={st.required}>*</span>
              </Check>
            </div>
            <Error
              name="informationProvideYn"
              value={
                RegistrationAgencyVm.informationProvideYn(props.index)
                  ? String(RegistrationAgencyVm.informationProvideYn(props.index))
                  : ''
              }
              errorCase={{
                required: '필수 약관에 동의해주세요.',
              }}
            />
          </div>
        </div>
      )}
    </div>
  ));
}
export default RegistrationAgency;
