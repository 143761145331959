import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';

class TabVm {
  get 주주전원_서면결의Idx() {
    return meetingsStepModel.tabList.findIndex(
      (obj) => obj.tabType === '주주총회_방식' || obj.tabType === '정기주주총회_방식',
    );
  }
  getTabType(idx: number) {
    return meetingsStepModel.tabList[idx].tabType;
  }
  displayWrittenResolution(idx: number) {
    //서면결의방식
    const tabType = this.getTabType(idx);

    return tabType === '주주총회_방식' || tabType === '정기주주총회_방식';
  }
  displayNotiOfDraft(idx: number) {
    //소집통지
    const tabType = this.getTabType(idx);

    if (
      tabType === '주주총회_정보입력' ||
      tabType === '주주총회_대표결정_정보입력' ||
      tabType === '주주전원서면결의_정보입력' ||
      tabType === '주주총회_이사회_정보입력'
    ) {
      const 주주전원_서면결의Yn = meetingsStepModel.tabList[this.주주전원_서면결의Idx].주주전원_서면결의Yn;

      return 주주전원_서면결의Yn === false;
    }

    return false;
  }
  displayDateList(idx: number) {
    //일자입력
    const tabType = this.getTabType(idx);

    if (tabType === '이사회_정보입력' || tabType === '대표_결정사항_정보입력') {
      return true;
    }

    if (
      tabType === '주주총회_정보입력' ||
      tabType === '주주총회_대표결정_정보입력' ||
      tabType === '주주전원서면결의_정보입력' ||
      tabType === '주주총회_이사회_정보입력'
    ) {
      const 주주전원_서면결의Yn = meetingsStepModel.tabList[this.주주전원_서면결의Idx].주주전원_서면결의Yn;

      if (주주전원_서면결의Yn) {
        return true;
      }

      const musterNoticeType = meetingsStepModel.tabList[idx].musterNoticeType;

      if (musterNoticeType) return true;

      return false;
    }
  }
  displayShareholderList(idx: number) {
    return this.getTabType(idx) === '주주명부';
  }
  displayRegistrationAgency(idx: number) {
    return this.getTabType(idx) === '등기_방법';
  }
  displayNewShareholders(idx: number) {
    return this.getTabType(idx) === '신주발행_방식';
  }
}

export default new TabVm();
