import { observable } from 'mobx';

export interface Notification {
  acceptAt: string | null;
  acceptYn: boolean | null;
  callbackUrl: string;
  content: string;
  createdAt: string;
  notificationId: number | null;
  notificationType: string | null;
  readAt: string | null;
  id: number | null;
}

interface NotificationModel {
  notifications: Notification[];
  setNotifications(notifications: Notification[]): void;
}

const notificationModel: NotificationModel = observable<NotificationModel>({
  notifications: [],
  setNotifications(notifications: Notification[]) {
    this.notifications = notifications.map((notification) => {
      return {
        acceptAt: notification.acceptAt || null,
        acceptYn: notification.acceptYn || null,
        callbackUrl: notification.callbackUrl || '',
        content: notification.content || '',
        createdAt: notification.createdAt || '',
        notificationId: notification.notificationId || null,
        notificationType: notification.notificationType || null,
        readAt: notification.readAt || null,
        id: notification.id || null,
      };
    });
  },
});

export default notificationModel;
