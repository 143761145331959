import PageMainTitle from '@src/pages/common/PageMainTitle';
import { PeopleAdmin } from '@standby/dike_ui/assets/icon/fill';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import vm from './AdminSettingVm';
import AuthorityManagementList from './components/AuthorityManagementList';
import ResponsibleList from './components/ResponsibleList';
import st from './style.module.scss';

function AdminSetting() {
  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <div>
      <PageMainTitle title="담당자 추가·변경" icon={<PeopleAdmin width={24} height={24} fill={color.primary800} />} />
      <div className={st.gap23} />
      <ResponsibleList />
      <div className={st.gap36} />
      <AuthorityManagementList />
    </div>
  ));
}

export default AdminSetting;
