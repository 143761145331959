import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';
import vm from '../../UserDashboardVm';

const Card일반 = observer((props: { company: CompanyInfo }) => {
  return (
    <>
      <div className={st.tagContent}>
        <div className={st.tagWrap}>
          <Typography fontType="Detail-12M" className={st.tag} data-type="임원">
            {vm.representative(props.company.executiveMembers).name} 대표님
            {vm.representative(props.company.executiveMembers).count > 1 && (
              <span> +{vm.representative(props.company.executiveMembers).count - 1}</span>
            )}
          </Typography>
        </div>
      </div>
      <Typography fontType="Detail-12M" className={st.subText}>
        {vm.minAddress(props.company.address)}
      </Typography>
    </>
  );
});

export default Card일반;
