import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import clsx from 'clsx';
import st from './AccountRegistrationPopup.module.scss';
import vm from './AccountRegistrationPopupVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';
import FileUpload from '@commonComponents/FileUpload/FileUpload';
import Table from '@standby/common-ui/components/atoms/Table/Table';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import AddBtn from '@standby/common-ui/components/atoms/Button/AddBtn/AddBtn';
import ErrorText from '@standby/common-ui/components/atoms/Text/ErrorText/ErrorText';

function AccountRegistrationPopup(props) {
  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <div className={st.accountRegistrationPopup}>
      <div className={st.infoBox}>
        <div className={st.info}>기장료는 CMS 계좌자동이체 방식으로 진행되어요.</div>
        <div className={st.info}>입력한 정보는 자동으로 저장 되어요.</div>
        {vm.cmsAccountInputDeadline.year && (
          <div className={st.info}>
            계좌정보를 {vm.cmsAccountInputDeadline.year}-{vm.cmsAccountInputDeadline.month}-
            {vm.cmsAccountInputDeadline.day} 까지 등록해주세요.
          </div>
        )}
      </div>
      <form className={st.form} onSubmit={(e) => vm.submit(e)}>
        {/* @todo 컴포넌트 분리하기 */}
        {vm.bankbooks && vm.bankbooks.length ? (
          <div className={st.bankbookList}>
            <div className={st.title}>계좌정보 리스트</div>
            <div className={st.header}>
              <div>선택</div>
              <div>은행</div>
              <div>계좌번호</div>
              <div>계좌용도</div>
            </div>
            <ul className={st.bankList}>
              {vm.bankbooks.map((bankbook, idx) => {
                return (
                  <li key={idx} className={bankbook.available === false ? st.disabled : ''}>
                    <span>
                      <Radio
                        name="bankbookId"
                        value={bankbook.id}
                        onChange={(e) => vm.setBankbookId(e)}
                        className={st.radio}
                        disabled={!bankbook.available}
                      ></Radio>
                    </span>
                    <span className={clsx(st.bankName, st[bankbook.bankName])}>{bankbook.bankName}</span>
                    <span style={{ textAlign: 'left' }}>{bankbook.accountNumber}</span>
                    <span style={{ textAlign: 'left' }}>{bankbook.accountUsage || '-'}</span>
                    {bankbook.available === false && (
                      <span className={st.cmsExplain}>CMS 자동이체 기능이 지원되지 않는 은행이에요.</span>
                    )}
                  </li>
                );
              })}
            </ul>
            <div>
              <Error
                name="bankbookId"
                value={vm.state.bankbookId}
                errorCase={{
                  required: '자동이체를 위한 계좌를 선택하세요.',
                }}
              />
            </div>
            <AddBtn className={st.addAccountBtn} onClick={() => vm.addBankbook()}>
              새로운 계좌 추가하기
            </AddBtn>
          </div>
        ) : (
          <>
            <div className={st.row}>
              <div className={st.title}>은행</div>
              <div className={st.content}>
                <div className={clsx(st.bankContent, vm.state.bankBoxOpenYn ? st.open : '')}>
                  {vm.state.bankName ? (
                    <button type="button" className={st.chooseBankBtn} onClick={() => vm.chooseBtn()}>
                      <span className={clsx(st.text, st[vm.state.bankName])}>{vm.state.bankName}</span>
                    </button>
                  ) : (
                    <button type="button" className={st.bankBoxOpenBtn} onClick={() => vm.chooseBtn()}>
                      <span className={clsx(st.text, vm.state.bankBoxOpenYn ? st.open : '')}>은행 선택</span>
                    </button>
                  )}
                  {!vm.state.bankBoxOpenYn && (
                    <div>
                      <Error
                        name="bankName"
                        value={vm.state.bankName}
                        errorCase={{
                          required: '은행을 선택하세요.',
                        }}
                      />
                    </div>
                  )}
                  <div className={clsx(st.bankListBox, vm.state.bankBoxOpenYn ? st.open : '')}>
                    {vm.bankList.map((bank, idx) => (
                      <label className={clsx(st.bankLabel, vm.state.bankName === bank ? st.checked : '')} key={idx}>
                        <input
                          type="radio"
                          name="bankName"
                          className={st.radio}
                          value={bank}
                          checked={vm.state.bankName === bank}
                          onChange={(e) => vm.setBankName(e)}
                        />
                        <div className={clsx(st.bankName, st[bank])}>{bank}</div>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>계좌번호</div>
              <div className={st.content}>
                <InputText
                  name="accountNumber"
                  value={vm.state.accountNumber}
                  onChange={(e) => vm.setAccountNumber(e)}
                  style={{ width: '460px' }}
                  maxLength={32}
                  errorText={
                    <Error
                      name="accountNumber"
                      value={vm.state.accountNumber}
                      errorCase={{
                        required: '계좌번호를 입력하세요.',
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>
                계좌용도 <span className={st.option}>(선택)</span>
              </div>
              <div className={st.content}>
                <InputText
                  name="accountUsage"
                  value={vm.state.accountUsage}
                  onChange={(e) => vm.setAccountUsage(e)}
                  style={{ width: '460px' }}
                />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>예금주</div>
              <div className={st.content}>
                <InputText value={vm.companyName} style={{ width: '460px' }} disabled />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>통장사본 업로드</div>
              <div className={st.content}>
                <QuestionTooltip title="통장사본 준비방법">
                  <div className={st.tooltipContent}>
                    <div className={st.method1}>
                      <div className={st.title}>
                        <div className={st.number}>방법1</div>
                        <div className={st.text}>
                          은행에서 발급받은 통장 첫 페이지(계좌번호가 나오는 부분)를 펼치고, 스캔 어플 등을 이용해서 잘
                          촬영해주세요.
                        </div>
                      </div>
                      <div className={st.method1Example}>
                        <div>
                          <div className={st.title}>- 스캔 예시</div>
                          <img src="/images/scan_example.svg" alt="스캔 예시" className={st.img} />
                        </div>
                        <div>
                          <div className={st.title}>- 촬영 예시</div>
                          <img src="/images/camera_example.svg" alt="촬영 예시" className={st.img} />
                        </div>
                      </div>
                    </div>
                    <div className={st.method2}>
                      <div className={st.title}>
                        <div className={st.number}>방법2</div>
                        <div className={st.text}>
                          인터넷뱅킹 홈페이지에 접속하고, 공동인증서로 로그인을 한 후, 통장사본을 다운로드 받으세요.
                          <br />
                          다운로드 받는 경로를 모르는 경우, "통장사본"이라고 검색을 하거나, 은행 고객센터에 전화문의를
                          하세요.
                        </div>
                      </div>
                      <img src="/images/bankbook_example.svg" alt="통장사본 예시" className={st.bankbookImg} />
                    </div>
                  </div>
                </QuestionTooltip>
                <FileUpload name="file" style={{ marginTop: '16px' }} multiple={false} />
              </div>
            </div>
            <div className={st.row}>
              <div className={st.title}>납부일</div>
              <div className={st.content}>
                <InputText value={vm.paymentDay} style={{ width: '220px' }} disabled />
              </div>
            </div>
          </>
        )}
        <div className={st.btnArea}>
          <SquareBtn type="submit" className={st.nextBtn}>
            다음
          </SquareBtn>
        </div>
      </form>
    </div>
  ));
}
export default AccountRegistrationPopup;
