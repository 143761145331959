import corpModel from '@model/Corp/corpModel';
import { observable } from 'mobx';
import React from 'react';

class GuideVm {
  state: {
    guideDisplayYn: boolean;
  };
  constructor(guideDisplayYn: boolean) {
    this.state = observable({
      guideDisplayYn: guideDisplayYn,
    });
  }
  get corpId() {
    return corpModel.id;
  }
  guideHideState(e: React.ChangeEvent<HTMLInputElement>, localStorageKey: string) {
    const getLocalItem = localStorage.getItem(localStorageKey);

    this.state.guideDisplayYn = e.target.checked;
    let hideIdList = [];

    if (this.state.guideDisplayYn) {
      if (getLocalItem) {
        hideIdList = getLocalItem.split(',').filter((id) => id !== String(this.corpId));
        localStorage.setItem(localStorageKey, hideIdList.join());
      }
    } else {
      if (getLocalItem) {
        hideIdList = getLocalItem.split(',');
        if (!hideIdList.includes(String(this.corpId))) {
          hideIdList.push(String(this.corpId));
        }

        localStorage.setItem(localStorageKey, hideIdList.join());
      } else {
        localStorage.setItem(localStorageKey, String(this.corpId));
      }
    }
  }
}

export default GuideVm;
