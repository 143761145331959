import { Alert, Confirm } from '@model/newDialogModel';
import NewModalModel from '@model/newModalModel';
import { useDeleteSealMutation, useSealDeleteValidateMutation } from '@pages/Corp/Seal/queries';
import { Seal, StampEnum } from '@service/Corp/Seal/SealService.interface';
import { UnorderedList } from '@standby/dike_ui/components/atoms';

const useDeleteSeal = (corpId: string) => {
  const sealDeleteValidateMutation = useSealDeleteValidateMutation(corpId);
  const deleteSealMutation = useDeleteSealMutation(corpId);

  const confirmDeleteSeal = async (seal: Seal) => {
    return await Confirm(
      `${seal.stampType === StampEnum.CORPORATE ? `${seal.representativeName} 법인인감` : `${seal.alias}`}(을)를 삭제할까요?`,
      <UnorderedList>
        <li>삭제 시 복구가 불가하며, 이 도장을 다른 담당자가 사용할 수 없어요.</li>
      </UnorderedList>,
    );
  };

  const deleteSeal = async (seal: Seal, isModal: boolean) => {
    if (seal.stampType === StampEnum.AUTHORIZED) {
      sealDeleteValidateMutation.mutate(
        { sealId: seal.sealId },
        {
          async onSuccess(data) {
            if (data.isDeletable === false) {
              void Alert(
                `사용인감을 삭제할 수 없어요.`,
                <UnorderedList>
                  <li>
                    대표님이 {data.representativeCount}명 이어서, 최소 {data.representativeCount}개의 사용인감이
                    필요해요. 삭제를 원한다면, 새로운 사용인감을 등록한 후 삭제해주세요.
                  </li>
                </UnorderedList>,
              );

              return;
            }

            if (await confirmDeleteSeal(seal)) {
              deleteSealMutation.mutate(
                { sealId: seal.sealId },
                {
                  async onSuccess() {
                    await Alert('삭제되었어요.');
                    if (isModal) {
                      NewModalModel.closeModal();
                    }
                  },
                },
              );
            }
          },
        },
      );

      return;
    }

    if (await confirmDeleteSeal(seal)) {
      deleteSealMutation.mutate(
        { sealId: seal.sealId },
        {
          async onSuccess() {
            await Alert('삭제되었어요.');
            if (isModal) {
              NewModalModel.closeModal();
            }
          },
        },
      );
    }
  };

  return { deleteSeal };
};

export default useDeleteSeal;
