import NewModalModel from '@model/newModalModel';
import { Seal } from '@service/Corp/Seal/SealService.interface';
import { More } from '@standby/dike_ui/assets/icon/fill';
import { Bin, Write } from '@standby/dike_ui/assets/icon/line';
import { TextButton, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';

import SealEditModal from './SealEditModal';
import st from './style.module.scss';
import useDeleteSeal from './useDeleteSeal';

const SealInfo = observer(({ seal, corpId }: { seal: Seal; corpId: string }) => {
  const [opened, setOpened] = useState(false);
  const sealRef = useRef<HTMLDivElement>(null);
  const { deleteSeal } = useDeleteSeal(corpId);
  const handleClickOutside = (event: MouseEvent) => {
    if (sealRef.current && !sealRef.current.contains(event.target as Node)) {
      setOpened(false); // 외부 클릭 시 닫기
    }
  };

  const openSealEditModal = (seal: Seal, corpId: string) => {
    NewModalModel.openModal(<SealEditModal seal={seal} corpId={corpId} />);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={st.sealInfo} onClick={() => setOpened(!opened)} ref={sealRef}>
      <Typography fontType="Body-16R" className={st.title}>
        {seal.alias}
      </Typography>
      <Typography fontType="Detail-12M" className={st.updateAt}>
        업데이트 : {seal.updatedAt}
      </Typography>
      <More fill={color.grayScale700} width="24" height="24" className={st.moreIcon} />
      {opened && (
        <ul className={st.sealControlList}>
          <li>
            <TextButton type="button" className={st.btn} onClick={() => openSealEditModal(seal, corpId)}>
              <Write width="16" height="16" fill={color.grayScale700} /> 수정하기
            </TextButton>
          </li>
          <li>
            <TextButton type="button" className={st.btn} onClick={() => deleteSeal(seal, false)}>
              <Bin width="16" height="16" fill={color.grayScale700} /> 삭제하기
            </TextButton>
          </li>
        </ul>
      )}
    </div>
  );
});

export default SealInfo;
