import { List, Typography } from '@standby/dike_ui';
import Color from '@standby/dike_ui/design-token/color.module.scss';

import st from './Register법인.module.scss';

interface Register법인Props {
  companyName: string;
  companyId: string;
  isDemoYn: boolean;
  isRegistering: '등록중' | null;
}

const Register법인 = ({ companyId, isDemoYn, companyName, isRegistering }: Register법인Props) => {
  return (
    <div className={st.container}>
      <div style={{ display: 'flex' }}>
        <List width={20} height={20} fill={Color.grayScale300} className={st.listIcon} />
        <a href={`/corp/redirect?corp=${companyId}`}>
          {isDemoYn && (
            <Typography fontType="Detail-12M" className={st.demo}>
              demo
            </Typography>
          )}
          <Typography fontType="Body-14R" className={st.companyName}>
            {companyName}
          </Typography>
        </a>
      </div>
      {isRegistering && (
        <Typography fontType="Detail-10M" className={st.tag}>
          등록중
        </Typography>
      )}
    </div>
  );
};

export default Register법인;
