import { Graph, Home, Info, Menu, Money, People,PeopleAdmin, Star, Typography } from '@standby/dike_ui';
import Color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import type { ComponentType, ReactNode, SVGProps } from 'react';

import st from './NavBox.module.scss';

const icons: { [key: string]: ComponentType<SVGProps<SVGSVGElement>> } = {
  Home,
  Info,
  Menu,
  Graph,
  Money,
  Star,
  PeopleAdmin,
  People,
};

interface NavBoxProps {
  title: string;
  iconType: string;
  isActive?: boolean;
  isActiveLabel?: boolean;
  children: ReactNode;
}

const NavBox = ({ title, children, iconType, isActive, isActiveLabel }: NavBoxProps) => {
  const Icon = icons[iconType];
  const active = isActive || isActiveLabel;

  return (
    <div className={st.navBox}>
      <div className={st.titleWrapper}>
        <Icon
          width={16}
          height={16}
          fill={active ? Color.primary100 : Color.grayScale300}
          className={clsx(st.linkTitle)}
        />
        <Typography fontType="Title-12B" className={clsx(st.linkTitle, active && st.active)}>
          {title}
        </Typography>
      </div>
      <div className={st.sideLinkList}>{children}</div>
    </div>
  );
};

export default NavBox;
