import type {
  CorpVatRs,
  Guide,
  PreparationData,
  SelectedVatRs,
  StatusOfVatReporting,
  VatData,
  VatGuideRs,
} from '@src/service/Corp/Vat/vatService.interface';
import { observable } from 'mobx';

interface IVatModel {
  allListUrl: string;
  guides: Guide[];
  statusOfVatReporting: StatusOfVatReporting;
  preparationData: PreparationData;
  vatData: VatData[];
  /**
   * 값이 있는 경우, 기장계약 해지 상태.
   */
  bookkeepingServiceEndAt: string | null;
  setGuides(data: Partial<VatGuideRs>): void;
  setVat(data: Partial<CorpVatRs>): void;
  setSelectVat(data: Partial<SelectedVatRs>): void;
}

const vatModel = observable<IVatModel>({
  allListUrl: '',
  guides: [],
  statusOfVatReporting: {
    vatReportPeriodType: '',
    reportingExemptYn: null,
    vatList: [],
    reportingPeriod: {
      year: '',
      month: '',
      day: '',
    },
    steps: [],
  },
  preparationData: {
    title: '',
    submissionDeadline: '',
    email: '',
    returnReadyDocumentUrl: '',
  },
  vatData: [],
  bookkeepingServiceEndAt: null,

  setGuides(data: Partial<VatGuideRs>) {
    this.allListUrl = data.allListUrl || '';
    if (data.guides?.length) {
      this.guides = data.guides.map((guide) => ({
        title: guide?.title || '',
        contents: guide?.content || '',
        url: guide?.url || '',
      }));
    } else {
      this.guides = [];
    }
  },

  setVat(data: Partial<CorpVatRs>) {
    const { statusOfVatReporting, preparationData, vatData, bookkeepingServiceEndAt } = data;

    if (statusOfVatReporting) {
      this.statusOfVatReporting.vatReportPeriodType = statusOfVatReporting.vatReportPeriodType || '';
      this.statusOfVatReporting.reportingExemptYn = statusOfVatReporting.reportingExemptYn ?? null;

      this.statusOfVatReporting.vatList =
        statusOfVatReporting.vatList?.map((item) => ({
          id: item.id || '',
          title: item.title || '',
        })) || [];

      this.statusOfVatReporting.reportingPeriod = statusOfVatReporting.reportingPeriod || {
        year: '',
        month: '',
        day: '',
      };

      this.statusOfVatReporting.steps =
        statusOfVatReporting.steps?.map((item) => ({
          name: !item.name?.includes('부가세') ? `부가세_${item.name}` : item.name,
          content: item.content || '',
        })) || [];
    }

    this.preparationData = preparationData || {
      title: '',
      submissionDeadline: '',
      email: '',
      returnReadyDocumentUrl: '',
    };

    this.vatData =
      vatData?.map((item) => ({
        reportingPeriod: item.reportingPeriod || '',
        vatReportPeriodType: item.vatReportPeriodType || '',
        reportProgressStatus: item.reportProgressStatus || '',
        earlyRefundYn: item.earlyRefundYn ?? null,
        reportingExemptYn: item.reportingExemptYn ?? null,
        refundTaxYn: item.refundTaxYn ?? null,
        card: item.card || {
          content: '',
          salesTax: '',
          purchaseTax: '',
          sumOfTheDeductibles: '',
          additionalTaxPayable: '',
          amountOfTaxPaid: '',
          refundTaxAmount: '',
          estimatedAmountOfNotice: {
            amount: '',
            content: '',
          },
          statementOfPaymentUrl: '',
          statementOfVatReturn: {
            downloadUrl: '',
            thumbnailUrl: '',
          },
          purchaseAndSalesLedgerFile: {
            downloadUrl: '',
            thumbnailUrl: '',
          },
          etcFile: {
            downloadUrl: '',
            thumbnailUrl: '',
          },
        },
      })) || [];

    this.bookkeepingServiceEndAt = bookkeepingServiceEndAt || null;
  },

  setSelectVat(data: Partial<SelectedVatRs>) {
    if (data.statusOfVatReporting) {
      this.statusOfVatReporting.vatReportPeriodType = data.statusOfVatReporting.vatReportPeriodType || '';
      this.statusOfVatReporting.reportingExemptYn = data.statusOfVatReporting.reportingExemptYn ?? null;
      this.statusOfVatReporting.vatList =
        data.statusOfVatReporting.vatList?.map((item) => ({
          id: item.id || '',
          title: item.title || '',
        })) || [];
      this.statusOfVatReporting.reportingPeriod = data.statusOfVatReporting.reportingPeriod || {
        year: '',
        month: '',
        day: '',
      };
      this.statusOfVatReporting.steps =
        data.statusOfVatReporting.steps?.map((item) => ({
          name: !item.name?.includes('부가세') ? `부가세_${item.name}` : item.name,
          content: item.content || '',
        })) || [];
    }

    this.preparationData = data.preparationData || {
      title: '',
      submissionDeadline: '',
      email: '',
      returnReadyDocumentUrl: '',
    };
  },
});

export default vatModel;
