import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import st from '../UserDashboard.module.scss';
import vm from '../UserDashboardVm';
import CardNew from './CardNew';
import Card등록중 from './Card등록중';
import Card일반 from './Card일반';
import Card청산 from './Card청산';
import Card필수 from './Card필수';
import Card필요 from './Card필요';

const ComponyCard = observer((props: { company: CompanyInfo }) => {
  return (
    <li className={clsx(props.company.demoYn ? st.demo : null, props.company.liquidationYn ? st.liquidation : null)}>
      <Link to={`/corp/redirect?corp=${props.company.id}`} className={clsx(st.corp, st.corpCard)}>
        <div className={st.top}>
          <Typography fontType="Title-24M" className={st.corpName}>
            {props.company.koreanName}
          </Typography>
        </div>
        <div className={st.bottom}>
          {props.company.demoYn === true && (
            <Typography fontType="Body-14R" className={st.demoText}>
              스탠바이 서비스, 한번 둘러보세요!
            </Typography>
          )}
          {props.company.liquidationYn === true && <Card청산 company={props.company} />}
          {props.company.demoYn === false && props.company.liquidationYn === false && (
            <>
              {vm.viewType(props.company) === '필요' && <Card필요 company={props.company} />}
              {vm.viewType(props.company) === '필수' && <Card필수 company={props.company} />}
              {vm.viewType(props.company) === 'new' && <CardNew company={props.company} />}
              {vm.viewType(props.company) === '일반' && <Card일반 company={props.company} />}
              {vm.viewType(props.company) === '등록중' && <Card등록중 />}
            </>
          )}
        </div>
      </Link>
    </li>
  );
});

export default ComponyCard;
