import adminModel from '@src/model/Corp/AdminSetting/adminModel';
import { Alert } from '@src/model/newDialogModel';
import AdminSettingService from '@src/service/Corp/AdminSetting/adminSettingService';
import type { Manager } from '@src/service/Corp/AdminSetting/adminSettingService.interface';
import { Check as CheckIcon, Close as CloseIcon, Write as WriteIcon } from '@standby/dike_ui/assets/icon/line';
import { Button, TextField, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import st from './style.module.scss';

interface RoleCellProps {
  manager: Manager;
}

const RoleCell = observer(({ manager }: RoleCellProps) => {
  const [isEditInputOpened, setIsEditInputOpened] = useState(false);
  const [value, setValue] = useState(manager.role || '');
  const inputRef = useRef<HTMLInputElement>(null);

  const isGeneralManager = adminModel.authority.authorityType === 'GENERAL_MANAGER';

  const openEditInput = useCallback((open = false) => {
    if (open) {
      setIsEditInputOpened(true);
    } else {
      setIsEditInputOpened(false);
      setValue(manager.role || '');
    }
  }, []);

  const submit = async () => {
    if (isGeneralManager) {
      await AdminSettingService.editRole(manager.userId ?? '', value);
    } else {
      await Alert('최고 관리자만 역할을 수정할 수 있어요.', '역할 수정이 필요하다면 최고 관리자에게 문의하세요.');
    }

    openEditInput(false);
  };

  // 수정 input 보이면 자동 focus
  useEffect(() => {
    if (isEditInputOpened && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditInputOpened, inputRef.current]);

  return (
    <div className={st.cell}>
      {isEditInputOpened ? (
        <div className={st.editInput}>
          <TextField className={st.input} value={value} onChange={(e) => setValue(e.target.value)} ref={inputRef} />
          <div className={st.buttonContainer}>
            <Button className={st.button} variant="outlined" color="grayScale400" type="button" onClick={submit}>
              <CheckIcon width={16} height={16} fill={color.grayScale700} />
            </Button>
            <Button
              className={st.button}
              variant="outlined"
              color="grayScale400"
              type="button"
              onClick={() => openEditInput(false)}
            >
              <CloseIcon width={16} height={16} fill={color.grayScale700} />
            </Button>
          </div>
        </div>
      ) : (
        <div className={st.row}>
          <Typography fontType="Body-14R">{manager.role || '역할 입력'}</Typography>
          <button className={st.iconButton} type="button" onClick={() => openEditInput(true)}>
            <WriteIcon width={16} height={16} fill={color.grayScale700} />
          </button>
        </div>
      )}
    </div>
  );
});

export default RoleCell;
