import { Typography } from '@standby/dike_ui/components/atoms';
import clsx from 'clsx';
import { ReactNode } from 'react';

import st from './style.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const SectionTitle = (props: Props) => {
  return (
    <Typography className={clsx(st.sectionTitle, props.className)} fontType="Title-18M">
      {props.children}
    </Typography>
  );
};

export default SectionTitle;
