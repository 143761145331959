import Guide from '@commonComponents/Guide/Guide';
import PageMainTitle from '@commonComponents/PageMainTitle';
import SectionTitle from '@src/pages/common/SectionTitle';
import { Info } from '@standby/dike_ui/assets/icon/fill';
import { Button, Typography } from '@standby/dike_ui/components/atoms';
import { Tooltip } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import SealInfo from './components/SealInfo';
import st from './style.module.scss';
import useSealHome from './useSealHome';

const Home = observer(() => {
  const location = useLocation();
  const corpId = String(queryString.parse(location.search).corp);

  const { sealListState } = useSealHome(corpId);

  return (
    <div className={st.sealHome}>
      <PageMainTitle icon={<Info width="24" height="24" fill={color.primary800} />} title="법인인감" />
      {sealListState.isSuccess && (
        <div className={st.sealContent}>
          <Guide
            title="법인인감 가이드"
            localStorageKey="sealGuideHide"
            corpId={String(corpId)}
            items={sealListState.data.guides}
            fullLink="https://standby.kr/blog/search/?keyword=인감"
          />
          <img src="/images/법인인감_소구용_배너.png" className={st.banner} />
          <section className={st.sealListWrap}>
            <div className={st.titleWrap}>
              <SectionTitle>
                등록된 법인인감({sealListState.data.seals.filter((seal) => seal.stampType === 'CORPORATE').length}) ·
                사용인감(
                {sealListState.data.seals.filter((seal) => seal.stampType === 'AUTHORIZED').length})
              </SectionTitle>
              <Link to={`/corp/seal/create?corp=${corpId}`}>
                <Button type="button" color="primary400">
                  법인인감 등록하기
                </Button>
              </Link>
            </div>
            <ul className={st.sealList}>
              {sealListState.data.seals.map((seal) => (
                <li className={clsx(st.seal, seal.stampType === 'CORPORATE' && st.corpopate)} key={seal.sealId}>
                  <div className={st.preview}>
                    {seal.stampType === 'CORPORATE' && (
                      <Typography fontType="Detail-12M" className={st.badge}>
                        법인인감({seal?.representativeName || '대표님 지정필요'})
                      </Typography>
                    )}
                    {seal.stampType === 'AUTHORIZED' && (
                      <Typography fontType="Detail-12M" className={st.badge}>
                        사용인감
                      </Typography>
                    )}
                    {seal?.description && (
                      <Tooltip
                        direction="leftUp"
                        iconColor={seal.isWarningDescription ? 'siRed500' : 'grayScale700'}
                        iconType="exclamation"
                        speechBubbleStyle={{
                          maxWidth: '308px',
                        }}
                        className={st.description}
                        iconSize="medium"
                      >
                        {seal.description}
                      </Tooltip>
                    )}
                    <img className={st.previewImg} src={seal.image} />
                  </div>
                  <SealInfo seal={seal} corpId={corpId} />
                </li>
              ))}
              {sealListState.data.seals.length === 1 && (
                <li className={st.addSeal}>
                  <Link to={`/corp/seal/create?corp=${corpId}`} className={st.addSealBtn}>
                    <Button type="button" color="primary400">
                      법인인감 등록하기
                    </Button>
                  </Link>
                </li>
              )}
            </ul>
          </section>
        </div>
      )}
    </div>
  );
});

export default Home;
