import { useObserver } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';

import Progress from './Progress/Progress';
import Step from './Step/Step';

function Agenda() {
  return useObserver(() => (
    <Routes>
      {/* 진행리스트 */}
      <Route path="/progress" element={<Progress />}></Route>
      {/* 작성스탭 */}
      <Route path="/step/*" element={<Step />}></Route>
    </Routes>
  ));
}

export default Agenda;
