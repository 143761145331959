import NewModalModel from '@model/newModalModel';
import { Close } from '@standby/dike_ui/assets/icon/line';
import { Button } from '@standby/dike_ui/components/atoms';
import { ModalContentWrapper, ModalLayout } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { useObserver } from 'mobx-react';

import st from './SealPromotionPopup.module.scss';

interface SealPromotionPopupProps {
  corpId: number | null;
}

function SealPromotionPopup(props: SealPromotionPopupProps) {
  return useObserver(() => (
    <ModalLayout>
      <ModalContentWrapper style={{ padding: 0 }}>
        <div className={st.popupContent}>
          <img src="/images/sealPromotion.png?1" />
          <div className={st.btnArea}>
            <a href={`/corp/seal?corp=${props.corpId}`}>
              <Button className={st.linkBtn} type="button" size="large" color="primary400">
                온라인 날인 기능 둘러보기
              </Button>
            </a>
          </div>
          <button type="button" onClick={() => NewModalModel.closeModal()}>
            <Close width="24" height="24" fill={color.grayScale500} className={st.closeBtn} />
          </button>
        </div>
      </ModalContentWrapper>
    </ModalLayout>
  ));
}

export default SealPromotionPopup;
