import { get, post } from '@common/module/httpRequest';
import AdminAddModel from '@model/Corp/AdminAdd/AdminAddModel';
import corpModel from '@model/Corp/corpModel';
import { Alert } from '@src/model/newDialogModel';

import type { AuthoritiesRs, InvitationRq } from './adminAddService.interface';

class AdminAddService {
  async load() {
    const res = await get<AuthoritiesRs>(`/userAuthority/company/${corpModel.id}/menu`);

    AdminAddModel.setAuthorities(res?.data?.authorities);
  }
  async invite(param: InvitationRq) {
    param.authorities = [
      ...AdminAddModel.addData.authorities.acct,
      ...AdminAddModel.addData.authorities.law,
      ...AdminAddModel.addData.authorities.partner,
    ].map((authority) => {
      return {
        menuCode: authority.menuCode,
        useYn: authority.useYn,
      };
    });

    await post(`userAuthority/company/${corpModel.id}/invite`, param);
    await Alert('담당자 초대를 발송했어요.');
    window.location.href = `/corp/adminSetting?corp=${corpModel.id}`;
  }
}

export default new AdminAddService();
