import type { Authorities } from '@src/service/Corp/AdminAdd/adminAddService.interface';
import { observable } from 'mobx';

interface IAdminAddModel {
  addData: {
    name: string;
    email: string;
    role: string;
    authorityType: 'MANAGER' | 'GENERAL_MANAGER';
    authorities: Authorities;
  };
  setAuthorities: (authorities: Authorities) => void;
}

const AdminAddModel = observable<IAdminAddModel>({
  addData: {
    name: '',
    email: '',
    role: '',
    authorityType: 'MANAGER',
    authorities: {
      law: [],
      acct: [],
      partner: [],
    },
  },
  setAuthorities(authorities) {
    this.addData.authorities.law = authorities.law
      ? authorities.law.map((law) => {
          return {
            menuName: law?.menuName || '',
            menuCode: law?.menuCode || '',
            useYn: law?.useYn || false,
          };
        })
      : [];
    this.addData.authorities.acct = authorities.acct
      ? authorities.acct.map((acct) => {
          return {
            menuName: acct?.menuName || '',
            menuCode: acct?.menuCode || '',
            useYn: acct?.useYn || false,
          };
        })
      : [];

    this.addData.authorities.partner = authorities.partner
      ? authorities.partner.map((partner) => {
          return {
            menuName: partner?.menuName || '',
            menuCode: partner?.menuCode || '',
            useYn: partner?.useYn || false,
          };
        })
      : [];
  },
});

export default AdminAddModel;
