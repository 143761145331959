import NewModalModel from '@model/newModalModel';
import NavBox from '@pages/Corp/Side/components/NavBox';
import HeaderSideLink from '@pages/Corp/Side/HeaderSidebar/components/HeaderSideLink';
import {
  Button,
  CheckBox,
  Exit,
  SelectField,
  TextButton,
  TextField,
  Typography,
  UnorderedList,
} from '@standby/dike_ui';
import { ErrorValidation, ModalContentWrapper, ModalLayout, ModalTitle } from '@standby/dike_ui/components/molecules';
import Color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

import st from './UserSetting.module.scss';
import UserSettingVm from './UserSettingVm';

interface UserSettingProps {
  activeTab?: string;
}

const UserSetting = observer((props: UserSettingProps) => {
  const [vm] = useState(new UserSettingVm(props.activeTab));

  useEffect(() => {
    vm.load();
  }, []);

  return (
    <ModalLayout>
      <ModalContentWrapper className={st.modalContent}>
        <nav className={st.side}>
          <NavBox title="나의 계정" iconType="People" isActive={true} isActiveLabel={true}>
            {vm.tabList.map((tab, index) => (
              <HeaderSideLink
                menuName={tab}
                isActiveLabel={tab === vm.state.activeTab}
                key={index}
                isLock={false}
                onClick={() => vm.changeTab(tab)}
              />
            ))}
          </NavBox>
          <button type="button" onClick={vm.logout} className={st.logout}>
            <Typography fontType="Body-14M" className={st.inner}>
              <Exit width={16} height={16} fill={Color.grayScale000} style={{ marginRight: '6px' }} />
              로그아웃
            </Typography>
          </button>
        </nav>
        <div className={st.tabContentWrapper}>
          <ModalTitle title={vm.state.activeTab} onClickClose={() => NewModalModel.closeModal()} withIcon={false} />
          <ModalContentWrapper className={st.tabContent}>
            {vm.state.activeTab === '이메일' && (
              <>
                <div className={st.inputWithBtn}>
                  <TextField name="name" value={vm.userSetData.email} disabled className={st.input} />
                  <Button
                    type="button"
                    onClick={() => vm.changeEmail(vm.userSetData.email)}
                    color="primary400"
                    variant="outlined"
                  >
                    변경하기
                  </Button>
                </div>
                <UnorderedList className={st.explainList}>
                  <li>이메일은 아이디로 사용돼요. 변경 시 유의해 주세요.</li>
                  <li>위의 이메일로 이메일 변경 링크를 보내드려요.</li>
                </UnorderedList>
              </>
            )}
            {vm.state.activeTab === '비밀번호' && (
              <form onSubmit={(e) => vm.passwordChangeSubmit(e)} className={clsx(st.form, st.btnForm)}>
                <div className={st.inputRow}>
                  <TextField
                    name="password"
                    type="password"
                    value={vm.state.password}
                    placeholder="현재 비밀번호"
                    label="현재 비밀번호"
                    onChange={(e) => vm.setPassword(e)}
                    labelSize="small"
                    error={
                      <ErrorValidation
                        name="password"
                        value={vm.state.password}
                        errorCase={{
                          required: '현재 비밀번호를 입력해 주세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.password,
                              ),
                            message: '올바른 비밀번호 형식으로 입력해 주세요.',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <div className={st.inputRow}>
                  <TextField
                    name="newPassword"
                    type="password"
                    value={vm.state.newPassword}
                    placeholder="새 비밀번호"
                    label="새 비밀번호"
                    onChange={(e) => vm.setNewPassword(e)}
                    labelSize="small"
                    error={
                      <ErrorValidation
                        name="newPassword"
                        value={vm.state.newPassword}
                        errorCase={{
                          required: '새 비밀번호를 입력해 주세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.newPassword,
                              ),
                            message: '올바른 비밀번호 형식으로 입력해 주세요.',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <div className={st.inputRow}>
                  <TextField
                    name="newPasswordCheck"
                    type="password"
                    value={vm.state.newPasswordCheck}
                    placeholder="새 비밀번호 확인"
                    label="새 비밀번호 확인"
                    onChange={(e) => vm.setNewPasswordCheck(e)}
                    labelSize="small"
                    error={
                      <ErrorValidation
                        name="newPasswordCheck"
                        value={vm.state.newPasswordCheck}
                        errorCase={{
                          required: '비밀번호 확인을 입력해 주세요.',
                          validate: {
                            func: vm.isSame(),
                            message: '동일한 비밀번호를 입력해주세요.',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <Button className={st.formBtn} type="submit" color="primary400" variant="outlined">
                  변경하기
                </Button>
              </form>
            )}

            {vm.state.activeTab === '회원정보' && (
              <>
                <form onSubmit={(e) => vm.nameChangeSubmit(e)} className={clsx(st.inputWithBtn, st.inputRow)}>
                  <TextField
                    name="name"
                    value={vm.userSetData.name}
                    placeholder="홍길동"
                    label="이름"
                    onChange={vm.changeName}
                    labelSize="small"
                    className={st.input}
                    error={
                      <ErrorValidation
                        name="name"
                        value={vm.userSetData.name}
                        errorCase={{
                          required: '이름을 입력하세요.',
                        }}
                      />
                    }
                  />
                  <Button type="submit" color="primary400" variant="outlined">
                    변경하기
                  </Button>
                </form>
                <form onSubmit={(e) => vm.phoneNumberChangeSubmit(e)} className={clsx(st.inputWithBtn, st.inputRow)}>
                  <SelectField
                    name="countryCode"
                    value={vm.userSetData.countryCode}
                    onChange={vm.changeCountryCode}
                    style={{ width: '64px' }}
                    disabled
                    labelSize="small"
                    label="전화번호"
                  >
                    <option value="82">+82</option>
                  </SelectField>
                  <TextField
                    name="phoneNumber"
                    value={vm.userSetData.phoneNumber}
                    placeholder="01012345678"
                    onChange={vm.changePhoneNumber}
                    labelSize="small"
                    className={st.input}
                    error={
                      <ErrorValidation
                        name="phoneNumber"
                        value={vm.userSetData.phoneNumber}
                        errorCase={{
                          required: '전화번호를 입력하세요.',
                        }}
                      />
                    }
                  />
                  <Button type="submit" color="primary400" variant="outlined">
                    변경하기
                  </Button>
                </form>
                <UnorderedList className={st.explainList}>
                  <li>법인등록, 등기신청 진행알림을 카카오톡 또는 문자로 보내드려요.</li>
                </UnorderedList>
              </>
            )}
            {vm.state.activeTab === '약관동의' && vm.userSetData.email && (
              <>
                <div className={st.termsAllCheckBox}>
                  <CheckBox
                    name="marketingAcceptYn"
                    checked={vm.userSetData.marketingAcceptYn}
                    onChange={vm.changeMarketingAcceptYn}
                    size="small"
                  >
                    <span className={st.option}>[선택]</span> 법인운영에 유용한 뉴스레터 등 프로모션 정보 수신 동의
                  </CheckBox>
                  <TextButton underline type="button" onClick={vm.marketingTermContentModal} fontType="Detail-12M">
                    상세보기
                  </TextButton>
                </div>
                <div className={st.termsCheckBox}>
                  <CheckBox
                    name="marketingAcceptEmailYn"
                    checked={vm.userSetData.marketingAcceptEmailYn}
                    onChange={vm.changeMarketingAcceptEmailYn}
                    size="small"
                  >
                    이메일
                  </CheckBox>
                  <CheckBox
                    name="marketingAcceptSmsYn"
                    checked={vm.userSetData.marketingAcceptSmsYn}
                    onChange={vm.changeMarketingAcceptSmsYn}
                    size="small"
                  >
                    SMS, 카톡
                  </CheckBox>
                </div>
                <UnorderedList className={clsx(st.explainList, st.right)}>
                  <li>{`${vm.userSetData.marketingYnChangedDate?.year}-${vm.userSetData.marketingYnChangedDate?.month}-${vm.userSetData.marketingYnChangedDate?.day} ${vm.userSetData.marketingAcceptYn ? '동의함' : '동의 철회함'}`}</li>
                </UnorderedList>
              </>
            )}
          </ModalContentWrapper>
        </div>
      </ModalContentWrapper>
    </ModalLayout>
  );
});

export default UserSetting;
