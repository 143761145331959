import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './ElectronicSignaturePopup.module.scss';
import vm from './ElectronicSignaturePopupVm';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import Loading from '@commonComponents/Loading/Loading';
import ExecutiveSealSelect from '@commonComponents/Document/SealSelect/ExecutiveSealSelect';

function ElectronicSignaturePopup(props) {
  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <form onSubmit={(e) => vm.requestAgree(e)}>
      <div className={st.electronicSignaturePopup}>
        {vm.state.loadingYn && <Loading />}
        <div className={st.documentWrap}>
          <div className={st.documentBox}>
            <div className={st.content}>
              <iframe title="기장대리 및 회계자문 계약서" className={st.document} src={vm.bookkeepingContractLink} />
            </div>
          </div>
          <div className={st.optionWrap}>
            <ExecutiveSealSelect includeCorporateSeal isSignature corpId={String(vm.corpId)} />
          </div>
        </div>
        <div className={st.btnArea}>
          <SquareBtn className={st.agreeBtn} type="submit">
            위 내용에 모두 동의합니다.
          </SquareBtn>
          <div className={st.btnExplain}>버튼을 누르면 회사도장이 날인되어요!</div>
        </div>
      </div>
    </form>
  ));
}
export default ElectronicSignaturePopup;
