import SectionTitle from '@src/pages/common/SectionTitle';
import { Plus } from '@standby/dike_ui';
import { Button } from '@standby/dike_ui/components/atoms/Button';
import Table from '@standby/dike_ui/components/atoms/Table';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import vm from '../../AdminSettingVm';
import AuthorityCell from './components/AuthorityCell';
import RoleCell from './components/RoleCell';
import st from './style.module.scss';

const ResponsibleList = observer(() => {
  return (
    <div className={st.container}>
      <div className={st.header}>
        <SectionTitle>담당자 목록</SectionTitle>
        <Link to={`/corp/adminAdd?corp=${vm.id}`}>
          <Button variant="outlined" color="primary500" className={st.inviteLink}>
            <Plus width={16} height={16} fill={color.primary500} />
            담당자 추가/초대하기
          </Button>
        </Link>
      </div>
      <Table
        columnDefinition={[{ width: '226px' }, { width: '226px' }, { width: '226px' }, {}]}
        className={'dike-root'}
      >
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>이름</Table.HeadCell>
            <Table.HeadCell>역할</Table.HeadCell>
            <Table.HeadCell>메일주소</Table.HeadCell>
            <Table.HeadCell>자격</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {vm.managers.map((manager, index) => (
            <Table.Row key={index}>
              <Table.Cell>{manager.name}</Table.Cell>
              <Table.Cell>
                <RoleCell manager={manager} />
              </Table.Cell>
              <Table.Cell>{manager.email}</Table.Cell>
              <Table.Cell>
                <AuthorityCell
                  manager={manager}
                  onChangeSelect={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    vm.changeAuthorityType(e, index, manager.userId)
                  }
                  onClickDelete={() =>
                    manager.inviting ? vm.invitationDelete(manager.userId) : vm.deleteAuthority(manager.userId)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
});

export default ResponsibleList;
