import type { Manager } from '@src/service/Corp/AdminSetting/adminSettingService.interface';
import { Button, SelectField } from '@standby/dike_ui';
import { observer } from 'mobx-react';
import React from 'react';

import st from './style.module.scss';

interface AuthorityCellProps {
  manager: Manager;
  onChangeSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onClickDelete: () => void;
}

const AuthorityCell = observer(({ manager, onChangeSelect, onClickDelete }: AuthorityCellProps) => {
  return (
    <div className={st.cell}>
      <SelectField
        value={manager.inviting ? '' : (manager.authorityType ?? '')}
        className={st.authorityType}
        name="authorityType"
        onChange={onChangeSelect}
        disabled={manager.inviting}
      >
        {manager.inviting ? (
          <option disabled value="">
            초대 수락 대기 중
          </option>
        ) : (
          <>
            <option value="GENERAL_MANAGER">최고 관리자</option>
            <option value="MANAGER">담당자</option>
          </>
        )}
      </SelectField>
      <Button variant="outlined" color="grayScale400" type="button" onClick={onClickDelete} className={st.deleteButton}>
        삭제
      </Button>
    </div>
  );
});

export default AuthorityCell;
