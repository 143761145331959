import { useObserver } from 'mobx-react';

import st from './DocumentPopup.module.scss';

function DocumentPopup(props) {
  return useObserver(() => (
    <div className={st.DocumentPopup}>
      <div className={st.documentWrap}>
        <div className={st.documentBox}>
          <div className={st.content}>
            <iframe title={props.iframeTitle} className={st.document} src={props.iframeSrc} />
          </div>
        </div>
        {props.options && <div className={st.options}>{props.options}</div>}
      </div>
      {props.children}
    </div>
  ));
}

export default DocumentPopup;
