interface Guide {
  title: string;
  contents: string;
  url: string;
}

enum StampEnum {
  AUTHORIZED = 'AUTHORIZED',
  CORPORATE = 'CORPORATE',
}

type StampType = `${StampEnum}`;

interface Seal {
  stampType: StampType;
  sealId: string;
  representativeName?: string;
  alias: string;
  updatedAt: string;
  image: string;
  description?: string;
  isWarningDescription?: boolean;
  executiveId?: string;
}

interface GetSealListRs {
  guides: Guide[];
  seals: Seal[];
}

interface PostSealRq {
  stampType: StampType;
  // Base64 형식의 data-url 문자열
  image: string;
  // 임원 id
  executiveId?: number;
  // 도장 별칭
  alias: string;
}

// 대표 이사 정보
interface RepresentativeInfo {
  name: string;
  id: number;
  // 법인인감이 이미 등록된 대표인지 판별 (도장 등록시 선택할 수 있는지 없는지)
  isDisable: boolean;
}

// 인감 등록 및 관리를 위한 대표 정보 조회
interface GetRepresentativeListRs {
  representatives: RepresentativeInfo[];
}

interface GetSealDeleteValidateRs {
  isDeletable: boolean;
  representativeCount: number;
}
interface GetSealDeleteValidateRq {
  sealId: string;
}

interface GetSealChangeValidateRq {
  sealId: string;
  executiveId: string;
}

interface GetSealChangeValidateRs {
  isChangingOthersSeal: boolean;
  remainingSealId: number;
  isDeletable: boolean;
  representativeCount: number;
}

interface ChangeSealRq {
  stampType: StampType;
  sealId: string;
  executiveId: string;
  alias: string;
}

interface DeleteSealRq {
  sealId: string;
}

interface DocumentSeal {
  id: number;
  alias: string;
  image: string;
}

interface GetSealExecutiveRs {
  executives: {
    name: string;
    id: number;
    seal?: DocumentSeal;
  }[];
  authorizedSeals: DocumentSeal[];
}
interface GetSealAgendaRs {
  executives: {
    name: string;
    id: number;
    seal: DocumentSeal;
  }[];
  authorizedSeals: DocumentSeal[];
}
interface GetSealAuthorizedRs {
  authorizedSeals: DocumentSeal[];
}

export {
  ChangeSealRq,
  DeleteSealRq,
  DocumentSeal,
  GetRepresentativeListRs,
  GetSealAgendaRs,
  GetSealAuthorizedRs,
  GetSealChangeValidateRq,
  GetSealChangeValidateRs,
  GetSealDeleteValidateRq,
  GetSealDeleteValidateRs,
  GetSealExecutiveRs,
  GetSealListRs,
  Guide,
  PostSealRq,
  RepresentativeInfo,
  Seal,
  StampEnum,
  StampType,
};
