import { makeAutoObservable } from 'mobx';
import { ReactNode } from 'react';

class NewModalModel {
  modalComponent: ReactNode = null;

  constructor() {
    makeAutoObservable(this);
  }

  openModal(component: ReactNode) {
    this.modalComponent = component;
  }

  closeModal() {
    this.modalComponent = null;
  }
}

export default new NewModalModel();
