import QuestionTooltip from '@standby/common-ui/components/molecules/QuestionTooltip/QuestionTooltip';

import st from './style.module.scss'

const Tooltip소집통지생략 = () => {

  return (
    <QuestionTooltip title="소집통지를 생략하는 꿀팁">
      <div className={st.tooltipContent}>
        <div className={st.qna}>
          <div className={st.title}>
            자본금 10억 미만이라면, 모든 주주가 동의시 소집통지를 생략할 수 있어요.
          </div>
          <div className={st.content}>
            <ul>
              <li>
                원칙상 정관에 따라 소집통지를 보내고, 10~14일을 기다린 후 주주총회를 열 수 있어요. 하지만 모든
                주주가 생략하면 바로 오늘이라도 주주총회를 할 수 있어요.
              </li>
            </ul>
          </div>
        </div>
        <div className={st.qna}>
          <div className={st.title}>주주들에게 주주총회 소집통지 생략동의를 클릭 몇 번으로 받아보세요.</div>
          <div className={st.content}>
            <ul>
              <li>
                스탠바이에서는 클릭 몇 번으로 주주들에게 주주총회 소집통지 생략동의를 받을 수 있어요.
                주주들에게 동의서에 직접 도장을 받지 않아도 되니 매우 편리해요!
              </li>
            </ul>
          </div>
        </div>
        <div className={st.sample}>
          <img src="/images/noti_of_draft_ex.svg" />
        </div>
      </div>
    </QuestionTooltip>
  );
};

export default Tooltip소집통지생략;
