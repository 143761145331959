import { observable } from 'mobx';

export interface ExecutiveMember {
  name: string;
  position: string;
  representativeYn: boolean;
  positionExpiredDays: number | null;
  tag: string | null;
}

export interface CompanyInfo {
  id: string | null;
  koreanName: string;
  companyType: string;
  demoYn: boolean;
  prefiexed: boolean;
  address: string;
  executiveTags: {
    필수: number;
    필요: number;
  };
  stockHolderListRegisteredYn: boolean;
  executiveMembers: ExecutiveMember[];
  companyRegisteredStatus: string;
  newTags: string[];
  liquidationYn: boolean;
}

interface UserDashboardModel {
  companiesInfo: CompanyInfo[];
  acceptWaitingCompanies: string[];
  name: string;
  setCompaniesInfo(companiesInfo: CompanyInfo[]): void;
  setAcceptWaitingCompanies(data: { name: string; acceptWaitingCompanies: string[] }): void;
}

const userDashboardModel = observable<UserDashboardModel>({
  companiesInfo: [],
  acceptWaitingCompanies: [],
  name: '',
  setCompaniesInfo(companiesInfo) {
    if (companiesInfo && companiesInfo.length) {
      this.companiesInfo = companiesInfo.map((company) => {
        return {
          id: company?.id || null,
          koreanName: company?.koreanName || '',
          companyType: company?.companyType || '',
          demoYn: company?.demoYn || false,
          prefiexed: company?.prefiexed || false,
          address: company?.address || '',
          executiveTags: {
            필수: company?.executiveTags?.필수 || 0,
            필요: company?.executiveTags?.필요 || 0,
          },
          stockHolderListRegisteredYn: company?.stockHolderListRegisteredYn || false,
          executiveMembers: company?.executiveMembers
            ? company.executiveMembers.map((executiveMember) => ({
                name: executiveMember?.name || '',
                position: executiveMember?.position || '',
                representativeYn: executiveMember?.representativeYn || false,
                positionExpiredDays: executiveMember?.positionExpiredDays || null,
                tag: executiveMember?.tag || null,
              }))
            : [],
          companyRegisteredStatus: company?.companyRegisteredStatus || '',
          newTags: company?.newTags ? company?.newTags.map((newTag) => newTag || '') : [],
          liquidationYn: company?.liquidationYn || false,
        };
      });
    } else {
      this.companiesInfo = [];
    }
  },
  setAcceptWaitingCompanies(data) {
    this.name = data.name || '';
    this.acceptWaitingCompanies = data.acceptWaitingCompanies || [];
  },
});

export default userDashboardModel;
