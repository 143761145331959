import { makeObservable, observable } from 'mobx';

class SealEditModalModel {
  nextOpenModalSealId?: number = undefined;

  constructor() {
    makeObservable(this, {
      nextOpenModalSealId: observable,
    });
  }
}

export default new SealEditModalModel();
