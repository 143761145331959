import type { Manager } from '@src/service/Corp/AdminSetting/adminSettingService.interface';
import CheckBox from '@standby/dike_ui/components/atoms/Controls/CheckBox';
import Table from '@standby/dike_ui/components/atoms/Table';
import { observer } from 'mobx-react';

import vm from '../../../../AdminSettingVm';
import st from './style.module.scss';

interface AuthorityManagementTableProps {
  manager?: Manager;
}

const AuthorityManagementTable = observer(({ manager }: AuthorityManagementTableProps) => {
  const isGeneralManager = manager?.authorityType === 'GENERAL_MANAGER';

  if (isGeneralManager || !manager) {
    return <GeneralManagerTable manager={manager} />;
  }

  return (
    <Table className={st.table} columnDefinition={[{ width: '160px' }, {}]}>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell colSpan={2}>{manager.name}</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.HeadCell>법무 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox
                size="small"
                checked={vm.checkAllAuthoritiesSelected(manager.userId, 'law')}
                onChange={(e) => vm.onChangeSelectAll(e, manager.userId, 'law')}
                id={`law[${manager.userId}]`}
              >
                전체
              </CheckBox>
              {manager.authorities.law.map((authority, index) => (
                <CheckBox
                  size="small"
                  key={authority.menuName}
                  checked={authority.useYn}
                  onChange={(e) => vm.onChangeUseYn(e, manager.userId, 'law', index)}
                >
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeadCell>회계 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox
                size="small"
                checked={vm.checkAllAuthoritiesSelected(manager.userId, 'acct')}
                onChange={(e) => vm.onChangeSelectAll(e, manager.userId, 'acct')}
                id={`acct[${manager.userId}]`}
              >
                전체
              </CheckBox>
              {manager.authorities.acct.map((authority, index) => (
                <CheckBox
                  size="small"
                  key={authority.menuName}
                  checked={authority.useYn}
                  onChange={(e) => vm.onChangeUseYn(e, manager.userId, 'acct', index)}
                >
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeadCell>제휴 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox
                size="small"
                checked={vm.checkAllAuthoritiesSelected(manager.userId, 'partner')}
                onChange={(e) => vm.onChangeSelectAll(e, manager.userId, 'partner')}
                id={`partner[${manager.userId}]`}
              >
                전체
              </CheckBox>
              {manager.authorities.partner.map((authority, index) => (
                <CheckBox
                  size="small"
                  key={authority.menuName}
                  checked={authority.useYn}
                  onChange={(e) => vm.onChangeUseYn(e, manager.userId, 'partner', index)}
                >
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
});

/**
 * 최고관리자 용 테이블
 * 최고관리자는 모든 권한을 가지고 있고, 권한을 제외 할 수 없음
 */
const GeneralManagerTable = ({ manager }: AuthorityManagementTableProps) => {
  return (
    <Table className={st.table} columnDefinition={[{ width: '160px' }, {}]}>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell colSpan={2}>최고 관리자</Table.HeadCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.HeadCell>법무 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox size="small" disabled checked={true}>
                전체
              </CheckBox>
              {manager?.authorities?.law?.map((authority) => (
                <CheckBox size="small" key={authority.menuName} disabled checked={true}>
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeadCell>회계 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox size="small" disabled checked={true}>
                전체
              </CheckBox>
              {manager?.authorities?.acct?.map((authority) => (
                <CheckBox size="small" key={authority.menuName} disabled checked={true}>
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.HeadCell>제휴 기능</Table.HeadCell>
          <Table.Cell>
            <div className={st.checkboxContainer}>
              <CheckBox size="small" disabled checked={true}>
                전체
              </CheckBox>
              {manager?.authorities?.partner?.map((authority) => (
                <CheckBox size="small" key={authority.menuName} disabled checked={true}>
                  {authority.menuName}
                </CheckBox>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

export default AuthorityManagementTable;
