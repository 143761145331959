import {post } from '@common/module/httpRequest';

class notificationService {
  async InviteTypeAccept(url, acceptYn, notificationId) {
    await post(url, {
      acceptYn: acceptYn,
      notificationId: notificationId,
    });
  }
  async readNotification() {
    await post('/notification/read');
  }
}

export default new notificationService();
