import { Alert } from '@src/model/newDialogModel';
import PageMainTitle from '@src/pages/common/PageMainTitle';
import SectionTitle from '@src/pages/common/SectionTitle';
import { Info } from '@standby/dike_ui/assets/icon/fill';
import { Button, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import Guide from './components/Guide';
import OptionSelectBox from './components/OptionSelectBox';
import SealNukkiContainer from './components/SealNukkiContainer';
import st from './style.module.scss';
import useCreateSeal from './useCreateSeal';

const CreateSeal = observer(() => {
  const location = useLocation();
  const navigation = useNavigate();

  const corpId = String(queryString.parse(location.search).corp);

  const {
    sealMob,
    nukki,
    uploadKey,
    imageFile,
    representatives,
    submit,
    onUploadImageFile,
    onDropImageFile,
    onDeleteImageFile,
  } = useCreateSeal(corpId);

  if (!corpId) {
    Alert('잘못된 접근', '법인인감 페이지로 돌아갑니다.')
      .then(() => {
        navigation(`/corp/seal?corp=${corpId}`, { replace: true });
      })
      .catch(console.error);
  }

  return (
    <div className={st.container}>
      <form onSubmit={(e) => void submit(e)}>
        <PageMainTitle title='법인인감' icon={<Info width="24" height="24" fill={color.primary800} />} />
        <SectionTitle className={st.sectionTitle}>법인인감 등록</SectionTitle>
        <div className={st.content}>
          <div className={st.main}>
            <SealNukkiContainer
              key={uploadKey}
              type={imageFile ? 'nukki' : 'upload'}
              nukki={nukki}
              imageFile={imageFile}
              onUploadImageFile={onUploadImageFile}
              onDeleteImageFile={onDeleteImageFile}
              onDropImageFile={onDropImageFile}
            />
            <Guide />
          </div>
          <div className={st.side}>
            <OptionSelectBox sealMob={sealMob} representatives={representatives} />
          </div>
        </div>
        <div className={st.footer}>
          <Button color={'primary400'} type='submit'>
            <Typography fontType='Body-16R'>등록하기</Typography>
          </Button>
        </div>
      </form>
    </div>
  );
});

export default CreateSeal;
