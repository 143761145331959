import { CompanyInfo } from '@model/userDashboardModel';
import SectionTitle from '@src/pages/common/SectionTitle';
import { Typography } from '@standby/dike_ui';

import { guideList, tipList } from './constant';
import st from './style.module.scss';

const BlogContent = (props: { companiesInfo: CompanyInfo[] }) => {
  return (
    <div className={st.blogContentList}>
      <div className={st.content}>
        <div className={st.corpTip}>
          <SectionTitle>법인 설립 직후 해야할 일</SectionTitle>
          <div className={st.tipList}>
            {tipList.map((tip, index) => (
              <a target="_blank" href={tip.url} rel="noreferrer" key={index}>
                <Typography fontType="Body-16M" className={st.title}>
                  {tip.title}
                </Typography>
                <Typography fontType="Body-14R" className={st.tip}>
                  {tip.content}
                </Typography>
              </a>
            ))}
          </div>
        </div>
        <div className={st.guide}>
          {props.companiesInfo.length !== 0 && (
            <a href={`/corp/alliance?corp=${props.companiesInfo[0].id}`} target="_blank" rel="noreferrer">
              <img src="/images/dashboardEtc.png?5" style={{ marginBottom: '20px' }} />
            </a>
          )}

          <SectionTitle>기초, 심화 가이드</SectionTitle>
          <div className={st.guideList}>
            {guideList.map((guide, index) => (
              <a target="_blank" href={guide.url} rel="noreferrer" key={index}>
                <span className={st.icon}>{guide.icon}</span>
                <Typography fontType="Body-16M" className={st.title}>
                  {guide.title}
                </Typography>
                <Typography fontType="Body-14R" className={st.subText}>
                  {guide.content}
                </Typography>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
