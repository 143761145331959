import { get } from '@common/module/httpRequest';
import corpModel from '@model/Corp/corpModel';
import NewModalModel from '@model/newModalModel';
import portalModel from '@model/portalModel';
import AgendaPromotionPopup from '@pages/Corp/Popup/AgendaPromotionPopup/AgendaPromotionPopup';
import SealPromotionPopup from '@pages/Corp/Popup/SealPromotionPopup/SealPromotionPopup';
import { runInAction } from 'mobx';

import type { PopupRq } from './popupService.interface';

class PopupService {
  async loadPopup() {
    const res = await get<PopupRq>(`/company/${corpModel.id}/popup`, {}, {});
    const data = res.data;

    runInAction(() => {
      switch (data.popupName) {
        case 'AGENDA_INIT':
        case 'AGENDA_EXISTING':
          portalModel.title = '';
          portalModel.content = <AgendaPromotionPopup agendaPopupType={data.popupName} corpId={corpModel.id} />;
          portalModel.closeBtn = false;
          break;
        case 'SEAL':
          NewModalModel.openModal(<SealPromotionPopup corpId={corpModel.id} />);
          break;
      }
    });
  }
}

export default new PopupService();
