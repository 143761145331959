import { ArrowRight } from '@standby/dike_ui/assets/icon/line';
import { TextButton, Toggle, Typography } from '@standby/dike_ui/components/atoms';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useState } from 'react';

import st from './Guide.module.scss';
import GuideVm from './GuideVm';

interface GuideProps {
  localStorageKey: string;
  title: string;
  items: {
    title: string;
    contents: string;
    url: string;
  }[];
  fullLink: string;
  corpId: string;
}

function Guide(props: GuideProps) {
  const getLocalStorageValue = (): boolean => {
    let hideIdList: string[] = [];

    const getLocalItem = localStorage.getItem(props.localStorageKey);

    if (getLocalItem) {
      hideIdList = getLocalItem.split(',');
    }
    if (hideIdList.includes(props.corpId)) {
      return false;
    }

    return true;
  };
  const [vm] = useState(new GuideVm(getLocalStorageValue()));

  return useObserver(() => (
    <div className={clsx(st.guide, vm.state.guideDisplayYn === false && st.closed)}>
      <div className={st.titleRow}>
        <div className={st.controlTitle}>
          <Toggle checked={vm.state.guideDisplayYn} onChange={(e) => vm.guideHideState(e, props.localStorageKey)} />
          <Typography fontType="Body-16R" className={st.title}>
            {props.title}
          </Typography>
        </div>
        <a href={props.fullLink} target="_blank" rel="noreferrer">
          <TextButton type="button" underline className={st.fullLink}>
            전체 목록 보기
          </TextButton>
        </a>
      </div>
      {vm.state.guideDisplayYn === true && (
        <div className={st.GuideList}>
          <div className={st.inside}>
            <div className={st.slide}>
              {props.items.map((item, idx) => {
                return (
                  <a href={item.url} target="_blank" key={idx} rel="noreferrer">
                    <Typography fontType="Detail-12M" className={st.title} tag="div">
                      {item.title}
                    </Typography>
                    <Typography
                      tag="div"
                      fontType="Body-15R"
                      className={st.content}
                      dangerouslySetInnerHTML={{ __html: item.contents.replace(/\n/g, '<br/>') }}
                    />
                    <TextButton type="button" className={st.moreView}>
                      더보기 <ArrowRight width="12px" height="12px" fill={color.grayScale500} />
                    </TextButton>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  ));
}

export default Guide;
