import { loadImageAsync } from '../NukkiImage/utils';
import { createNewCanvas } from '../utils';
import { resizeImageByCanvas, trimMarginByCanvas } from './utils';

/**
 * @typedef {Object} IterationIndexOption
 * @property {number} startX
 * @property {number} endX
 * @property {number} startY
 * @property {number} endY
 */

class ImageProcessor {
  static maxSize = 160;
  static minSize = 80;

  _base64DataUrl;
  _canvasImage;
  _originalImage;

  /**
   *
   * @param {string} imageBase64DataUrlString
   * @param {{maxSize: number, minSize: number}} [options]
   */
  constructor(imageBase64DataUrlString, options = {}) {
    this.setImageDataUrl(imageBase64DataUrlString);

    if (options.maxSize) ImageProcessor.maxSize = options.maxSize;
    if (options.minSize) ImageProcessor.minSize = options.minSize;
  }

  setImageDataUrl(imageBase64DataUrlString) {
    this._canvasImage = null;
    this._originalImage = null;

    if (
      typeof imageBase64DataUrlString !== 'string' &&
      imageBase64DataUrlString.slice(0, 22) !== 'data:image/png;base64,'
    ) {
      console.error('imageBase64DataUrlString is not in valid format: ', imageBase64DataUrlString);
      this._base64DataUrl = null;

      return;
    }

    this._base64DataUrl = imageBase64DataUrlString;
  }

  /**
   *
   * 이미지 편집 프로세스
   *
   * @param {IterationIndexOption} [iterationIndexOption] 원본 여백을 날리기 시작하고 멈출 위치
   * @returns {string} base64 데이터 URL
   */
  async process(iterationIndexOption) {
    try {
      await this.trimMargin(iterationIndexOption);
      await this.resize();

      return this.result;
    } catch (error) {
      console.error('[ERROR] ImageProcessor.process() error');
      console.error(error);

      return this._base64DataUrl;
    }
  }

  /**
   *
   * 편집된 이미지를 base64 데이터 URL로 반환
   *
   * @returns {string} base64 데이터 URL
   */
  get result() {
    return this._canvasImage.toDataURL('image/png');
  }

  updateCanvasImage(canvasImage) {
    this._canvasImage = canvasImage;
  }

  async getCanvasImage() {
    if (this._canvasImage && this._originalImage)
      return { canvasImage: this._canvasImage, originalImage: this._originalImage };

    const originalImage = await loadImageAsync(this._base64DataUrl);

    const { canvas: canvasImage, ctx } = createNewCanvas({
      width: originalImage.width,
      height: originalImage.height,
    });

    ctx.drawImage(originalImage, 0, 0);

    this.updateCanvasImage(canvasImage);
    this._originalImage = originalImage;

    return { canvasImage, originalImage };
  }

  /**
   *
   * 도장 부분만 남기고 나머지 여백 없애기
   *
   * @param {IterationIndexOption} [iterationIndexOption] 원본 여백을 날리기 시작하고 멈출 위치
   */
  async trimMargin(iterationIndexOption) {
    const { canvasImage, originalImage } = await this.getCanvasImage();
    const { width: imgWidth, height: imgHeight } = originalImage;

    const trimmedCanvas = trimMarginByCanvas(canvasImage, imgWidth, imgHeight, iterationIndexOption);

    this.updateCanvasImage(trimmedCanvas);
  }

  /**
   *
   * 최대 크기 보다 작게 이미지 조절
   *
   * @param {number} maxSize 픽셀 단위
   */
  async resize(maxSize = ImageProcessor.maxSize, minSize = ImageProcessor.minSize) {
    const { canvasImage } = await this.getCanvasImage();

    const resizedCanvas = resizeImageByCanvas(canvasImage, maxSize, minSize);

    this.updateCanvasImage(resizedCanvas);
  }
}

export default ImageProcessor;
