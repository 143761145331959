import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';

const CardNew = observer((props: { company: CompanyInfo }) => {
  return (
    <div className={st.tagContent}>
      <div className={st.tagWrap}>
        <Typography fontType="Detail-10M" className={clsx(st.tag, st.smallTag)} data-type="new">
          NEW
        </Typography>
      </div>
      <div className={st.text}>
        <Typography fontType="Body-14R" className={st.mainText}>
          {props.company.newTags.slice(0, 3).join(', ')}
          {props.company.newTags.length > 3 && (
            <Typography fontType="Body-14M" className={st.number}>
              +{props.company.newTags.length - 3}
            </Typography>
          )}
        </Typography>
      </div>
    </div>
  );
});

export default CardNew;
