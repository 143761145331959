import corpModel from '@src/model/Corp/corpModel';
import type { MenuType } from '@src/service/Corp/corpService.interface';
import { Lock, Typography } from '@standby/dike_ui';
import Color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { ForwardedRef, forwardRef } from 'react';
import { Link } from 'react-router-dom';

import st from './SideLink.module.scss';
import { checkAuthority, getStyle, showAuthorityAlert } from './utils';

export interface SideLinkProps {
  menuName: string;
  path: string;
  tag?: string;
  boxTag?: string;
  isActive?: boolean;
  type?: MenuType;
}

// eslint-disable-next-line react/display-name
const SideLink = forwardRef(
  ({ menuName, path, type, tag, boxTag, isActive }: SideLinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
    return checkAuthority(type) ? (
      <Link className={clsx(st.sideLink, isActive && st.active)} to={`${path}?corp=${corpModel.id}`} ref={ref}>
        <Typography fontType="Body-14M">{menuName}</Typography>
        {tag && (
          <Typography fontType="Detail-10M" className={clsx(getStyle(tag), st.tag)}>
            {tag}
          </Typography>
        )}
        {boxTag && (
          <Typography fontType="Detail-10M" className={clsx(getStyle(boxTag), st.boxTag)}>
            {boxTag}
          </Typography>
        )}
      </Link>
    ) : (
      <button
        type="button"
        className={clsx(st.sideButton, st.buttonCursor)}
        onClick={() => showAuthorityAlert(menuName)}
      >
        <Lock width={16} height={16} fill={Color.siYellow500} />
        <Typography fontType="Body-14M">{menuName}</Typography>
      </button>
    );
  },
);

export default SideLink;
