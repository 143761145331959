import { filters } from 'fabric';

import { normalize } from '../utils';

class RedFilter extends filters.BaseFilter {
  static type = 'RedFilter';
  static redFilterValue = 32;

  /**
   * 붉은 색을 얼마나 엄격하게 판단할지의 기준
   * @type {Number} 0 ~ 1
   * @default 0.5
   */
  scale = 0.25;

  applyTo2d({ imageData: { data } }) {
    // 픽셀 데이터 필터링 (붉은 색만 남김)
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i]; // Red
      const g = data[i + 1]; // Green
      const b = data[i + 2]; // Blue

      // 붉은색 감지 조건
      const isRed =
        r > RedFilter.redFilterValue && // 빨간색이 충분히 강해야 함
        r > g * this.scaleFactor && // 빨간색이 초록색보다 훨씬 강해야 함
        r > b * this.scaleFactor; // 빨간색이 파란색보다 훨씬 강해야 함

      if (isRed) {
        data[i] = 255; // Red 강조
        data[i + 1] = 0; // Green 제거
        data[i + 2] = 0; // Blue 제거
      } else {
        // 나머지 색상을 검은색으로 변경
        data[i] = 0; // Red
        data[i + 1] = 0; // Green
        data[i + 2] = 0; // Blue
        data[i + 3] = 0; // Alpha
      }
    }
  }

  get scaleFactor() {
    return normalize(this.scale + 1, 0, 2);
  }
}

export default RedFilter;
