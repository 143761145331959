import { createNewCanvas } from '../utils';

/**
 * @typedef {Object} IterationIndexOption
 * @property {number} startX
 * @property {number} endX
 * @property {number} startY
 * @property {number} endY
 */

/**
 *
 * canvas를 사용해서 여백을 제거한다.
 *
 * @param {HTMLCanvasElement} canvasImage 편집하고 싶은 이미지가 그려진 canvas
 * @param {number} imgWidth 원본 이미지 너비
 * @param {number} imgHeight 원본 이미지 높이
 * @param {IterationIndexOption} [iterationIndexOption] 원본 여백을 날리기 시작하고 멈출 위치
 * @returns {HTMLCanvasElement}
 */
export const trimMarginByCanvas = (canvasImage, imgWidth, imgHeight, iterationIndexOption) => {
  const ctx = canvasImage.getContext('2d');

  const imageData = ctx.getImageData(0, 0, imgWidth, imgHeight);
  const data = imageData.data;

  const startX = iterationIndexOption?.startX ?? 0,
    startY = iterationIndexOption?.startY ?? 0,
    endX = iterationIndexOption?.endX ?? imgWidth,
    endY = iterationIndexOption?.endY ?? imgHeight;

  // 붉은색 픽셀이 존재하는 영역을 기록하기 위한 변수
  let minX = imgWidth,
    minY = imgHeight,
    maxX = 0,
    maxY = 0;

  // 각 픽셀을 순회한다
  for (let y = startY; y < endY; y++) {
    for (let x = startX; x < endX; x++) {
      const index = (y * imgWidth + x) * 4;
      const a = data[index + 3];

      const isTransparent = a === 0;

      if (!a || isTransparent) {
        continue;
      }

      // 여백을 없애기 위한 최소, 최대 좌표를 갱신한다
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }
  }

  // 여백을 없애기 위한 최소, 최대 좌표를 기반으로 새로운 Canvas의 크기를 계산한다
  const cropWidth = maxX - minX + 1;
  const cropHeight = maxY - minY + 1;

  const { canvas: croppedCanvas, ctx: croppedCtx } = createNewCanvas({
    width: cropWidth,
    height: cropHeight,
  });

  // 여백을 없애기 위한 최소, 최대 좌표를 기반으로 새로운 Canvas에 그린다
  croppedCtx.drawImage(canvasImage, minX, minY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

  return croppedCanvas;
};

/**
 *
 * canvas를 사용해서 이미지를 리사이즈한다.
 *
 * @param {HTMLCanvasElement} canvasImage 편집하고 싶은 이미지가 그려진 canvas
 * @param {number} maxSize pixel 단위
 * @param {number} minSize pixel 단위
 * @returns {HTMLCanvasElement}
 */
export const resizeImageByCanvas = (canvasImage, maxSize, minSize) => {
  // 기존 이미지 크기 가져오기
  const originalWidth = canvasImage.width;
  const originalHeight = canvasImage.height;

  const calcScale = () => {
    if (minSize && (originalWidth < minSize || originalHeight < minSize)) {
      return Math.max(minSize / originalWidth, minSize / originalHeight, 1);
    } else if (maxSize && (originalWidth > maxSize || originalHeight > maxSize)) {
      return Math.min(maxSize / originalWidth, maxSize / originalHeight, 1);
    }

    return 1;
  };

  // 최대 크기 설정
  const scale = calcScale();

  // 새로운 크기 계산
  const newWidth = Math.round(originalWidth * scale);
  const newHeight = Math.round(originalHeight * scale);

  const { canvas: resizeCanvas, ctx: resizeCtx } = createNewCanvas();

  // 리사이즈할 Canvas 크기 설정
  resizeCanvas.width = newWidth;
  resizeCanvas.height = newHeight;

  // 기존 canvasImage를 리사이즈된 크기로 그리기
  resizeCtx.drawImage(canvasImage, 0, 0, originalWidth, originalHeight, 0, 0, newWidth, newHeight);

  return resizeCanvas;
};
