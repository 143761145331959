import { createQueryKeys } from '@lukemorales/query-key-factory';
import SealService from '@service/Corp/Seal/SealService';
import {
  ChangeSealRq,
  DeleteSealRq,
  GetRepresentativeListRs,
  GetSealChangeValidateRq,
  GetSealDeleteValidateRq,
  GetSealListRs,
  PostSealRq,
} from '@service/Corp/Seal/SealService.interface';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = createQueryKeys('Seal', {
  sealList: (corpId) => [corpId],
  representatives: (corpId) => [corpId],
});

const useSealListQuery = (corpId: string) => {
  return useQuery<GetSealListRs>({
    ...queryKey.sealList(corpId),
    queryFn: () => SealService.getSealList(corpId),
    enabled: !!corpId,
  });
};

const useRepresentativeListQuery = (corpId: string) => {
  return useQuery<GetRepresentativeListRs>({
    ...queryKey.representatives(corpId),
    queryFn: () => SealService.getRepresentativeList(corpId),
    initialData: {
      representatives: [],
    },
    enabled: !!corpId,
  });
};

const useDeleteSealMutation = (corpId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param: DeleteSealRq) => SealService.deleteSeal(corpId, param),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKey.sealList(corpId).queryKey });
    },
  });
};

const useSealDeleteValidateMutation = (corpId: string) => {
  return useMutation({
    mutationFn: (param: GetSealDeleteValidateRq) => SealService.getSealDeleteValidate(corpId, param),
  });
};

const useSealChangeValidateMutation = (corpId: string) => {
  return useMutation({
    mutationFn: (param: GetSealChangeValidateRq) => SealService.getSealChangeValidate(corpId, param),
  });
};

const useChangeSealMutation = (corpId: string) => {
  return useMutation({
    mutationFn: (param: ChangeSealRq) => SealService.changeSeal(corpId, param),
  });
};

const usePostSealMutation = (corpId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (param: PostSealRq) => SealService.postSeal(corpId, param),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKey.sealList(corpId).queryKey, type: 'inactive' });
    },
  });
};

export {
  queryKey,
  useChangeSealMutation,
  useDeleteSealMutation,
  usePostSealMutation,
  useRepresentativeListQuery,
  useSealChangeValidateMutation,
  useSealDeleteValidateMutation,
  useSealListQuery,
};
