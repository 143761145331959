import { Canvas as FabricCanvas } from 'fabric';

import { createBackground } from './Background';

class NukkiCanvas extends FabricCanvas {
  /**
   *
   * @param {HTMLCanvasElement} canvasElement
   * @param {{width: number, height: number, backgroundCheckerSize?: number, backgroundOffset?: {top: number, left: number}}} [options]
   */
  constructor(canvasElement, options = {}) {
    super(canvasElement, {
      width: options.width,
      height: options.height,
      selection: false,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    });

    this._keepsObjWithinBounds();
    this._setBackground(options.backgroundCheckerSize, options.backgroundOffset);
    this.renderAll();
  }

  destroy() {
    super.destroy();
  }

  /**
   *
   * 체크 무늬 배경 설정
   *
   */
  _setBackground(checkerSize, offset) {
    const background = createBackground({
      width: this.width,
      height: this.height,
      checkerSize,
      offset,
    });

    this.add(background);
    this.sendObjectToBack(background);
  }

  /**
   *
   * 객체가 캔버스 밖으로 나가지 않도록 제한
   *
   */
  _keepsObjWithinBounds() {
    this.on('object:moving', function (e) {
      const obj = e.target;

      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
        return;
      }

      obj.setCoords();

      // top-left corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }

      // bot-right corner
      if (
        obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height ||
        obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width
      ) {
        obj.top = Math.min(
          obj.top,
          obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top,
        );
        obj.left = Math.min(
          obj.left,
          obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left,
        );
      }
    });

    let left1 = 0,
      top1 = 0,
      scale1x = 0,
      scale1y = 0,
      width1 = 0,
      height1 = 0;

    this.on('object:scaling', function (e) {
      const obj = e.target;

      obj.setCoords();

      const brNew = obj.getBoundingRect();

      if (
        brNew.width + brNew.left >= obj.canvas.width ||
        brNew.height + brNew.top >= obj.canvas.height ||
        brNew.left < 0 ||
        brNew.top < 0
      ) {
        obj.left = left1;
        obj.top = top1;
        obj.scaleX = scale1x;
        obj.scaleY = scale1y;
        obj.width = width1;
        obj.height = height1;
      } else {
        left1 = obj.left;
        top1 = obj.top;
        scale1x = obj.scaleX;
        scale1y = obj.scaleY;
        width1 = obj.width;
        height1 = obj.height;
      }
    });
  }
}

export default NukkiCanvas;
