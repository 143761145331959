import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import { DocumentSeal } from '@service/Corp/Seal/SealService.interface';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React from 'react';

import st from './style.module.scss';

interface SealLabelLiProps {
  seal: DocumentSeal;
  sealType: '법인인감' | '사용인감';
  executiveName?: string;
  executiveId: number;
  type: 'Agenda' | 'Executive';
  changeEventCallback?: () => void;
}

enum ExecutiveIdKey {
  Executive = 'executiveId',
  Agenda = 'agendaExecutiveId',
}

const SealLabelLi = observer(
  ({ seal, sealType, executiveName, executiveId, type, changeEventCallback }: SealLabelLiProps) => {
    const changeExecutiveSeal = (e: React.ChangeEvent<HTMLInputElement>, executiveId: number) => {
      SealSelectModel.selectedSeals.forEach((selectedSeal) => {
        const sealId = Number(e.target.value);

        if (selectedSeal[ExecutiveIdKey[type]] === executiveId) {
          selectedSeal.sealId = sealId;
        }

        //같은 도장을 다른임원이 선택되어있다면 선택을 해제함
        if (selectedSeal[ExecutiveIdKey[type]] !== executiveId && selectedSeal.sealId === sealId) {
          selectedSeal.sealId = undefined;
        }
      });

      changeEventCallback && changeEventCallback();
    };

    const selectedSealId = (executiveId: number) => {
      return SealSelectModel.selectedSeals.find((selectedSeal) => {
        return selectedSeal[ExecutiveIdKey[type]] === executiveId;
      })?.sealId;
    };

    return (
      <li>
        <label className={st.sealLabel}>
          <div className={st.imgWrap}>
            <img className={st.sealImg} src={seal.image} />
          </div>
          <div className={st.content}>
            <div className={st.info}>
              <div className={clsx(st.badge, sealType === '법인인감' && st.corp)}>
                {sealType === '사용인감' ? '온라인 사용인감' : `법인인감(${executiveName})`}
              </div>
              <div className={st.sealTitle}>{seal.alias}</div>
            </div>
            <div className={st.radioWrap}>
              <Radio
                name={`sealExecutiveId${executiveId}`}
                value={seal.id}
                checked={seal.id === selectedSealId(executiveId)}
                onChange={(e) => changeExecutiveSeal(e, executiveId)}
              ></Radio>
            </div>
          </div>
        </label>
      </li>
    );
  },
);

export default SealLabelLi;
