import NewModalModel from '@model/newModalModel';
import { Seal } from '@service/Corp/Seal/SealService.interface';
import { Pen } from '@standby/dike_ui/assets/icon/fill';
import { Button, InfoBox, Label, Radio, SelectField, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation, Modal } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import React from 'react';

import useDeleteSeal from '../useDeleteSeal';
import st from './style.module.scss';
import useSealEdit from './useSealEdit';

const SealEditModal = observer(({ seal, corpId }: { seal: Seal; corpId: string }) => {
  const { sealMob, representatives, onSubmitForm } = useSealEdit(seal, corpId);
  const { deleteSeal } = useDeleteSeal(corpId);

  return (
    <Modal
      isForm
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => onSubmitForm(e, seal.sealId)}
      title="법인인감 관리"
      size="medium"
      withIcon={false}
      buttonContents={
        <div className={st.buttonContainer}>
          <Button
            type="button"
            color="primary400"
            variant="outlined"
            size="large"
            onClick={() => deleteSeal(seal, true)}
          >
            삭제하기
          </Button>
          <Button type="submit" color="primary400" size="large">
            저장하기
          </Button>
        </div>
      }
      onClickClose={() => NewModalModel.closeModal()}
    >
      <div className={st.modalContent}>
        <div className={st.preview}>
          <div className={st.imgWrap}>
            <img src={sealMob.image} />
          </div>
          <Typography className={st.info} fontType="Body-14M">
            이미지는 수정할 수 없어요.
            <br />
            변경을 원하시면, 삭제 후 재등록 해주세요.
          </Typography>
        </div>
        <div className={st.form}>
          <div className={st.titleWrap}>
            <div className={st.icon}>
              <Pen width="16" height="16" fill={color.grayScale000} />
            </div>
            <Typography fontType="Body-16M">기본정보</Typography>
          </div>
          <Typography className={st.updatedAt} fontType="Body-14R" tag="div">
            업데이트 : {sealMob.updatedAt}
          </Typography>
          <Label asteriskMark className={st.radioLabel}>
            유형
          </Label>
          <div className={st.radioGroup}>
            <Radio
              name="stampType"
              value="AUTHORIZED"
              checked={sealMob.stampType === 'AUTHORIZED'}
              onChange={(e) => sealMob.onChange(e, 'stampType')}
            >
              사용인감
            </Radio>
            <Radio
              name="stampType"
              value="CORPORATE"
              checked={sealMob.stampType === 'CORPORATE'}
              onChange={(e) => sealMob.onChange(e, 'stampType')}
            >
              법인인감
            </Radio>
          </div>
          {sealMob.stampType === 'CORPORATE' && (
            <>
              <SelectField
                name="executiveId"
                value={sealMob.executiveId}
                className={st.executiveId}
                error={
                  <ErrorValidation
                    name="executiveId"
                    value={sealMob.executiveId}
                    errorCase={{
                      required: '대표를 입력해 주새요.',
                    }}
                  />
                }
                onChange={(e) => sealMob.onChange(e, 'executiveId')}
              >
                <option value="">-</option>
                {representatives?.length &&
                  representatives.map((representative) => (
                    <option key={representative.id} value={representative.id}>
                      {representative.name}
                    </option>
                  ))}
              </SelectField>
              <InfoBox className={st.infoBox}>법인인감은 대표마다 별도로 등록해야 해요.</InfoBox>
            </>
          )}
          <TextField
            name="alias"
            label="인감 별칭"
            labelAsteriskMark
            value={sealMob.alias}
            className={st.alias}
            maxLength={20}
            error={
              <ErrorValidation
                name="alias"
                value={sealMob.alias}
                errorCase={{
                  required: '인감 별칭을 입력해 주새요.',
                }}
              />
            }
            onChange={(e) => sealMob.onChange(e, 'alias')}
          />
        </div>
      </div>
    </Modal>
  );
});

export default SealEditModal;
