import corpModel from '@model/Corp/corpModel';
import vatModel from '@model/Corp/Vat/vatModel';
import VatService from '@service/Corp/Vat/vatService';
import { observable } from 'mobx';

class VatVm {
  constructor() {
    this.state = observable({
      loadYn: false,
    });
  }
  async load() {
    await VatService.loadGuides();
    await VatService.loadVat();
    this.state.loadYn = true;
  }
  get corpId() {
    return corpModel.id;
  }
  get guides() {
    return vatModel.guides;
  }
  get steps() {
    //타임스탬프
    return vatModel.statusOfVatReporting.steps;
  }
  get nowStep() {
    return vatModel.statusOfVatReporting.steps[vatModel.statusOfVatReporting.steps.length - 1]?.name;
  }
}

export default new VatVm();
