import type { Notification } from '@src/model/notificationModel';
import { Typography } from '@standby/dike_ui';
import color from '@standby/dike_ui/design-token/color.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from './style.module.scss';

interface GeneralNotiCardProps {
  notification: Notification;
}

const GeneralNotiCard = observer(({ notification }: GeneralNotiCardProps) => {
  return (
    <div className={clsx(st.readType, notification.readAt ? st.read : null)}>
      <Typography
        fontType="Body-14R"
        className={st.detail}
        dangerouslySetInnerHTML={{ __html: notification.content.replace(/\n/g, '<br />') }}
      />
      <Typography fontType="Detail-12M" style={{ color: color.grayScale500 }}>
        {notification.createdAt}
      </Typography>
    </div>
  );
});

export default GeneralNotiCard;
