import { observer } from 'mobx-react';
import type { ReactNode } from 'react';
import ReactDOM from 'react-dom';

type PortalProviderProps = {
  children: ReactNode;
  elementId: string;
};

export const PortalProvider = observer(({ children, elementId }: PortalProviderProps) => {
  const element = document.getElementById(elementId);

  if (!element) return null;

  return ReactDOM.createPortal(children, element);
});
 