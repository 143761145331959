import { Alert } from '@src/model/newDialogModel';
import type { Notification as INotification } from '@src/model/notificationModel';
import portalModel from '@src/model/portalModel';
import ScrollArea from '@src/pages/common/ScrollArea';
import notificationService from '@src/service/notificationService';
import Typography from '@standby/dike_ui/components/atoms/Typography';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import GeneralNotiCard from './components/GeneralNotiCard';
import InvitationCard from './components/InvitationCard';
import st from './style.module.scss';

interface NotificationProps {
  open: boolean;
  notifications: INotification[];
  onClickBackGround: () => void;
}

const Notification = observer(({ open = false, notifications, onClickBackGround }: NotificationProps) => {
  const acceptInvitation = async (callbackUrl: string, acceptYn: boolean, notificationId: number | null) => {
    await notificationService.InviteTypeAccept(callbackUrl, acceptYn, notificationId);

    await Alert(acceptYn ? '수락되었습니다.' : '거절되었습니다.');

    //노티가 안남아있으면 닫음
    if (notifications.length === 0) {
      portalModel.content = null;
    }
  };

  const invitationList = notifications.filter((notification) => notification.notificationType === 'INVITE');
  const generalList = notifications.filter((notification) => notification.notificationType === 'GENERAL');

  return (
    <div className={clsx(st.notifyWrap, open ? st.open : null)}>
      <div className={st.notifyRelative}>
        <div className={st.background} onClick={onClickBackGround}></div>
        <div className={st.notifyBox}>
          <div className={st.title}>
            <Typography fontType="Title-18M">알림</Typography>
          </div>
          <div className={st.gap} />
          {notifications.length === 0 ? (
            <div className={st.empty}>
              <Typography fontType="Body-16M">새로운 알림이 없습니다.</Typography>
            </div>
          ) : (
            <div className={st.list} style={{ height: 'calc(100% - 57px)' }}>
              <ScrollArea className={st.scrollArea} thumbWidth={8} thumbColor={'#ADAFB1'}>
                <div className={st.inside}>
                  {invitationList.map((notification, index) => (
                    <InvitationCard notification={notification} acceptInvitation={acceptInvitation} key={index} />
                  ))}
                  {generalList.map((notification, index) => (
                    <GeneralNotiCard notification={notification} key={index} />
                  ))}
                </div>
              </ScrollArea>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Notification;
