import { Typography } from '@standby/dike_ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';

const Card등록중 = observer(() => {
  return (
    <>
      <div className={st.tagContent}>
        <div className={st.tagWrap}>
          <Typography fontType="Detail-10M" className={clsx(st.tag, st.smallTag)} data-type="등록중">
            등록중
          </Typography>
        </div>
        <div className={st.text}>
          <Typography fontType="Body-14R" className={st.mainText}>
            등록을 마치고 알림을 드려요!
          </Typography>
        </div>
      </div>
      <Typography fontType="Detail-12M" className={st.subText}>
        최대 1영업일 소요
      </Typography>
    </>
  );
});

export default Card등록중;
