import { AxiosError } from 'axios';

/**
 * Sentry에서 무시되는 에러
 */
export class IgnoredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'IgnoredError';
  }
}

/**
 * Sentry에 기록되는 Axios 에러
 * Axios Error를 래핑해서 name을 변경해 Sentry에 기록되도록 한다.
 */
export class RecordedAxiosError extends AxiosError {
  constructor(error: AxiosError) {
    super(
      error.message,
      error.code,
      error.config ? { ...error.config } : undefined,
      error.request ? { ...error.request } : undefined,
      error.response ? { ...error.response } : undefined,
    );
    this.name = 'RecordedAxiosError';
  }
}
