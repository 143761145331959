import adminModel from '@model/Corp/AdminSetting/adminModel';
import corpModel from '@model/Corp/corpModel';
import { Alert, Confirm } from '@src/model/newDialogModel';
import AdminSettingService from '@src/service/Corp/AdminSetting/adminSettingService';
import type { ManagerAuthorities } from '@src/service/Corp/AdminSetting/adminSettingService.interface';
import type React from 'react';

class AdminSettingVm {
  load() {
    void AdminSettingService.load();
  }
  get id() {
    return corpModel.id;
  }
  get managers() {
    return adminModel.managers;
  }
  // 현재 사용자가 최고관리자인지
  get isGeneralManager() {
    return adminModel.authority.authorityType === 'GENERAL_MANAGER';
  }
  async changeAuthorityType(e: React.ChangeEvent<HTMLSelectElement>, index: number, userId: string | null) {
    if (this.isGeneralManager) {
      await AdminSettingService.editAuthorityType(userId ?? '', e.target.value);
      adminModel.managers[index].authorityType = e.target.value;
    } else {
      return Alert(
        '최고 관리자만 담당자 권한 변경이 가능해요.',
        '담당자 권한 변경이 필요하다면 최고 관리자에게 문의하세요.',
        '확인',
      );
    }
  }
  async deleteAuthority(userId: string | null) {
    if (this.isGeneralManager) {
      if (await Confirm('정말 삭제하나요? 삭제 후에는 해당 관리자의 접근이 차단됩니다.', '', '네', '아니오')) {
        await AdminSettingService.deleteAdmin(userId ?? '');
      }
    } else {
      return Alert(
        '최고 관리자만 다른 담당자를 삭제할 수 있어요.',
        '권한 삭제가 필요하다면 최고 관리자에게 문의하세요.',
        '확인',
      );
    }
  }
  async invitationDelete(userId: string | null) {
    await AdminSettingService.deleteInvitation(userId ?? '');
  }
  onChangeUseYn(
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string | null,
    type: keyof ManagerAuthorities,
    authorityIndex: number,
  ) {
    const targetManager = adminModel.managers.find((manager) => manager.userId === userId);

    if (!targetManager) {
      return;
    }

    targetManager.authorities[type][authorityIndex].useYn = e.target.checked;
  }
  checkAllAuthoritiesSelected(userId: string | null, type: keyof ManagerAuthorities) {
    const targetManager = adminModel.managers.find((manager) => manager.userId === userId);

    if (!targetManager) {
      return false;
    }

    return targetManager.authorities[type].every((authority) => authority.useYn);
  }
  onChangeSelectAll(e: React.ChangeEvent<HTMLInputElement>, userId: string | null, type: keyof ManagerAuthorities) {
    const targetManager = adminModel.managers.find((manager) => manager.userId === userId);

    if (!targetManager) {
      return;
    }

    targetManager.authorities[type].forEach((authority) => {
      authority.useYn = e.target.checked;
    });
  }
  save() {
    if (!this.isGeneralManager) {
      return Alert(
        '최고 관리자만 담당자 권한 변경이 가능해요.',
        '담당자 권한 변경이 필요하다면 최고 관리자에게 문의하세요.',
        '확인',
      );
    }

    let notAllowLength = false;

    adminModel.managers.forEach((manager) => {
      if (manager.authorityType !== 'GENERAL_MANAGER') {
        let checkedItem = 0;

        for (const authoritiesType in manager.authorities) {
          manager.authorities[authoritiesType as keyof ManagerAuthorities].forEach((authority) => {
            checkedItem += authority.useYn ? 1 : 0;
          });
        }

        if (checkedItem === 0) {
          notAllowLength = true;
        }
      }
    });

    if (notAllowLength) {
      void Alert('담당자는 최소한 1개의 권한을 가지고 있어야 해요.', '권한을 추가한 후 저장버튼을 누르세요.', '확인');
    } else {
      void AdminSettingService.save();
    }
  }
}

export default new AdminSettingVm();
