import agendaModel from '@model/Corp/Agenda/agendaModel';
import meetingsStepModel from '@model/Corp/Agenda/Step/meetingsStepModel';
import corpModel from '@model/Corp/corpModel';
import { _alert, _confirm } from '@model/dialogModel';
import { pageRouter } from '@model/pageModel';
import meetingsService from '@service/Corp/Agenda/Step/meetingsService';
import { observable } from 'mobx';

interface State {
  opened: number;
}

class MeetingsVm {
  state: State = observable({
    opened: -1,
  });
  async load(agendaProgressId: string) {
    await meetingsService.load(agendaProgressId);
    this.openFirst();
  }
  get corpId() {
    return corpModel.id;
  }
  get title() {
    return meetingsStepModel.title;
  }
  get hasCapitalOverBillionYn() {
    //자본금 10억 이상 여부
    return meetingsStepModel.hasCapitalOverBillionYn;
  }
  get tabList() {
    return meetingsStepModel.tabList;
  }
  changeOpened(idx: number) {
    if (this.state.opened === -1 || this.tabList[this.state.opened].savedDataYn === false) {
      this.tabList[idx].savedYn = !this.tabList[idx].savedYn;
      this.state.opened = idx;
    } else {
      void _alert('앗! 현재 수정 중인 안건에서 저장버튼을 먼저 눌러주세요.', '안건 2개를 동시에 수정할 수 없어요. ');
    }
  }
  openFirst() {
    let isSelected = false;

    this.tabList.forEach((tab, idx) => {
      if ((tab.savedDataYn === false || tab.savedYn === false) && isSelected === false) {
        this.state.opened = idx;
        isSelected = true;
      }
    });

    if (isSelected === false) {
      this.state.opened = -1;
    }
  }
  async nextStep(agendaProgressId: string) {
    await meetingsService.nextStep(agendaProgressId);
  }
  get isAllSaved() {
    return !this.tabList.some((tabList) => {
      return tabList.savedYn === false;
    });
  }
  get registrationRequiredYn() {
    return agendaModel.registrationRequiredYn;
  }
  async prevPage(agendaProgressId: string) {
    if (
      await _confirm(
        '안건입력을 다시 할까요?',
        '안건입력을 다시 하면, 날짜, 방식은 다시 선택해야 해요.',
        '네',
        '아니오',
      )
    ) {
      pageRouter.replace(`/corp/agenda/step/AGENDA/?corp=${this.corpId}&agendaProgressId=${agendaProgressId}`);
    }
  }
}

export default new MeetingsVm();
