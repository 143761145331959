import { Pattern, Rect, StaticCanvas } from 'fabric';

/**
 *
 * fabricjs를 사용해서 체크무늬 배경을 만드는 모듈
 *
 */

const defaultRectSize = 10;
const rectColors = ['#E6E6E6', '#F9F9F9'];

const getColor = (x, y) => {
  if (y % 2 === 0) {
    return x % 2 === 0 ? rectColors[0] : rectColors[1];
  }

  return x % 2 === 0 ? rectColors[1] : rectColors[0];
};

const createRect = ({ rectSize = defaultRectSize, color, left, top }) => {
  return new Rect({
    left,
    top,
    width: rectSize,
    height: rectSize,
    fill: color,
    backgroundColor: color,
    borderColor: 'transparent',
    stroke: 'transparent',
    strokeWidth: 0,
    selectable: false,
    hasControls: false,
    hasBorders: false,
  });
};

const createPattern = (rectSize = defaultRectSize) => {
  const patternSourceCanvas = new StaticCanvas();

  patternSourceCanvas.setDimensions({
    width: rectSize * 2,
    height: rectSize * 2,
  });

  for (let x = 0; x < 2; x++) {
    for (let y = 0; y < 2; y++) {
      patternSourceCanvas.add(
        createRect({
          color: getColor(x, y),
          left: x * rectSize,
          top: y * rectSize,
          rectSize,
        }),
      );
    }
  }

  patternSourceCanvas.renderAll();

  const pattern = new Pattern({
    source: patternSourceCanvas.getElement(),
    repeat: 'repeat',
  });

  return pattern;
};

/**
 * @typedef {Object} BackgroundOptions
 * @property {number} width
 * @property {number} height
 * @property {number} [checkSize]
 * @property {{top: number, left: number}} [offset]
 */

/**
 *
 * @param {BackgroundOptions} param
 * @returns
 */
const createBackground = ({ width, height, checkerSize = defaultRectSize, offset }) => {
  offset = {
    top: offset?.top ?? Math.ceil(checkerSize * 1.25) * -1,
    left: offset?.left ?? 0,
  };

  return new Rect({
    left: offset.left,
    top: offset.top,
    width: width + Math.abs(offset.left * 2),
    height: height + Math.abs(offset.top * 2),
    fill: createPattern(checkerSize),
    selectable: false,
    hasBorders: false,
    backgroundColor: 'transparent',
  });
};

export { createBackground };
