import { GuideBox, UnorderedList } from '@standby/dike_ui';

import st from './style.module.scss';

const Guide = () => {
  return (
    <GuideBox title='인감 이미지 업로드 TIP' style={{ width: '100%' }} className={st.guide} defaultOpen>
      <UnorderedList>
        <li>
          하얀 종이에 찍으신 인감을 확대하여 업로드 하시면, 더욱 선명하게 사용할 수 있어요.
        </li>
        <img src="/images/Img_인감이미지업로드TIP.png" />
        <li>
          대표가 여러 명이라면, 각 대표의 법인인감은 다른 것을 써야해서 비표라도 다른 인감이어야해요.
        </li>
      </UnorderedList>
    </GuideBox>
  );
};

export default Guide;
