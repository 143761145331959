import { downloadFileGet, get } from '@common/module/httpRequest';
import corpModel from '@model/Corp/corpModel';
import vatModel from '@model/Corp/Vat/vatModel';

import type { CorpVatRs, SelectedVatRs, VatGuideRs } from './vatService.interface';

class VatService {
  async loadGuides(): Promise<void> {
    const res = await get<VatGuideRs>('/vat/home');

    vatModel.setGuides(res.data);
  }
  async loadVat(): Promise<void> {
    const res = await get<CorpVatRs>(`/company/${corpModel.id}/vat`);

    vatModel.setVat(res.data);
  }
  async vat(vatId: string): Promise<void> {
    const res = await get<SelectedVatRs>(`/company/${corpModel.id}/vat/${vatId}`);

    vatModel.setSelectVat(res.data);
  }
  async fileDownload(downloadLink: string): Promise<void> {
    await downloadFileGet(downloadLink);
  }
}

export default new VatService();
