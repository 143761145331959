import regExp from '@src/common/module/regExp';
import PageMainTitle from '@src/pages/common/PageMainTitle';
import SectionTitle from '@src/pages/common/SectionTitle';
import { Button, CheckBox, ErrorValidation, Label, Radio, Table, TextField } from '@standby/dike_ui';
import { PeopleAdmin } from '@standby/dike_ui/assets/icon/fill';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import vm from './AdminAddVm';
import st from './style.module.scss';

const AdminAdd = observer(() => {
  useEffect(() => {
    vm.load();
  }, []);

  return (
    <div className={st.container}>
      <form onSubmit={(e) => vm.submit(e)}>
        <PageMainTitle title="담당자 추가·변경" icon={<PeopleAdmin width={24} height={24} fill={color.primary800} />} />
        <div className={st.gap24} />
        <SectionTitle>담당자 추가·초대하기</SectionTitle>
        <div className={st.gap7} />
        <div className={st.box}>
          <TextField
            className={st.input}
            label="이름"
            labelAsteriskMark={true}
            placeholder="이름"
            value={vm.addData.name}
            onChange={(e) => vm.changeName(e)}
            name="name"
            error={
              <ErrorValidation
                name="name"
                errorCase={{
                  required: '이름을 입력해주세요.',
                  pattern: {
                    value: regExp.onlyKorEng().test(vm.addData.name),
                    message: '한글 또는 영문으로 입력해주세요.',
                  },
                }}
                value={vm.addData.name}
              />
            }
          />
          <TextField
            className={st.input}
            label="이메일"
            labelAsteriskMark={true}
            placeholder="admin01@example.com"
            value={vm.addData.email}
            onChange={(e) => vm.changeEmail(e)}
            name="email"
            error={
              <ErrorValidation
                name="email"
                errorCase={{
                  required: '이메일을 입력해주세요.',
                  pattern: {
                    value: regExp.email().test(vm.addData.email),
                    message: '올바른 이메일 형식으로 입력해주세요.',
                  },
                }}
                value={vm.addData.email}
              />
            }
          />
          <TextField
            className={st.input}
            label="역할(선택)"
            placeholder="역할"
            value={vm.addData.role}
            onChange={(e) => vm.changeRole(e)}
            name="role"
          />
          <div className={st.authority}>
            <Label asteriskMark>권한</Label>
            <div className={st.radioGroup}>
              <Radio
                value="MANAGER"
                checked={vm.addData.authorityType === 'MANAGER'}
                onChange={(e) => vm.changeAuthorityType(e)}
                name="authorityType"
              >
                담당자
              </Radio>
              <Radio
                value="GENERAL_MANAGER"
                checked={vm.addData.authorityType === 'GENERAL_MANAGER'}
                onChange={(e) => vm.changeAuthorityType(e)}
                name="authorityType"
              >
                최고관리자
              </Radio>
            </div>
            <Table className={st.table} columnDefinition={[{ width: '160px' }, {}]}>
              <Table.Body>
                <Table.Row>
                  <Table.HeadCell>법무 기능</Table.HeadCell>
                  <Table.Cell>
                    <div className={st.checkboxContainer}>
                      <CheckBox
                        size="small"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                        checked={vm.checkAllAuthoritiesSelected('law')}
                        onChange={(e) => vm.onChangeSelectAllAuthorities(e, 'law')}
                        id="law"
                      >
                        전체
                      </CheckBox>
                      {vm.addData.authorities.law.map((authority, index) => (
                        <CheckBox
                          size="small"
                          key={index}
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'law', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeadCell>회계 기능</Table.HeadCell>
                  <Table.Cell>
                    <div className={st.checkboxContainer}>
                      <CheckBox
                        size="small"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                        checked={vm.checkAllAuthoritiesSelected('acct')}
                        onChange={(e) => vm.onChangeSelectAllAuthorities(e, 'acct')}
                        id="acct"
                      >
                        전체
                      </CheckBox>
                      {vm.addData.authorities.acct.map((authority, index) => (
                        <CheckBox
                          size="small"
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                          key={index}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'acct', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.HeadCell>제휴 기능</Table.HeadCell>
                  <Table.Cell>
                    <div className={st.checkboxContainer}>
                      <CheckBox
                        size="small"
                        disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                        checked={vm.checkAllAuthoritiesSelected('partner')}
                        onChange={(e) => vm.onChangeSelectAllAuthorities(e, 'partner')}
                        id="partner"
                      >
                        전체
                      </CheckBox>
                      {vm.addData.authorities.partner.map((authority, index) => (
                        <CheckBox
                          size="small"
                          disabled={vm.addData.authorityType === 'GENERAL_MANAGER'}
                          key={index}
                          checked={authority.useYn}
                          onChange={(e) => vm.changeUseYn(e, 'partner', index)}
                        >
                          {authority.menuName}
                        </CheckBox>
                      ))}
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
          <div className={st.btnArea}>
            <Button size="large" type="submit" color="primary400" className={st.submit}>
              추가·초대하기
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
});

export default AdminAdd;
