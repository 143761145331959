import { stampTypeToName } from '@src/pages/Corp/Seal/constant';
import { type RepresentativeInfo, StampEnum } from '@src/service/Corp/Seal/SealService.interface';
import { InfoBox, Radio, SelectField, TextField, Typography } from '@standby/dike_ui/components/atoms';
import { ErrorValidation } from '@standby/dike_ui/components/molecules';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';

import type { SealMob } from '../../useCreateSeal';
import st from './style.module.scss';

interface Props {
  sealMob: SealMob;
  representatives: RepresentativeInfo[];
}

const OptionSelectBox = observer((props: Props) => {
  const { sealMob, representatives } = props;

  return (
    <div className={st.box}>
      <div className={st.type}>
        <Typography color={color.grayScale800} fontType="Body-16M">
          유형
        </Typography>
        <div className={st.radioWrap}>
          {(Object.keys(StampEnum) as (keyof typeof StampEnum)[]).map((type) => (
            <Radio
              key={type}
              name="stamp_type"
              data-name="stamp_type"
              value={type}
              checked={sealMob.stampType === type}
              onChange={(e) => {
                if (e.target.checked) sealMob.stampType = type;
              }}
            >
              {stampTypeToName[type]}
            </Radio>
          ))}
        </div>
        <div style={{ marginTop: '4px' }}>
          <ErrorValidation
            name="stamp_type"
            value={sealMob.stampType || ''}
            errorCase={{
              required: '인감 유형을 선택해주세요.',
            }}
          />
        </div>
        {sealMob.stampType === StampEnum.CORPORATE && (
          <div className={st.executiveWrap}>
            <SelectField
              name="executive_id"
              className={st.select}
              onChange={(e) => (sealMob.executiveId = Number(e.target.value))}
              value={sealMob.executiveId ?? ''}
              error={
                <ErrorValidation
                  name="executive_id"
                  value={sealMob.executiveId ? String(sealMob.executiveId) : ''}
                  errorCase={{
                    required: '대표이사를 선택해주세요.',
                  }}
                />
              }
            >
              {(representatives.length > 1 && sealMob.executiveId === null) && (
                <option value="" disabled hidden>
                  대표이사 선택
                </option>
              )}
              {representatives.map((representative) => (
                <option key={representative.id} value={representative.id} disabled={representative.isDisable}>
                  {`${representative.name}${representative.isDisable ? ' (이미 등록됨)' : ''}`}
                </option>
              ))}
            </SelectField>
            <InfoBox className={st.info}>법인인감은 대표마다 별도로 등록해야 해요.</InfoBox>
          </div>
        )}
      </div>
      <div className={st.divider} />
      <div className={st.alias}>
        <Typography color={color.grayScale800} fontType="Body-16M">
          인감 별칭
        </Typography>
        <TextField
          name="alias"
          placeholder="인감 별칭"
          className={st.input}
          value={sealMob.alias}
          onChange={(e) => sealMob.setAlias(e.target.value)}
          maxLength={20}
          error={
            <ErrorValidation
              name="alias"
              value={sealMob.alias}
              errorCase={{
                required: '별칭을 입력해 주세요.',
              }}
            />
          }
        />
      </div>
    </div>
  );
});

export default OptionSelectBox;
