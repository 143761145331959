export const downloadSrc = (src, filename = 'cropped.png') => {
  const link = document.createElement('a');

  link.href = src;
  link.download = filename;
  link.click();
};

/**
 *
 * 주어진 범위에 맞게 value를 변환합니다.
 *
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns
 */
export const normalize = (value, min, max) => {
  if (value <= min) return min;
  if (value >= max) return max;

  return value;
};

export const createNewCanvas = ({ width, height } = {}) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (width) canvas.width = width;
  if (height) canvas.height = height;

  return { canvas, ctx };
};

/**
 * reset an object transform state to neutral except rotation.
 * @param  {FabricObject} target object to transform
 */
export const resetObjectTransform = (target) => {
  target.scaleX = 1;
  target.scaleY = 1;
  target.skewX = 0;
  target.skewY = 0;
  target.flipX = false;
  target.flipY = false;
};

/**
 *
 * 주어진 이미지 객체의 실제 크기(픽셀 단위)를 계산해서, cropArea 객체가 위치한 픽셀 좌표 범위를 반환합니다.
 *
 * @param {FabricObject} imageObj
 * @param {FabricObject} cropArea
 * @returns {{cropMinX: number, cropMaxX: number, cropMinY: number, cropMaxY: number}}
 */
export const calculateCropRangeInPixel = (imageObj, cropArea) => {
  /**
   * 1. 각도에 따라 변화하는 실제 이미지 크기를 계산한다.
   */
  const originalWidth = imageObj.width,
    originalHeight = imageObj.height;

  const angle = imageObj.angle;

  const degreeToRadian = (degree) => degree * (Math.PI / 180);

  const realWidth = Math.ceil(
    Math.abs(Math.sin(degreeToRadian(90) - degreeToRadian(angle))) * originalWidth +
      Math.abs(Math.sin(degreeToRadian(angle))) * originalHeight,
  );
  const realHeight = Math.ceil(
    Math.abs(Math.sin(degreeToRadian(angle))) * originalWidth +
      Math.abs(Math.sin(degreeToRadian(90) - degreeToRadian(angle))) * originalHeight,
  );

  /**
   * 2. 이미지 캔버스 객체의 left와 top은 첫 삽입시 왼쪽 위 꼭지점 기준이라 rotation에 따라 부정확해짐으로,
   *    각도에 따른 정확한 left, top을 계산한다.
   */

  const dimension = Math.floor(angle / 90);
  const innerAngle = angle % 90;

  const scaledOriginalWidth = originalWidth * imageObj.scaleX,
    scaledOriginalHeight = originalHeight * imageObj.scaleY;

  let realTop, realLeft;

  switch (dimension) {
    case 0:
      realLeft = imageObj.left - Math.sin(degreeToRadian(innerAngle)) * scaledOriginalHeight;
      realTop = imageObj.top;
      break;
    case 1:
      realLeft = imageObj.left - realWidth * imageObj.scaleX;
      realTop = imageObj.top - Math.sin(degreeToRadian(innerAngle)) * scaledOriginalHeight;
      break;
    case 2:
      realLeft =
        imageObj.left -
        Math.sin(degreeToRadian(90) - degreeToRadian(innerAngle)) * scaledOriginalWidth;
      realTop = imageObj.top - realHeight * imageObj.scaleY;
      break;
    case 3:
      realLeft = imageObj.left;
      realTop =
        imageObj.top -
        Math.sin(degreeToRadian(90) - degreeToRadian(innerAngle)) * scaledOriginalWidth;
      break;
  }

  /**
   * 3. 이미지 캔버스 객체의 실제 left,top 과 cropArea의 left,top 을 사용해,
   *    최종 이미지 픽셀 기준으로 잘라내야하는 위치를 계산한다.
   */

  const cropLeft = Math.abs(cropArea.left - realLeft),
    cropTop = Math.abs(cropArea.top - realTop);

  const cropTargetArea = {
    cropMinX: Math.floor(cropLeft / imageObj.scaleX),
    cropMaxX: Math.ceil((cropLeft + cropArea.width) / imageObj.scaleX),
    cropMinY: Math.floor(cropTop / imageObj.scaleY),
    cropMaxY: Math.ceil((cropTop + cropArea.height) / imageObj.scaleY),
  };

  return cropTargetArea;
};
