import { useObserver } from 'mobx-react';
import { Route, Routes } from 'react-router-dom';

import CreateSeal from './components/CreateSeal';
import Home from './components/Home';

function Seal() {
  return useObserver(() => (
    <Routes>
      {/* 법인인감 */}
      <Route path="/" element={<Home />}></Route>
      {/* 법인등록 */}
      <Route path="/create" element={<CreateSeal />}></Route>
    </Routes>
  ));
}

export default Seal;
