import Nukki from '@src/common/module/Nukki';
import { observer } from 'mobx-react';
import React from 'react';

import FileUploadBox from './components/FileUploadBox';
import SealNukkiCanvas from './components/SealNukkiCanvas';

interface Props {
  type?: 'nukki' | 'upload'
  nukki: Nukki;
  imageFile: File | null;
  onUploadImageFile(e: React.ChangeEvent<HTMLInputElement>): void
  onDropImageFile(e: React.DragEvent<HTMLDivElement>): void
  onDeleteImageFile(): void
}

const SealNukkiContainer = observer((props: Props) => {
  const { type = 'upload', nukki, imageFile, onUploadImageFile, onDeleteImageFile, onDropImageFile } = props;

  return type === 'nukki' ?
    <SealNukkiCanvas
      nukki={nukki}
      imageFile={imageFile}
      onDeleteImageFile={onDeleteImageFile}
    />
    : <FileUploadBox
      onChange={onUploadImageFile}
      onDropImageFile={onDropImageFile}
    />
});

export default SealNukkiContainer;

