import { makeObservable, observable, runInAction, when } from 'mobx';
import type { ReactNode } from 'react';

const NEW_DIALOG_STATE = {
  type: undefined,
  withIcon: true,
  title: undefined,
  contents: undefined,
  onClickButton: undefined,
  onClickConfirmButton: undefined,
  onClickCancelButton: undefined,
  isConfirm: undefined,
  buttonText: '확인',
  confirmButtonText: '확인',
  cancelButtonText: '취소',
};

class NewDialogModel {
  type?: 'alert' | 'confirm' = undefined;
  withIcon = true;
  title?: string = undefined;
  contents?: ReactNode = undefined;
  onClickButton?: () => void = undefined;
  onClickConfirmButton?: () => void = undefined;
  onClickCancelButton?: () => void = undefined;
  isConfirm?: boolean = undefined;
  buttonText = '확인';
  confirmButtonText = '확인';
  cancelButtonText = '취소';

  constructor() {
    makeObservable(this, {
      type: observable,
      withIcon: observable,
      title: observable,
      contents: observable,
      onClickButton: observable,
      onClickConfirmButton: observable,
      onClickCancelButton: observable,
      buttonText: observable,
      confirmButtonText: observable,
      cancelButtonText: observable,
      resetState: observable,
      isConfirm: observable,
    });
  }

  resetState() {
    Object.assign(this, NEW_DIALOG_STATE);
  }
}

const newDialogModel = new NewDialogModel();

const closeDialog = () => {
  runInAction(() => {
    newDialogModel.resetState();
  });
};

const onClickConfirmButton = () => {
  runInAction(() => {
    newDialogModel.resetState();
    newDialogModel.isConfirm = true;
  });
};
const onClickCancelButton = () => {
  runInAction(() => {
    newDialogModel.resetState();
    newDialogModel.isConfirm = false;
  });
};

const Alert = async (title: string, contents: ReactNode = '', buttonText = '확인', withIcon = true) => {
  runInAction(() => {
    newDialogModel.resetState();

    newDialogModel.type = 'alert';
    newDialogModel.withIcon = withIcon;

    newDialogModel.title = title;
    newDialogModel.contents = contents;
    newDialogModel.onClickButton = closeDialog;
    newDialogModel.buttonText = buttonText;
  });
  await when(() => !newDialogModel.type);
};

const Confirm = async (
  title: string,
  contents: ReactNode,
  confirmButtonText = '확인',
  cancelButtonText = '취소',
  withIcon = true,
) => {
  runInAction(() => {
    newDialogModel.resetState();

    newDialogModel.type = 'confirm';
    newDialogModel.withIcon = withIcon;

    newDialogModel.title = title;
    newDialogModel.contents = contents;
    newDialogModel.onClickConfirmButton = onClickConfirmButton;
    newDialogModel.onClickCancelButton = onClickCancelButton;
    newDialogModel.confirmButtonText = confirmButtonText;
    newDialogModel.cancelButtonText = cancelButtonText;
  });
  await when(() => !newDialogModel.type);

  return newDialogModel.isConfirm;
};

export { Alert, closeDialog, Confirm, newDialogModel };
