import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import corpModel from '@model/Corp/corpModel';
import SealSelectModel from '@model/Corp/Document/SealSelectModel';
import shareholderListModel from '@model/Corp/Stock/ShareholderList/shareholderListModel';
import userModel from '@model/userModel';
import shareholderListService from '@service/Corp/Stock/ShareholderList/shareholderListService';
import { observable, runInAction } from 'mobx';

class DownloadVm {
  constructor() {
    this.state = observable({
      createdAt: userModel.date,
      fontSize: 'MEDIUM',
      lineSpacing: 'MEDIUM',
      showShareholderName: 'ALL_SHOW',
      showShareholderUniqueInfo: 'NO_SHOW',
      corpUniqueInfo: 'UNIQUE_NUMBER',
      showAddress: 'NO_SHOW',
      // 취득 일자 & 취득 원인
      showAcquisitionInfo: 'NO_SHOW',
      minorityShareholder: '',
      loadingYn: false,
      loadSelectionYn: false,
    });
  }
  get corpId() {
    return corpModel.id;
  }
  get minDate() {
    return shareholderListModel.minDate;
  }
  get param() {
    const tmpList = shareholderListModel.shareholders.filter((elem) => elem.address || elem.uniqueNumber);
    const shareholderUpdateInfoList = [];

    tmpList.forEach((item) => {
      shareholderUpdateInfoList.push({
        id: item.id,
        uniqueNumber: item.uniqueNumber || '',
        address: item.address || '',
      });
    });

    const param = {
      createdAt: this.state.createdAt,
      fontSize: this.state.fontSize,
      lineSpacing: this.state.lineSpacing,
      showShareholderName: this.state.showShareholderName,
      showShareholderUniqueInfo: this.state.showShareholderUniqueInfo,
      corpUniqueInfo: this.state.corpUniqueInfo,
      showAddress: this.state.showAddress,
      showAcquisitionInfo: this.state.showAcquisitionInfo,
      minorityShareholder: this.state.minorityShareholder || 0,
      shareholderUpdateInfoList: shareholderUpdateInfoList,
      seals: SealSelectModel.selectedSeals,
    };

    return param;
  }
  async load() {
    await shareholderListService.loadSelectionInfo(userModel.date);
    this.state.loadSelectionYn = true;
    void this.requestPreview();
  }
  async submit(e, next, setSendParams) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    }
    if (e.nativeEvent?.submitter?.name === 'excelDownload') {
      try {
        this.state.loadingYn = true;
        await shareholderListService.excelDownload(this.param);
        this.state.loadingYn = false;
      } catch (err) {
        this.state.loadingYn = false;
      }
    } else if (e.nativeEvent?.submitter?.name === 'pdfDownload') {
      try {
        this.state.loadingYn = true;
        await shareholderListService.pdfDownload(this.param);
        this.state.loadingYn = false;
      } catch (err) {
        this.state.loadingYn = false;
      }
    } else if (e.nativeEvent?.submitter?.name === 'sendEmail') {
      setSendParams(this.param);
      next();
    }
  }
  async requestPreview() {
    await shareholderListService.preview(this.param);
  }
  get previewLink() {
    return shareholderListModel.previewLink;
  }
  async setCreatedAt(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    this.state.createdAt = date;
    await shareholderListService.loadSelectionInfo(this.state.createdAt);
    void this.requestPreview();
  }
  setFontSize(e) {
    this.state.fontSize = e.target.value;
    void this.requestPreview();
  }
  setLineSpacing(e) {
    this.state.lineSpacing = e.target.value;
    void this.requestPreview();
  }
  setShowShareholderName(e) {
    this.state.showShareholderName = e.target.value;
    void this.requestPreview();
  }
  setShowShareholderUniqueInfo(e) {
    this.state.showShareholderUniqueInfo = e.target.value;
    void this.requestPreview();
  }
  displayCorpUniqueInfo() {
    let displayYn = false;

    shareholderListModel.shareholders.forEach((item) => {
      if (
        this.state.showShareholderUniqueInfo !== 'NO_SHOW' &&
        (item.shareholderType === '국내법인' || item.shareholderType === '펀드/투자조합')
      ) {
        displayYn = true;
      }
    });

    return displayYn;
  }
  setCorpUniqueInfo(e) {
    shareholderListModel.shareholders.forEach((item, idx) => {
      if (item.shareholderType === '국내법인' || item.shareholderType === '펀드/투자조합') {
        if (this.state.corpUniqueInfo !== e.target.value) {
          runInAction(() => {
            shareholderListModel.shareholders[idx].uniqueNumber = '';
          });
        }
      }
    });
    this.state.corpUniqueInfo = e.target.value;
    void this.requestPreview();
  }
  displayUniqueInfoInput() {
    let cnt = 0;

    if (this.state.showShareholderUniqueInfo !== 'NO_SHOW') {
      shareholderListModel.shareholders.forEach((item) => {
        if (item.shareholderType === '국내법인' || item.shareholderType === '펀드/투자조합') {
          if (this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && item.existsUniqueInfoYn === false) {
            cnt++;
          } else if (
            this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' &&
            item.existBusinessRegistrationNumberYn === false
          ) {
            cnt++;
          }
        } else {
          if (item.existsUniqueInfoYn === false) {
            cnt++;
          }
        }
      });
    }
    if (cnt > 0 && this.state.showShareholderUniqueInfo !== 'NO_SHOW') {
      return true;
    }

    return false;
  }
  get shareholders() {
    return shareholderListModel.shareholders;
  }
  uniqueNumberType(idx) {
    const shareholder = shareholderListModel.shareholders[idx];

    if (shareholder.shareholderType === '국내성인' || shareholder.shareholderType === '국내미성년자') {
      return {
        type: '주민등록번호',
        placeholder: '111111-1111111',
        kssn: true,
        ein: false,
        validate: {
          func: regExp.kssn(shareholder.uniqueNumber),
          message: '올바른 주민등록번호를 입력해 주세요.',
        },
      };
    } else if (shareholder.shareholderType === '국내법인') {
      if (this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && shareholder.existsUniqueInfoYn === false) {
        return {
          type: '법인등록번호',
          placeholder: '111111-1111111',
          kssn: true,
          ein: false,
          validate: {
            func: regExp.cssn().test(shareholder.uniqueNumber),
            message: '올바른 법인등록번호를 입력해 주세요.',
          },
        };
      } else if (
        this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' &&
        shareholder.existBusinessRegistrationNumberYn === false
      ) {
        return {
          type: '사업자등록번호',
          placeholder: '111-11-11111',
          kssn: false,
          ein: true,
          validate: {
            func: regExp.ein().test(shareholder.uniqueNumber),
            message: '올바른 사업자등록번호를 입력해 주세요.',
          },
        };
      }
    } else if (shareholder.shareholderType === '펀드/투자조합') {
      if (this.state.corpUniqueInfo === 'UNIQUE_NUMBER' && shareholder.existsUniqueInfoYn === false) {
        return {
          type: '고유번호',
          placeholder: '111-11-11111',
          kssn: false,
          ein: true,
          validate: {
            func: regExp.ein().test(shareholder.uniqueNumber),
            message: '올바른 고유번호를 입력해 주세요.',
          },
        };
      } else if (
        this.state.corpUniqueInfo === 'BUSINESS_REGISTRATION_NUMBER' &&
        shareholder.existBusinessRegistrationNumberYn === false
      ) {
        return {
          type: '사업자등록번호',
          placeholder: '111-11-11111',
          kssn: false,
          ein: true,
          validate: {
            func: regExp.ein().test(shareholder.uniqueNumber),
            message: '올바른 사업자등록번호를 입력해 주세요.',
          },
        };
      }
    } else if (shareholder.shareholderType === '해외성인' || shareholder.shareholderType === '해외미성년') {
      return {
        type: '생년월일',
        placeholder: '0000. 00. 00.',
        kssn: false,
        ein: false,
        validate: {},
      };
    } else if (shareholder.shareholderType === '해외법인') {
      return {
        type: '설립년월일',
        placeholder: '0000. 00. 00.',
        kssn: false,
        ein: false,
        validate: {},
      };
    }
  }
  shareholderUniqueNumber(idx) {
    return shareholderListModel.shareholders[idx].uniqueNumber;
  }
  setShareholderUniqueNumber(e, idx) {
    runInAction(() => {
      shareholderListModel.shareholders[idx].uniqueNumber = e.target.value;
    });
  }
  setBlur() {
    void this.requestPreview();
  }
  setShareholderUniqueNumberBirth(e, idx) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    shareholderListModel.shareholders[idx].uniqueNumber = date;
    void this.requestPreview();
  }
  setShowAddress(e) {
    this.state.showAddress = e.target.value;
    void this.requestPreview();
  }
  displayAddressInfoInput() {
    let cnt = 0;

    shareholderListModel.shareholders.forEach((item) => {
      if (item.existsAddressYn === false) {
        cnt++;
      }
    });
    if (cnt > 0 && this.state.showAddress === 'ALL_SHOW') {
      return true;
    }

    return false;
  }
  setShowAcquisitionInfo(e) {
    this.state.showAcquisitionInfo = e.target.value;
    void this.requestPreview();
  }
  shareholderAddress(idx) {
    return shareholderListModel.shareholders[idx].address;
  }
  setShareholderAddress(e, idx) {
    runInAction(() => {
      shareholderListModel.shareholders[idx].address = e.target.value;
    });
  }
  setMinorityShareholder(e) {
    this.state.minorityShareholder = e.target.value;
  }
}

export default DownloadVm;
