import { Button, Return, Typography } from '@standby/dike_ui';
import color from '@standby/dike_ui/design-token/color.module.scss';
import { observer } from 'mobx-react';
import React from 'react';

import st from './style.module.scss';

interface ImageControlProps {
  onClickButton: () => void;
  onChangeSlider: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageControl = observer(({ onClickButton, onChangeSlider }: ImageControlProps) => {
  return (
    <div className={st.container}>
      <Typography fontType='Body-14R' className={st.label}>약한 대비</Typography>
      <input
        type="range"
        className={st.contrastSlider}
        min="0"
        max="100"
        onChange={onChangeSlider}
      />
      <Typography fontType='Body-14R' className={st.label}>강한 대비</Typography>
      <Button
        type='button'
        variant='outlined'
        color={'grayScale400'}
        className={st.rotateButton}
        onClick={onClickButton}
      >
        <Return fill={color.grayScale700} width="16" height="16" />
        이미지 회전
      </Button>
    </div>
  );
});

export default ImageControl;
