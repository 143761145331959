import { CompanyInfo } from '@model/userDashboardModel';
import { Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';

import st from '../../UserDashboard.module.scss';
import vm from '../../UserDashboardVm';

const Card청산 = observer((props: { company: CompanyInfo }) => {
  return (
    <>
      <div className={st.tagContent}>
        <div className={st.tagWrap}>
          <Typography fontType="Detail-12M" className={st.tag} data-type="청산">
            청산
          </Typography>
        </div>
        <div className={st.text}>
          <Typography fontType="Body-14R" className={st.mainText}>
            {vm.representative(props.company.executiveMembers).name} 청산인{' '}
            {vm.representative(props.company.executiveMembers).count > 1
              ? `+ ${vm.representative(props.company.executiveMembers).count - 1}`
              : ''}
          </Typography>
        </div>
      </div>
      <Typography fontType="Detail-12M" className={st.subText}>
        {vm.minAddress(props.company.address)}
      </Typography>
    </>
  );
});

export default Card청산;
