import { debounce } from '@src/common/module/function';
import Nukki from '@src/common/module/Nukki';
import { FileUploadList, Typography } from '@standby/dike_ui';
import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useRef } from 'react';

import ImageControl from '../ImageControl';
import st from './style.module.scss'

interface Props {
  nukki: Nukki;
  imageFile: File | null;
  onDeleteImageFile(): void
}

const SealNukkiCanvas = observer((props: Props) => {
  const { nukki, imageFile, onDeleteImageFile } = props;

  const canvasRef = useRef(null);
  const angle = useRef(0);

  const onChangeSlider = useCallback(debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) return;

    nukki.contrastImage((Number(e.target.value) / 50) - 1);
  }, { wait: 500 }), [nukki]);

  const onClickButton = useCallback(() => {
    angle.current += 22.5;

    nukki.rotateImage(angle.current);
  }, [nukki]);

  useEffect(() => {
    if (canvasRef.current) {
      nukki.init(canvasRef.current, {
        width: 688,
        height: 456,
        backgroundCheckerSize: 20,
      });
    }
  }, [canvasRef.current]);

  return (
    <div className={st.container}>
      <FileUploadList>
        <FileUploadList.Item fileName={imageFile?.name ?? ''} onClickClose={onDeleteImageFile} />
      </FileUploadList>
      <div>
        <div className={st.header}>
          <Typography className={st.header} fontType='Body-14M'>도장이 붉은원 안에 가득차게 박스를 조정해 주세요.</Typography>
        </div>
        <canvas className={st.canvas} ref={canvasRef} />
      </div>
      <ImageControl onChangeSlider={onChangeSlider} onClickButton={onClickButton} />
    </div>
  );
});

export default SealNukkiCanvas;
