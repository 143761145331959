import { _delete, get, post, put } from '@common/module/httpRequest';
import adminModel from '@model/Corp/AdminSetting/adminModel';
import corpModel from '@model/Corp/corpModel';
import { Alert } from '@src/model/newDialogModel';

import type { Manager } from './adminSettingService.interface';

class AdminSettingService {
  async load() {
    const res = await get<{ managers: Manager[] }>(`/userAuthority/company/${corpModel.id}`);

    adminModel.setManagers(res.data?.managers || []);
  }
  async editRole(userId: string, role: string) {
    await put(`/userAuthority/company/${corpModel.id}/user/${userId}/role`, {
      role,
    });

    await Alert('수정되었어요.');

    void this.load();
  }
  async editAuthorityType(userId: string, authorityType: string) {
    await put(`/userAuthority/company/${corpModel.id}/user/${userId}/authorityType`, {
      authorityType,
    });

    await Alert('수정되었어요.');

    void this.load();
  }
  async inviteAdmin(param: object) {
    await post(`/userAuthority/company/${corpModel.id}/invite`, param);
  }
  async deleteAdmin(userId: string) {
    await _delete(`/userAuthority/company/${corpModel.id}/user/${userId}`);

    await Alert('삭제되었어요.');

    void this.load();
  }
  async save() {
    const managersList = adminModel.managers.filter((item) => item.inviting === false);
    const invitingManagersList = adminModel.managers.filter((item) => item.inviting === true);

    const param = {
      managers: managersList.map((manager) => {
        return {
          userId: manager.userId,
          authorities: [...manager.authorities.acct, ...manager.authorities.law, ...manager.authorities.partner].map(
            (authority) => {
              return {
                menuCode: authority.menuCode,
                useYn: authority.useYn,
              };
            },
          ),
        };
      }),
      invitingManagers: invitingManagersList.map((manager) => {
        return {
          invitationId: manager.userId,
          authorities: [...manager.authorities.acct, ...manager.authorities.law, ...manager.authorities.partner].map(
            (authority) => {
              return {
                menuCode: authority.menuCode,
                useYn: authority.useYn,
              };
            },
          ),
        };
      }),
    };

    await post(`/userAuthority/company/${corpModel.id}/save`, param);
    await Alert('수정되었어요.');
    void this.load();
  }
  async deleteInvitation(userId: string) {
    await _delete(`/userAuthority/company/${corpModel.id}/invitation/${userId}`);
    await Alert('삭제되었어요.');
    void this.load();
  }
}

export default new AdminSettingService();
