import CropArea from './CropArea';
import ImageProcessor from './ImageProcessor';
import NukkiCanvas from './NukkiCanvas';
import NukkiImage from './NukkiImage';
import { calculateCropRangeInPixel, downloadSrc } from './utils';

/**
 * Nukki 모듈입니다.
 * @version 1.0.1 수정시마다 올려주세요 (수정사항 추적을 위함)
 * @updated 2024-12-16
 * @author 김록원
 */

/**
 * @typedef {Object} NukkiConfig
 * @property {number} width 캔버스 너비
 * @property {number} height 캔버스 높이
 * @property {number} [maxOutputImageSize] 최종 결과물 이미지의 최대 크기 (px)
 * @property {number} [minOutputImageSize] 최종 결과물 이미지의 최소 크기 (px)
 * @property {number} [backgroundCheckerSize] 캔버스 배경의 체크 무늬 정사각형 크기
 * @property {{top: number, left: number}} [backgroundOffset] 캔버스 배경의 체크 무늬 시작 위치
 * @property {number} [imagePadding] 이미지의 상하 패딩 값
 */

class Nukki {
  static defaultOptions = {
    width: 400,
    height: 300,
    maxOutputImageSize: 160,
    minOutputImageSize: 80,
    backgroundCheckerSize: 10,
    imagePadding: 5,
  };

  static options = Nukki.defaultOptions;

  /**
   * @type {NukkiCanvas}
   */
  canvas;
  /**
   * @type {NukkiImage}
   */
  image;
  /**
   * @type {CropArea}
   */
  cropArea;

  /**
   *
   * @param {HTMLCanvasElement} [canvasElement] 누끼 캔버스가 삽입될 canvasElement
   * @param {NukkiConfig} [options]
   */
  constructor(canvasElement, options = {}) {
    if (!canvasElement) {
      return;
    }

    this.init(canvasElement, options);
  }

  /**
   *
   * @param {HTMLCanvasElement} canvasElement 누끼 캔버스가 삽입될 canvasElement
   * @param {NukkiConfig} options
   */
  init(canvasElement, options = {}) {
    this.destroy();

    Nukki.options = { ...Nukki.options, ...options };

    this.canvas = new NukkiCanvas(canvasElement, {
      width: Nukki.options.width,
      height: Nukki.options.height,
      backgroundCheckerSize: Nukki.options.backgroundCheckerSize,
      backgroundOffset: Nukki.options.backgroundOffset,
    });
  }

  destroy() {
    if (this.canvas) void this.canvas.dispose();

    this.canvas = null;

    this.clear();
  }

  clear() {
    if (this.cropArea) this.cropArea.destroy();
    if (this.image) this.image.destroy();

    this.cropArea = null;
    this.image = null;
  }

  /**
   *
   * File 형식의 이미지를 읽어 캔버스에 삽입합니다.
   * 자동으로 크롭 영역을 삽입합니다.
   *
   * @param {File} file
   */
  insertImageFile(file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async (e) => {
      try {
        const imgUrl = e.target?.result;

        this._addImage(imgUrl);
        this._addCropArea();

        this.canvas.renderAll();
      } catch (e) {
        console.error(e);
      }
    };

    reader.onerror = console.error;
  }

  /**
   *
   * 삽입된 이미지를 회전시킵니다.
   *
   * @param {Number} angle Angle value (in degrees)
   * @default 0
   */
  rotateImage(angle = 0) {
    this.image?.rotate(angle);
  }

  /**
   *
   * 삽입된 이미지에 contrast를 적용합니다. from -1 to 1.
   *
   * @param {Number} contrast
   * @default 0
   */
  contrastImage(contrast = 0) {
    this.image?.contrast(contrast);
  }

  /**
   *
   * 이미지의 크롭 영역을 계산해서 반환한다.
   *
   * @returns {{cropMinX: number, cropMaxX: number, cropMinY: number, cropMaxY: number}}
   */
  crop() {
    const cropArea = this.canvas.getActiveObjects('rect').find((obj) => obj.name === CropArea.name);
    const imageObj = this.canvas.getObjects('image').find((obj) => obj.name === NukkiImage.name);

    if (!cropArea || !imageObj) {
      console.warn(
        '🚀 ~ cropAndExportAsPNG ~ cropArea or image is undefined :',
        `\ncropFrame: ${cropArea}\nimage: ${imageObj}`,
      );

      return;
    }

    const cropTargetArea = calculateCropRangeInPixel(imageObj, cropArea);

    return cropTargetArea;
  }

  /**
   *
   * 캔버스에 삽입된 이미지를
   * 1. 크롭 영역에 맞추어, (CropArea)
   * 2. 회전, 대비, 누끼가 적용된 상태로, (rotate, contrast, RedFilter)
   * 3. 이미지 크기를 최소 80px, 최대 160px로 조절해서 (resize)
   * 4. 붉은 색이 존재하는 부분만 남긴 이미지를 (trimMargin)
   * data url로 내보냅니다.
   *
   * @returns {string | undefined} Base64 data url
   */
  async exportImageToProcessedBase64() {
    if (!this.image) {
      console.warn('🚀 ~ exportImageToBase64 ~ this.image is undefined :', this.image);

      return;
    }

    try {
      const cropTargetPixels = this.crop();
      const src = await this.image.toDataURL();

      const processor = new ImageProcessor(src, {
        maxSize: Nukki.options.maxOutputImageSize,
        minSize: Nukki.options.minOutputImageSize,
      });

      return processor.process({
        startX: cropTargetPixels.cropMinX,
        endX: cropTargetPixels.cropMaxX,
        startY: cropTargetPixels.cropMinY,
        endY: cropTargetPixels.cropMaxY,
      });
    } catch (e) {
      console.error(e);

      return;
    }
  }

  /**
   *
   * 캔버스에 삽입 된 이미지를 처리 후 다운로드 시킵니다.
   *
   */
  async download() {
    if (!this.image) {
      console.warn('🚀 ~ exportAsPNG ~ this.image is undefined :', this.image);

      return;
    }

    const src = await this.exportImageToProcessedBase64();

    downloadSrc(src);
  }

  /**
   *
   * 캔버스에 조절가능한 크롭 영역을 삽입합니다.
   *
   */
  _addCropArea() {
    if (this.cropArea) {
      this.cropArea.destroy();
    }

    this.cropArea = new CropArea(this.canvas);

    this.canvas.add(this.cropArea);
    this.canvas.setActiveObject(this.cropArea);
    this.canvas.bringObjectToFront(this.cropArea);
  }

  /**
   *
   * 캔버스에 이미지를 삽입합니다.
   *
   * @param {string} imageSrc
   */
  _addImage(imageSrc) {
    if (this.image) {
      this.image.destroy();
    }

    this.image = new NukkiImage(this.canvas, imageSrc, {
      padding: Nukki.options.imagePadding,
    });
  }
}

export default Nukki;
