import { _delete, get, post, put } from '@common/module/httpRequest';

import type {
  ChangeSealRq,
  DeleteSealRq,
  GetRepresentativeListRs,
  GetSealAgendaRs,
  GetSealAuthorizedRs,
  GetSealChangeValidateRq,
  GetSealChangeValidateRs,
  GetSealDeleteValidateRq,
  GetSealDeleteValidateRs,
  GetSealExecutiveRs,
  GetSealListRs,
  PostSealRq,
} from './SealService.interface';

class SealService {
  async getSealList(corpId: string): Promise<GetSealListRs> {
    const res = await get<GetSealListRs>(`/company/${corpId}/seal`);
    const data = res.data;

    return {
      guides:
        data?.guides?.map((guide) => ({
          title: guide?.title || '',
          contents: guide?.contents || '',
          url: guide?.url || '',
        })) || [],
      seals:
        data?.seals?.map((seal) => ({
          ...seal,
          sealId: String(seal.sealId ?? ''),
          executiveId: String(seal.executiveId ?? ''),
        })) || [],
    };
  }
  /**
   * 인감 등록 및 관리를 위한 대표 정보 조회
   */
  async getRepresentativeList(corpId: string): Promise<GetRepresentativeListRs> {
    const res = await get<GetRepresentativeListRs>(`/company/${corpId}/seal/representative`);
    const data = res.data;

    return {
      representatives:
        data?.representatives?.map((representative) => ({
          name: representative?.name || '',
          id: Number(representative?.id || ''),
          isDisable: Boolean(representative.isDisable),
        })) || [],
    };
  }
  /**
   * 법인 인감 등록하기
   */
  async postSeal(corpId: string, param: PostSealRq) {
    await post(`/company/${corpId}/seal`, param);
  }
  /**
   * 인감 삭제전 유효성 체크
   */
  async getSealDeleteValidate(corpId: string, param: GetSealDeleteValidateRq): Promise<GetSealDeleteValidateRs> {
    const res = await get<GetSealDeleteValidateRs>(`/company/${corpId}/seal/delete/validate`, param);
    const data = res.data;

    return data;
  }
  /**
   * 인감 수정전 유효성 체크
   */
  async getSealChangeValidate(corpId: string, param: GetSealChangeValidateRq): Promise<GetSealChangeValidateRs> {
    const res = await get<GetSealChangeValidateRs>(`/company/${corpId}/seal/change/validate`, param);
    const data = res.data;

    return data;
  }
  /**
   * 인감 삭제하기
   */
  async deleteSeal(corpId: string, param: DeleteSealRq) {
    await _delete(`/company/${corpId}/seal`, param);
  }
  /**
   * 인감 수정하기
   */
  async changeSeal(corpId: string, param: ChangeSealRq) {
    await put(`/company/${corpId}/seal`, param);
  }
  /**
   * 서류 인감정보 조회
   */
  async getSealExecutive(corpId: string, param: { includeCorporateSeal: boolean }): Promise<GetSealExecutiveRs> {
    console.log(param);
    const res = await get<GetSealExecutiveRs>(`/company/${corpId}/seal/executive`, param);
    const data = res.data;

    return data;
  }
  /**
   * 서류 안건인감정보 조회
   */
  async getSealAgenda(corpId: string, agendaProgressId: string): Promise<GetSealAgendaRs> {
    const res = await get<GetSealAgendaRs>(`/company/${corpId}/seal/executive/agenda/${agendaProgressId}`);
    const data = res.data;

    return data;
  }
  /**
   * 서류 사용인감 조회
   */
  async getSealAuthorized(corpId: string): Promise<GetSealAuthorizedRs> {
    const res = await get<GetSealAuthorizedRs>(`/company/${corpId}/seal/authorized`);
    const data = res.data;

    return data;
  }
}

export default new SealService();
