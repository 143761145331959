import NewModalModel from '@model/newModalModel';
import notificationModel, { type Notification } from '@model/notificationModel';
import userModel from '@model/userModel';
import UserSetting from '@pages/UserSetting/UserSetting';
import notificationService from '@service/notificationService';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useHeader = () => {
  const notifications: Notification[] = notificationModel.notifications;
  const [openNotify, setOpenNotify] = useState<boolean>(false);

  // 새로운 알림이 있는지 확인
  const newNotifyYn: boolean = useMemo(() => {
    let hasNew = notifications.some((notification) => !notification.readAt);

    if (notifications.filter((notification) => notification.notificationType === 'INVITE').length !== 0) {
      hasNew = true;
    }

    return hasNew;
  }, [notifications]);

  const userName: string | null = useMemo(() => userModel.user.name, [userModel.user.name]);

  const openUserSetting = useCallback(() => {
    NewModalModel.openModal(<UserSetting />);
  }, []);

  const toggleNotification = useCallback(() => {
    setOpenNotify((prev) => {
      const newState = !prev;

      if (newState) {
        document.body.style.overflow = 'hidden';
      } else {
        void notificationService.readNotification();
        document.body.style.overflow = 'auto';
      }

      return newState;
    });
  }, []);

  useEffect(() => {
    // 초대 알림이 있으면 알림 목록을 열어준다.
    if (notifications.filter((notification) => notification.notificationType === 'INVITE').length !== 0) {
      setOpenNotify(true);
    }
  }, [notifications.filter((notification) => notification.notificationType === 'INVITE').length]);

  return {
    openNotify,
    openUserSetting,
    toggleNotification,
    newNotifyYn,
    notifications,
    userName,
  };
};

export default useHeader;
