import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import AdminAddModel from '@model/Corp/AdminAdd/AdminAddModel';
import { Alert } from '@src/model/newDialogModel';
import AdminAddService from '@src/service/Corp/AdminAdd/adminAddService';
import type { Authorities, InvitationRq } from '@src/service/Corp/AdminAdd/adminAddService.interface';
import { runInAction } from 'mobx';
import type React from 'react';

class AdminAddVm {
  load() {
    void AdminAddService.load();
  }
  get addData() {
    return AdminAddModel.addData;
  }
  changeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      this.addData.email = regExp.deleteSpace(e.target.value);
    });
  }
  changeName(e: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      this.addData.name = e.target.value;
    });
  }
  changeRole(e: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      this.addData.role = e.target.value;
    });
  }
  changeAuthorityType(e: React.ChangeEvent<HTMLInputElement>) {
    runInAction(() => {
      this.addData.authorityType = e.target.value as 'MANAGER' | 'GENERAL_MANAGER';

      for (const authoritiesType in AdminAddModel.addData.authorities) {
        AdminAddModel.addData.authorities[authoritiesType as keyof Authorities].forEach((authority) => {
          authority.useYn = e.target.value === 'GENERAL_MANAGER';
        });
      }
    });
  }
  checkAllAuthoritiesSelected(type: keyof Authorities) {
    let checkAll = true;

    AdminAddModel.addData.authorities[type].forEach((authority) => {
      if (authority.useYn === false) {
        checkAll = false;
      }
    });

    return checkAll;
  }
  changeUseYn(e: React.ChangeEvent<HTMLInputElement>, type: keyof Authorities, authorityIndex: number) {
    AdminAddModel.addData.authorities[type][authorityIndex].useYn = e.target.checked;
  }
  onChangeSelectAllAuthorities(e: React.ChangeEvent<HTMLInputElement>, type: keyof Authorities) {
    AdminAddModel.addData.authorities[type].forEach((authority) => {
      authority.useYn = e.target.checked;
    });
  }
  submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = formDataToJson(e.target);

    if (formData.error) {
      return;
    }

    const param = formData.data as InvitationRq;

    let checkedItem = 0;

    for (const authoritiesType in AdminAddModel.addData.authorities) {
      AdminAddModel.addData.authorities[authoritiesType as keyof Authorities].forEach((authority) => {
        checkedItem += authority.useYn ? 1 : 0;
      });
    }

    if (checkedItem === 0) {
      return void Alert(
        '담당자는 최소한 1개의 권한을 가지고 있어야 해요.',
        '권한을 추가한 후 초대버튼을 누르세요.',
        '확인',
      );
    }

    void AdminAddService.invite(param);
  }
}

export default new AdminAddVm();
