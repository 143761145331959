
/**
 * @param {HTMLImageElement} img
 * @returns {string} PNG image source
 */
export const convertToPngSrc = (img) => {
  const canvasEl = document.createElement('canvas');

  canvasEl.width = img.width;
  canvasEl.height = img.height;

  const ctx = canvasEl.getContext('2d');

  ctx.drawImage(img, 0, 0);

  return canvasEl.toDataURL('image/png');
};

/**
 * @param {string} src
 * @returns {Promise<HTMLImageElement>}
 */
export const loadImageAsync = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};
