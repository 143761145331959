import NewModalModel from '@model/newModalModel';
import UserSetting from '@pages/UserSetting/UserSetting';
import { runInAction } from 'mobx';

class AllianceVm {
  openUserSetting() {
    runInAction(() => {
      NewModalModel.openModal(<UserSetting activeTab="약관동의" />);
    });
  }
}

export default new AllianceVm();
