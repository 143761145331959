import { observable } from 'mobx';

const bookKeepingContractModel = observable({
  pageType: '',
  accountingFirmName: '',
  bankList: [],
  bookkeepingServiceStartAt: {
    year: '',
    month: '',
    day: '',
  },
  freeBookkeepingServiceEndAt: {
    year: '',
    month: '',
    day: '',
  },
  freeBookkeepingServiceYn: null,
  bookkeepingServiceFee: '',
  bookkeepingContractFile: '',
  cmsDirectDebitApplicationFile: '',
  delegationRegistrationAgreeYn: null,
  cmsAccountInputDeadline: {
    year: '',
    month: '',
    day: '',
  },
  bookkeepingServicePauseStartedAt: {
    year: '',
    month: '',
    day: '',
  },
  delegationRegistrationOnlyYn: false,
  bookkeepingContractLink: '',
  cmsDirectDebitApplicationLink: '',
  bookkeepingSignatureActivationYn: false,
  cmsAccountSignatureActivationYn: false,
  term: {
    id: '',
    content: '',
  },
  freeMonth: '',
  bankbooks: [],
  bookkeepingServiceFeeChangeHistories: [],
  bookkeepingContractUpdatedAt: {
    year: '',
    month: '',
    day: '',
  },
  cmsDirectDebitApplicationUpdatedAt: {
    year: '',
    month: '',
    day: '',
  },
  freeBookkeepingServicePreviousEndAt: {
    year: '',
    month: '',
    day: '',
  },
  paymentDay: '',
  setBookkeepingContract(data) {
    this.pageType = data?.pageType || '';
    this.accountingFirmName = data?.accountingFirmName || '';
    this.bankList = data?.bankList || [];
    this.bookkeepingServiceStartAt = data?.bookkeepingServiceStartAt || {
      year: '',
      month: '',
      day: '',
    };
    this.bookkeepingSignatureActivationYn = data?.bookkeepingSignatureActivationYn ?? false;
    this.freeBookkeepingServiceEndAt = data?.freeBookkeepingServiceEndAt || {
      year: '',
      month: '',
      day: '',
    };
    this.freeBookkeepingServiceYn = data?.freeBookkeepingServiceYn ?? null;
    this.bookkeepingServiceFee = data?.bookkeepingServiceFee || '';
    this.bookkeepingContractFile = data?.bookkeepingContractFile || '';
    this.cmsDirectDebitApplicationFile = data?.cmsDirectDebitApplicationFile || '';
    this.delegationRegistrationAgreeYn = data?.delegationRegistrationAgreeYn ?? false;
    this.cmsAccountInputDeadline = data?.cmsAccountInputDeadline || {
      year: '',
      month: '',
      day: '',
    };
    this.bookkeepingServicePauseStartedAt = data?.bookkeepingServicePauseStartedAt || {
      year: '',
      month: '',
      day: '',
    };
    this.delegationRegistrationOnlyYn = data?.delegationRegistrationOnlyYn ?? false;
    this.cmsAccountSignatureActivationYn = data?.cmsAccountSignatureActivationYn ?? false;
    this.term = {
      id: data?.term?.id || '',
      content: data?.term?.content || '',
    };
    this.freeMonth = data?.freeMonth || '';
    this.bookkeepingServiceFeeChangeHistories = data?.bookkeepingServiceFeeChangeHistories?.map(
      (bookkeepingServiceFeeChangeHistory) =>
        ({
          changedAt: {
            year: bookkeepingServiceFeeChangeHistory?.changedAt?.year || '',
            month: bookkeepingServiceFeeChangeHistory?.changedAt?.month || '',
            day: bookkeepingServiceFeeChangeHistory?.changedAt?.day || '',
          },
          newFee: bookkeepingServiceFeeChangeHistory.newFee || '',
          previousFee: bookkeepingServiceFeeChangeHistory.previousFee || '',
        }) ?? [],
    );

    this.bookkeepingContractUpdatedAt = data?.bookkeepingContractUpdatedAt || {
      year: '',
      month: '',
      day: '',
    };
    this.cmsDirectDebitApplicationUpdatedAt = data?.cmsDirectDebitApplicationUpdatedAt || {
      year: '',
      month: '',
      day: '',
    };
    this.freeBookkeepingServicePreviousEndAt = data?.freeBookkeepingServicePreviousEndAt || {
      year: '',
      month: '',
      day: '',
    };
  },
  setElectronicSignature(data) {
    this.bookkeepingContractLink = data?.bookkeepingContractLink || '';
  },
  setCmsDirectDebitApplication(data) {
    this.cmsDirectDebitApplicationLink = data?.cmsDirectDebitApplicationLink || '';
  },
  setBankbooks(data) {
    this.paymentDay = data?.paymentDay ? data.paymentDay + '일' : '';
    if (data.bankbooks && data.bankbooks.length !== 0) {
      this.bankbooks = data.bankbooks.map((bankbook) => {
        return {
          id: bankbook.id || '',
          bankName: bankbook.bankName || '',
          accountNumber: bankbook.accountNumber || '',
          available: bankbook.available ?? null,
          accountUsage: bankbook.accountUsage || '',
        };
      });
    } else {
      this.bankbooks = [];
    }
  },
});

export default bookKeepingContractModel;
