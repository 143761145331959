import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import BeforeUploadFileVm from './BeforeUploadFileVm';
import st from './BeforeUploadFile.module.scss';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import Guide from '@commonComponents/Guide/Guide';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Loading from '@commonComponents/Loading/Loading';

function BeforeUploadFile() {
  return useObserver(() => (
    <>
      {BeforeUploadFileVm.state.loadingYn && <Loading />}
      <PageTitle>
        <span className={st.pageTitle}>사업자등록</span>
      </PageTitle>
      <Guide
        title="사업자등록 가이드"
        localStorageKey="businessRegistration"
        corpId={String(BeforeUploadFileVm.corpId)}
        items={BeforeUploadFileVm.guides}
        fullLink="https://standby.kr/blog/category/%EC%82%AC%EC%97%85%EC%9E%90%EB%93%B1%EB%A1%9D%EC%A6%9D/"
      />
      <ContentBox
        title="사업자등록"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
      >
        <div className={st.content}>
          <div className={st.businessBox}>
            <div className={st.subTitle}>앗! 사업자등록 정보가 없어요.</div>
            <div className={st.title}>
              <span className={st.bold}>사업자등록증</span>을 <span className={st.bold}>업로드</span>하세요.
            </div>
            <div className={st.ad}>이제 사업자등록도 원스톱으로 자동 관리!</div>
            <form className={st.file}>
              <label className={st.fileGuide} htmlFor="fileUpload">
                <div className={st.fileTitle}>파일 업로드</div>
                <div className={st.fileExplain}>고객님의 소중한 정보는 스탠바이가 철저하게 관리하고 있어요!</div>
              </label>
              <input
                type="file"
                id="fileUpload"
                style={{ width: 0, height: 0, position: 'absolute', display: 'none' }}
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={(e) => BeforeUploadFileVm.fileUpload(e)}
              />
            </form>
          </div>
        </div>
      </ContentBox>
      <ContentBox
        title="사업자등록 FAQ"
        className={st.contentBox}
        titleStyle={{ top: '15px', paddingLeft: '18px', fontSize: '18px' }}
      >
        <div className={st.content}>
          <div className={st.businessFAQBox}>
            <div className={st.faq}>
              <div className={st.question}>사업자등록증은 어떻게 재발급하나요?</div>
              <div className={st.answer}>
                홈택스 로그인 후 1)민원증명 <span className={st.arrowIcon} /> 2)사업자등록증 재발급 버튼을 눌러서
                재발급하면 되어요.
              </div>
            </div>
            <div className={st.faq}>
              <div className={st.question}>사업자등록증은 발급비용이 얼마인가요?</div>
              <div className={st.answer}>사업자등록증은 무료로 발급되어요. (법인등기부등본은 700~1,000원 지불)</div>
            </div>
            <div className={st.faq}>
              <div className={st.question}>사업자등록증에는 유효기간이 있나요?</div>
              <div className={st.answer}>사업자등록증은 유효기간이 없어요. (법인등기부등본은 유효기간 3개월)</div>
            </div>
            <div className={st.faq}>
              <div className={st.question}>사업자등록증은 언제 변경해야 해요?</div>
              <div className={st.answer}>
                회사명, 본점주소, 대표자, 주로 운영하는 업종(업태, 종목)이 바뀌었을 때 변경해요.
              </div>
            </div>
            <div className={st.faq}>
              <div className={st.question}>사업자등록증은 어떻게 변경해요?</div>
              <div className={st.answer}>
                홈택스에서 직접 변경할 수 있어요. 만약 담당 회계법인/세무법인이 있다면 변경을 요청하세요.
              </div>
            </div>
          </div>
        </div>
      </ContentBox>
    </>
  ));
}
export default BeforeUploadFile;
