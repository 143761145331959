import Guide from '@commonComponents/Guide/Guide';
import PageTitle from '@commonComponents/PageTitle/PageTitle';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';

import FirstPage from './FirstPage/FirstPage';
import StepPage from './StepPage/StepPage';
import st from './Vat.module.scss';
import vm from './VatVm';

function Vat() {
  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <>
      <PageTitle>
        <span className={st.pageTitle}>부가세</span>
      </PageTitle>
      <Guide
        title="부가세 가이드"
        localStorageKey="vatGuide"
        corpId={String(vm.corpId)}
        items={vm.guides}
        fullLink="https://standby.kr/blog/group/%EB%B6%80%EA%B0%80%EC%84%B8%20%ED%95%84%EC%88%98%EC%83%81%EC%8B%9D/"
      />
      {vm.state.loadYn && (
        <>
          {vm.nowStep === '부가세_안내문_발송전' && <FirstPage />}
          {vm.nowStep !== '부가세_안내문_발송전' && <StepPage />}
        </>
      )}
    </>
  ));
}

export default Vat;
