import { createQueryKeys } from '@lukemorales/query-key-factory';
import SealService from '@service/Corp/Seal/SealService';
import { GetSealAgendaRs, GetSealAuthorizedRs, GetSealExecutiveRs } from '@service/Corp/Seal/SealService.interface';
import { useQuery } from '@tanstack/react-query';

const queryKey = createQueryKeys('Seal', {
  sealExecutive: (corpId) => [corpId],
  sealAgenda: (corpId, agendaProgressId) => [corpId, agendaProgressId],
  sealAuthorized: (corpId) => [corpId],
});

const useSealExecutiveQuery = (corpId: string, includeCorporateSeal: boolean) => {
  return useQuery<GetSealExecutiveRs>({
    ...queryKey.sealExecutive(corpId),
    queryFn: () => SealService.getSealExecutive(corpId, { includeCorporateSeal }),
    enabled: !!corpId,
  });
};

const useSealAgendaQuery = (corpId: string, agendaProgressId: string) => {
  return useQuery<GetSealAgendaRs>({
    ...queryKey.sealAgenda(corpId, agendaProgressId),
    queryFn: () => SealService.getSealAgenda(corpId, agendaProgressId),
    enabled: !!corpId && !!agendaProgressId,
  });
};

const useSealAuthorizedQuery = (corpId: string) => {
  return useQuery<GetSealAuthorizedRs>({
    ...queryKey.sealAuthorized(corpId),
    queryFn: () => SealService.getSealAuthorized(corpId),
    initialData: {
      authorizedSeals: [],
    },
    enabled: !!corpId,
  });
};

export { queryKey, useSealAgendaQuery, useSealAuthorizedQuery, useSealExecutiveQuery };
