import { Rect } from 'fabric';

const strokeColor = '#4E73F6';
const strokeWidth = 2;
const sizeLimit = 10;

/**
 * @type {import('fabric').FabricObjectProps}
 */
const lockInteractionOption = {
  lockRotation: true,
};

/**
 * @type {import('fabric').FabricObjectProps}
 */
const controlOption = {
  cornerStyle: 'circle',
  cornerStrokeColor: strokeColor,
};

/**
 * @type {import('fabric').FabricObjectProps}
 */
const borderOption = {
  borderOpacityWhenMoving: 1,
  borderColor: strokeColor,
  borderScaleFactor: strokeWidth,
  hasBorders: true,
};

class CropArea extends Rect {
  static name = 'cropFrame';

  static defaultSize = {
    width: 200,
    height: 200,
  };

  _canvas;
  _backgroundDim = {
    topLeft: null,
    rightTop: null,
    bottomRight: null,
    leftBottom: null,
  };

  constructor(canvas) {
    super({
      left: (canvas.width - CropArea.defaultSize.width) / 2,
      top: (canvas.height - CropArea.defaultSize.height) / 2,
      width: CropArea.defaultSize.width,
      height: CropArea.defaultSize.height,
      fill: 'transparent',
      stroke: strokeColor,
      strokeWidth: strokeWidth,
      strokeUniform: true,
      selectable: true,
      evented: true,
      ...lockInteractionOption,
      ...controlOption,
      ...borderOption,
      controls: createCustomControls(),
      name: CropArea.name,
      absolutePositioned: true,
    });

    this._canvas = canvas;

    this._setBackgroundDim();

    this.on('scaling', () => {
      this._maintainSize(this);
      this._updateBackgroundDimPosition();
      this._canvas.renderAll();
    });

    this.on('modified', () => {
      this._maintainSize(this);
      this._updateBackgroundDimPosition();
      this._canvas.renderAll();
    });

    this.on('moving', () => {
      this._updateBackgroundDimPosition();
      this._canvas.renderAll();
    });

    // 선택 영역이 계속 보이도록, 선택 영역이 사라지면 크롭 프레임을 활성화
    this._canvas.on('selection:cleared', () => {
      this._canvas.setActiveObject(this);
      this._canvas.renderAll();
    });
  }

  destroy() {
    this._canvas = null;
    super.dispose();

    Object.values(this._backgroundDim).forEach((rect) => {
      rect.dispose();
    });
    this._backgroundDim = {
      topLeft: null,
      rightTop: null,
      bottomRight: null,
      leftBottom: null,
    };
  }

  /**
   *
   * @note fabricjs 의 scaling 동작은 obj.width, obj.height를 변화시키지 않고 scaleX,Y 만 변화 시킴
   *       이 scale 은 border stroke width에도 적용되어 축소 시키거나 확대 시키면 테두리 너비도 변화함
   *       일관된 결과를 위해 scale이 변화할 때마다 obj의 크기 자체를 업데이트함.
   *       + cropArea가 조작으로 너무 작아지는 것을 막기 위해 sizeLimit 추가
   *
   * @param {Rect} obj
   */
  _maintainSize(obj) {
    const scaleX = obj.scaleX || 1;
    const scaleY = obj.scaleY || 1;

    obj.set({
      width: Math.max(obj.width * scaleX, sizeLimit),
      height: Math.max(obj.height * scaleY, sizeLimit),
      scaleX: 1,
      scaleY: 1,
      strokeWidth,
    });

    obj.setCoords();
  }

  _setBackgroundDim() {
    const positions = this._calculateUpdatedBackgroundDimPosition();

    this._backgroundDim = {
      topLeft: this._createDim(positions.topLeft),
      rightTop: this._createDim(positions.rightTop),
      bottomRight: this._createDim(positions.bottomRight),
      leftBottom: this._createDim(positions.leftBottom),
    };

    Object.values(this._backgroundDim).forEach((rect) => {
      this._canvas.add(rect);
    });
  }

  _createDim({ left, top }) {
    const length = Math.max(this._canvas.width, this._canvas.height);

    return new Rect({
      left,
      top,
      width: length,
      height: length,
      fill: '#121212',
      opacity: 0.5,
      selectable: false,
      evented: false,
      hasBorders: false,
      hasControls: false,
    });
  }

  _calculateUpdatedBackgroundDimPosition() {
    const cropAreaLeft = this.left;
    const cropAreaTop = this.top;
    const cropAreaWidth = this.width;
    const cropAreaHeight = this.height;
    const length = Math.max(this._canvas.width, this._canvas.height);

    return {
      topLeft: {
        left: Math.round(cropAreaWidth + cropAreaLeft - length),
        top: Math.round(cropAreaTop - length),
      },
      rightTop: {
        left: Math.round(cropAreaLeft + cropAreaWidth),
        top: Math.round(cropAreaHeight + cropAreaTop - length),
      },
      bottomRight: {
        left: Math.round(cropAreaLeft),
        top: Math.round(cropAreaTop + cropAreaHeight),
      },
      leftBottom: {
        left: Math.round(cropAreaLeft - length),
        top: Math.round(cropAreaTop),
      },
    };
  }

  _updateBackgroundDimPosition() {
    const positions = this._calculateUpdatedBackgroundDimPosition();

    Object.entries(this._backgroundDim).forEach(([key, rect]) => {
      rect.set(positions[key]);
    });
  }
}

const renderCustomControl = (ctx, left, top, _, fabricObject) => {
  const size = fabricObject.cornerSize;

  ctx.beginPath();
  ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.lineWidth = 4;
  ctx.strokeStyle = strokeColor;
  ctx.stroke();
};

const createCustomControls = () => {
  const { controls } = Rect.createControls();

  delete controls.mtr;
  delete controls.ml;
  delete controls.mt;
  delete controls.mr;
  delete controls.mb;

  controls.tl.render = renderCustomControl;
  controls.tr.render = renderCustomControl;
  controls.br.render = renderCustomControl;
  controls.bl.render = renderCustomControl;

  return controls;
};

export default CropArea;
