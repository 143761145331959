import NewModalModel from '@model/newModalModel';
import { useEffect } from 'react';

import { useSealListQuery } from '../../queries';
import SealEditModal from './components/SealInfo/SealEditModal';
import SealEditModalModel from './components/SealInfo/SealEditModal/SealEditModalModel';

const useSealHome = (corpId: string) => {
  const sealListState = useSealListQuery(corpId);

  //도장 수정후 다른 도장팝업을 다시 열어야하는 케이스가 있음
  useEffect(() => {
    const nextOpenModalSealId = SealEditModalModel.nextOpenModalSealId;

    if (nextOpenModalSealId && sealListState.data?.seals) {
      const seal = sealListState.data.seals.find((seal) => seal.sealId === String(nextOpenModalSealId));

      if (seal) {
        NewModalModel.openModal(<SealEditModal seal={seal} corpId={corpId} />);
        //팝업을 열고 다음 열어야할 팝업 아이디 삭제
        SealEditModalModel.nextOpenModalSealId = undefined;
      }
    }
  }, [SealEditModalModel.nextOpenModalSealId]);

  return {
    sealListState,
  };
};

export default useSealHome;
